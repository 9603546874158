/**
 * Mobile Nav Draw
 * @flow
 */
import React, { Fragment, useState, type Node } from 'react';
import { NavLink } from 'react-router-dom';
import Copyright from '../../copyright/Copyright';
import ProfileNav from '../profile-nav/ProfileNav';
import AdminNav from '../../admin/admin-nav/AdminNav';
import SearchModal from '../../search-modal/SearchModal';
import FooterNav from '../../footer/footer-nav/FooterNav';
import { Drawer } from '@mui/material';
import {
  AccountCircleRounded,
  CloseRounded,
  // FaceRounded,
  HomeRounded,
  MenuRounded,
  SearchRounded,
  StarRounded,
  VerifiedUserRounded
} from '@mui/icons-material';
import { UNVERIFIED, UNKNOWN } from '../../../data/Data';
import userVerificationStatus from '../../../hooks/userVerified';
import type { User } from '../../../types/Types';
import styles from './MobileNav.module.scss';

type Props = {
  app: *,
  user: User
};

const MobileNav = (props: Props): Node => {
  const { user } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const status = userVerificationStatus(user);

  /**
   * toggle drawer
   */
  const toggle = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  /**
   * Toggle search
   */
  const toggleSearch = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowSearch(!showSearch);
  };

  return (
    <Fragment>
      <a onClick={toggle} href="#toggle-drawer" className={styles.ToggleNav}>
        <MenuRounded />
      </a>
      <Drawer anchor="right" open={open} onClose={toggle} className={styles.Drawer}>
        <a href="#close-menu" className={styles.Close} onClick={toggle}>
          <CloseRounded />
        </a>
        <nav className={styles.MobileNav}>
          <ul>
            <li>
              <a href="#search" onClick={toggleSearch}>
                <SearchRounded /> Search
              </a>
            </li>
            <li onClick={toggle}>
              <NavLink to="/">
                <HomeRounded fontSize="medium" />
                <span className={styles.Text}>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/favourites">
                <StarRounded fontSize="medium" />
                <span className={styles.Text}>Saved</span>
              </NavLink>
            </li>
            {user?.providerType ? (
              <li onClick={toggle}>
                <NavLink to="/">
                  <AccountCircleRounded fontSize="medium" />
                  <span className={styles.Text}>Create Account</span>
                </NavLink>
              </li>
            ) : null}
            {status === UNVERIFIED || status === UNKNOWN ? (
              <li onClick={toggle}>
                <NavLink to="/settings/get-verified">
                  <VerifiedUserRounded />
                  <span className={styles.Text}>Get Verified</span>
                </NavLink>
              </li>
            ) : null}
            {user?.role === 'admin' ? <AdminNav {...props} toggle={toggle} /> : null}
            <ProfileNav {...props} toggle={toggle} drawer={true} />
            <FooterNav toggle={toggle} drawer={true} showTitle={true} />
          </ul>
        </nav>
        <Copyright style={{ textAlign: 'left', padding: '1rem', position: 'absolute', bottom: 0, left: 0 }} />
      </Drawer>
      <SearchModal {...props} showSearch={showSearch} toggleSearch={toggleSearch} />
    </Fragment>
  );
};

export default MobileNav;
