/**
 * CreateAccountFormModal
 * @flow
 */
import React, { type Node } from 'react';
import CreateAccountForm from './CreateAccountForm';
import { Card, Modal } from '@mui/material';
import styles from './CreateAccountFormModal.module.scss';

type Props = {
  app: *,
  open: boolean,
  setOpen: (open: boolean) => *,
  text: string
};

const CreateAccountFormModal = (props: Props): Node => {
  const { app, open, setOpen, text } = props;

  const close = () => {
    setOpen(!open);
  };

  return (
    <Modal className={styles.CreateAccountFormModal} open={open} onBackdropClick={close}>
      <Card className={styles.Card}>
        <CreateAccountForm app={app} text={text} />
      </Card>
    </Modal>
  );
};

export default CreateAccountFormModal;
