import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useLike = (query) => {
  const { like, loading } = getLikeById(query);

  return {
    loading,
    like
  };
};
export default useLike;

function getLikeById(query) {
  const { data, loading, error } = useQuery(
    gql`
      query GetLikeById($query: LikeQueryInput!) {
        like(query: $query) {
          _id
          post {
            _id
          }
          userId
          vote
        }
      }
    `,
    {
      variables: {
        query
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch like: ${error.message}`);
  }

  // If the query has finished, return the like from the result data
  // Otherwise, return null
  const like = data?.like ?? null;
  return { like, loading };
}
