/**
 * User Graphql queries
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useGetUserQuery = (query) => {
  const { user, loading } = getUserQuery(query);
  return {
    loading,
    user
  };
};
export default useGetUserQuery;

function getUserQuery(query) {
  const { data, loading, error } = useQuery(
    gql`
      query GetUserByQuery($query: UserQueryInput!) {
        user(query: $query) {
          _id
          bio
          email
          emailConfirmed
          dateJoined
          free
          firstName
          headerImage
          lastName
          phone
          phoneConfirmed
          profileImage
          role
          settings {
            _id
            name
            value
            showInUi
          }
          stripe {
            _id
            accountId
            bankAccount
            customerId
            setupIntents
            paymentMethods
            stripeId
          }
          subscriptions {
            _id
            accountId
            creatorId
            currentPeriodEnd
            invoices {
              _id
              invoiceId
              stripeId
              userId
            }
            status
            stripeId
            subscriptionId
          }
          twitter
          url
          userName
          verified
          verifications {
            _id
            address {
              country
              locality
              street
              state
              postcode
            }
            actions {
              _id
              action
              actionDate
              note
            }
            created
            dateOfBirth
            firstName
            idType
            idNumber
            idOther
            idSsn
            ipAddress
            images {
              _id
              filePath
              title
            }
            lastName
            userAgent
          }
        }
      }
    `,
    {
      variables: {
        query
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch user: ${error.message}`);
  }

  // If the query has finished, return the posts from the result data
  // Otherwise, return an empty list
  const user = data?.user ?? null;
  return { user, loading };
}
