/**
 * Like
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import CreateAccountFormModal from '../create-account-form/CreateAccountFormModal';
import Like from './Like';
import useLikeMutations from '../../graphql/useLikeMutations';
import usePostMutations from '../../graphql/usePostMutations';
import { verificationStatus } from '../../lib/user/user';
import { ANON } from '../../data/Data';
import type { Photo, Post, SaveInput, User, Vote, Video } from '../../types/Types';
import useLike from '../../graphql/useSingleLike';

type Props = {
  app: *,
  alignment: 'horizontal' | 'vertical',
  likes: null | number,
  photo?: Photo,
  post: Post,
  user: User,
  video?: Video
};

const LikeWrapper = (props: Props): Node => {
  const { alignment, likes, photo, post, user, video } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [num, setNum] = useState(parseInt(likes ? likes : 0));
  const [query, setQuery] = useState({ post: { _id: post._id }, userId: user.userId });
  const [localLike, setLocalLike] = useState(null);
  const [canLike, setCanLike] = useState(true);
  const { useAddLike, updateLike, deleteLike } = useLikeMutations();
  const { addLike, data } = useAddLike;
  const { updatePost } = usePostMutations();
  const { like } = useLike(query);
  const status = verificationStatus(user);

  useEffect(() => {
    if (!user) {
      return;
    }

    let newQuery = photo
      ? {
          photo: {
            _id: photo._id
          },
          userId: user._id
        }
      : post
      ? {
          post: {
            _id: post._id
          },
          userId: user._id
        }
      : video
      ? {
          video: {
            _id: video._id
          },
          userId: user._id
        }
      : null;
    setQuery(newQuery);
  }, [photo, post, user, video]);

  /**
   * Update localLike value based on fetched or addedLike data
   */
  useEffect(() => {
    switch (true) {
      case !!like && !data:
        setLocalLike(like);
        break;

      case data !== undefined && data.addedLike !== undefined:
        setLocalLike(data.addedLike);
        break;

      default:
      // no op
    }
  }, [data, like, setLocalLike]);

  const action = (e: SyntheticMouseEvent<HTMLAnchorElement>, vote: Vote) => {
    e.preventDefault();

    if (status === ANON) {
      setShowModal(!showModal);
      return;
    }

    // prevent double taps
    if (!canLike) {
      return;
    }

    setCanLike(false);

    if (!localLike) {
      const save: SaveInput = {
        postId: post._id,
        userId: user._id,
        vote
      };
      addLike(save);
      setNum(parseInt(num) + vote);
      setTimeout(() => {
        setCanLike(true);
      }, 1000);
    } else {
      switch (true) {
        // change down vote to up vote
        case vote === 1 && localLike.vote == -1: {
          const change = 2;
          setNum(parseInt(num) + change);
          updateLike({ _id: localLike._id }, { vote_inc: change });
          updatePost(post, { likes_inc: change });
          setLocalLike({ ...localLike, vote: 1 });
          break;
        }

        // change up vote to down vote
        case vote === -1 && localLike.vote == 1: {
          const change = -2;
          setNum(parseInt(num) + change);
          updateLike({ _id: localLike._id }, { vote_inc: change });
          updatePost(post, { likes_inc: change });
          setLocalLike({ ...localLike, vote: -1 });
          break;
        }

        // remove up vote
        case vote === 0 && localLike.vote == 1: {
          setNum(parseInt(num) - 1);
          deleteLike({ _id: localLike._id });
          updatePost(post, { likes_inc: -1 });
          setLocalLike(null);
          break;
        }

        // remove  down vote
        case vote === 0 && localLike.vote == -1:
          setNum(parseInt(num) + 1);
          deleteLike({ _id: localLike._id });
          updatePost(post, { likes_inc: 1 });
          setLocalLike(null);
          break;

        default:
        // no op
      }
    }

    // re-enable voting
    setTimeout(() => {
      setCanLike(true);
    }, 1000);
  };

  return (
    <>
      <Like action={action} alignment={alignment} like={localLike} num={num} user={user} />
      <CreateAccountFormModal
        {...props}
        open={showModal}
        setOpen={setShowModal}
        text={'Create an account to like this post'}
      />
    </>
  );
};

LikeWrapper.defaultProps = {
  alignment: 'horizontal',
  likes: 0,
  post: null,
  user: null
};

export default LikeWrapper;
