/**
 * Favourite
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import CreateAccountFormModal from '../create-account-form/CreateAccountFormModal';
import useSaveMutations from '../../graphql/useSaveMutations';
import useSave from '../../graphql/useSingleSave';
import { verificationStatus } from '../../lib/user/user';
import { ANON } from '../../data/Data';
import type { Photo, Post, SaveInput, User, Video } from '../../types/Types';
import Favourite from './Favourite';

type Props = {
  app: *,
  photo: Photo | null,
  post: Post | null,
  user: User,
  video: Video | null
};

const FavouriteWrapper = (props: Props): Node => {
  const { photo, post, user, video } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const { deleteSave, useAddSave } = useSaveMutations();
  const [query, setQuery] = useState({ post: { _id: '61aafbc659cf979136df9faf' } }); // use a fictional post id for initial query
  const [isFavoured, setIsFavoured] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const { save } = useSave(query);
  const status = verificationStatus(user);

  useEffect(() => {
    if (!user) {
      return;
    }

    let newQuery = photo
      ? {
          photo: {
            _id: photo._id
          },
          userId: user._id
        }
      : post
      ? {
          post: {
            _id: post._id
          },
          userId: user._id
        }
      : video
      ? {
          video: {
            _id: video._id
          },
          userId: user._id
        }
      : null;

    setQuery(newQuery);
  }, [photo, post, user, video]);

  useEffect(() => {
    if (!save) {
      setIsFavoured(false);
    } else {
      setIsFavoured(true);
    }
  }, [save]);

  const action = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    // prevent double taps
    if (!canSave) {
      return;
    }

    setIsFavoured(!isFavoured);
    setCanSave(false);
    if (!isFavoured) {
      saveItem();
    } else {
      deleteItem();
    }
  };

  /**
   * Save
   */
  const saveItem = () => {
    if (!user) {
      return;
    }

    if (status === ANON) {
      setShowModal(!showModal);
      return;
    }

    const save: SaveInput = {
      photoId: photo ? photo._id : '',
      postId: post ? post._id : '',
      videoId: video ? video._id : '',
      userId: user._id
    };

    useAddSave.addSave(save);
    setTimeout(() => {
      setCanSave(true);
    }, 1000);
  };

  /**
   * Delete save
   */
  const deleteItem = () => {
    if (!save || !save._id) {
      return null;
    }
    deleteSave(save);
    setTimeout(() => {
      setCanSave(true);
      if (window.location.pathname === '/favourites') {
        window.location.reload();
      }
    }, 1000);
  };

  return (
    <>
      <Favourite action={action} favoured={isFavoured} user={user} />
      <CreateAccountFormModal
        {...props}
        open={showModal}
        setOpen={setShowModal}
        text={'Create an account to save this post'}
      />
    </>
  );
};

FavouriteWrapper.defaultProps = {
  favoured: false,
  photo: null,
  post: null,
  video: null,
  user: null
};

export default FavouriteWrapper;
