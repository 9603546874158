/**
 * SubscribeButton
 * @flow
 */
import React, { Fragment, useEffect, useState, type Node } from 'react';
import { EmailRounded } from '@mui/icons-material';
import { Card, Button } from '@mui/material';
import userOffers from '../../hooks/userOffers';
import userPaymentMethods from '../../hooks/userPaymentMethod';
import ErrorComponent from '../error-component/ErrorComponent';
import Loading from '../loading/Loading';
import PaymentMethodModal from '../payment-method-modal/PaymentMethodModal';
import SubscribeModal from '../subscribe-modal/SubscribeModal';
import CreateAccountFormModal from '../create-account-form/CreateAccountFormModal';
import { currencySymbol } from '../../lib/stripe/stripeHelpers';
import { PROVIDER_TYPES } from '../../data/Data';
import type { Offer, PaymentMethod, SetupIntentType, Stripe, User } from '../../types/Types';
import styles from './SubscribeButton.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  color: string, // @mui theme color: primary, secondary, etc
  owner: User | null,
  stripePromise: Promise<*>,
  user: User
};

const SubscribeButton = (props: Props): Node => {
  const { app, customerPlatform, owner, stripePromise, user } = props;
  const ownerStripe =
    owner && owner.stripe
      ? owner.stripe.find((stripe) => {
          return stripe.accountId && !stripe.customerId;
        })
      : null;

  const userIsOwner = user._id === owner?._id ? true : false;

  if (!ownerStripe || userIsOwner) {
    return null;
  }

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{ error: boolean, message: string }>({ error: false, message: '' });
  const offers: Array<Offer> = userOffers(app, owner);
  const [subscription, setSubscription] = useState<Offer | null>(null);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { emailConfirmed } = user;
  const { firstName, lastName } = owner ? owner : { firstName: '', lastName: '' };
  const paymentMethod: PaymentMethod | null = userPaymentMethods(app, customerPlatform);
  const userAnonymous = app?.currentUser?.providerType === PROVIDER_TYPES.apiKey;

  /**
   * Have we been redirected after adding a payment method?
   * eg: ?setup_intent=seti_1KBTTrBFB2qXDYHDV2FmuN3b
   */
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const setupIntent = search.get('setup_intent');
    const sub = search.get('subscription');

    if (!setupIntent || !customerPlatform || !user || !sub) {
      return;
    }

    setSpinner(true);

    // get the setup intent
    const args = {
      endpoint: `setup_intents/${setupIntent}`,
      method: 'get'
    };
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        parseSetupIntent(result, customerPlatform);
      })
      .catch((err) => console.error('SubscribeButton GET setup_intents', err));
  }, [customerPlatform, user]);

  /**
   * Parse setup intent logic
   */
  const parseSetupIntent = (intent: SetupIntentType, stripeCustomer: Stripe) => {
    const { customer, payment_method, status } = intent;

    switch (true) {
      case status !== 'succeeded':
      case !payment_method:
      case customer !== stripeCustomer.customerId:
        setError({
          error: true,
          message: 'Error saving payment method'
        });
        setSpinner(false);
        break;

      default:
        refreshPage();
    }
  };

  /**
   * refresh the page
   */
  const refreshPage = () => {
    const search = new URLSearchParams(window.location.search);
    const subscription = search.get('subscription');

    setSpinner(true);
    window.history.replaceState({}, document.title, window.location.pathname);

    setTimeout(() => {
      if (subscription) {
        window.location.replace(`${window.location.href}?open_subscription=${subscription}`);
      }
    }, 2000);
  };

  /**
   * after adding paymentMethod the page reloads with param `open_subscription`
   */
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const sub = search.get('open_subscription');

    if (!sub || !offers) {
      return;
    }

    const currentOffer = offers.find((offer) => {
      return offer.id === sub ? true : false;
    });

    if (currentOffer) {
      setSubscription(currentOffer);
      setOpen(true);
    }
  }, [offers]);

  /**
   * Confirm Subscribe
   */
  const confirm = (e: SyntheticMouseEvent<HTMLAnchorElement>, offer: Offer) => {
    e.preventDefault();
    setOpen(!open);
    setSubscription(offer);
  };

  /**
   * Show login modal
   */
  const showLoginModal = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  /**
   * renderSubscribe
   */
  const renderSubscribe = (offer: Offer) => {
    const { price } = offer;
    const { currency, recurring, unit_amount } = price;
    const { interval, interval_count } = recurring;
    const period = interval_count === 1 ? `a ${interval}` : `every ${interval_count} ${interval}s`;
    const symbol = currencySymbol(currency);
    return (
      <Button variant="contained" size="medium" className={styles.Button}>
        Subscribe to {firstName} {lastName} for only {currency.toUpperCase()} {symbol}
        {unit_amount / 100} {period}
      </Button>
    );
  };

  switch (true) {
    case !owner:
      return null;

    case !offers || offers.length === 0:
      return (
        <div className={styles.NoOffers}>
          <Card className={styles.Card}>
            <p>
              {firstName} {lastName} does not have any offers yet.
              <br />
              Check back later to subscribe
            </p>
          </Card>
        </div>
      );

    default:
      return (
        <Fragment>
          <div className={styles.SubscribeContainer}>
            {offers.map((offer, index) => {
              const { price } = offer;
              const { currency, recurring, unit_amount } = price;
              const { interval, interval_count } = recurring;
              const period = interval_count === 1 ? `a ${interval}` : `every ${interval_count} ${interval}s`;
              const symbol = currencySymbol(currency);
              return userAnonymous ? (
                <a key={index} href="#login" onClick={showLoginModal} className={styles.SubscribeButton}>
                  <Button variant="contained" size="medium" className={styles.Button}>
                    Subscribe to {firstName} {lastName} for only {currency.toUpperCase()} {symbol}
                    {unit_amount / 100} {period}
                  </Button>
                </a>
              ) : emailConfirmed ? (
                <a key={index} href="#subscribe" onClick={(e) => confirm(e, offer)} className={styles.SubscribeButton}>
                  {renderSubscribe(offer)}
                </a>
              ) : (
                <a
                  key={index}
                  href="/settings/verify-email"
                  className={styles.SubscribeButton}
                  target="_blank"
                  rel="noreferrer, noopener"
                >
                  <Button variant="contained" size="medium" className={styles.Button}>
                    <EmailRounded /> Confirm your email to subscribe
                  </Button>
                </a>
              );
            })}
          </div>
          {open && paymentMethod ? (
            <SubscribeModal
              {...props}
              accountId={ownerStripe.accountId}
              customerPlatform={customerPlatform}
              open={open}
              paymentMethod={paymentMethod}
              setError={setError}
              setOpen={setOpen}
              subscription={subscription}
              user={user}
            />
          ) : null}
          {open && !paymentMethod && customerPlatform && stripePromise ? (
            <PaymentMethodModal
              {...props}
              accountId={ownerStripe.accountId}
              customerPlatform={customerPlatform}
              open={open}
              setError={setError}
              setOpen={setOpen}
              subscription={subscription}
              user={user}
            />
          ) : null}
          {open && userAnonymous ? (
            <CreateAccountFormModal
              app={app}
              open={open}
              setOpen={setOpen}
              text={`Create an account, or login to subscribe to ${firstName} ${lastName}`}
            />
          ) : null}
          <ErrorComponent {...error} />
          {spinner ? <Loading /> : null}
        </Fragment>
      );
  }
};

SubscribeButton.defaultProps = {
  color: 'secondary',
  offer: null,
  user: null
};

export default SubscribeButton;
