/**
 * SubscribeModal
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import Close from '../close/Close';
import { Alert, Card, CircularProgress, Button, Modal } from '@mui/material';
import ProfileHeaderMinimal from '../profile-header-minimal/ProfileHeaderMinimal';
import userStripeConnectCustomer from '../../hooks/userStripeConnectCustomer';
import { currencySymbol } from '../../lib/stripe/stripeHelpers';
import type { Offer, PaymentMethod, Stripe, TaxRate, User } from '../../types/Types';
import styles from './SubscribeModal.module.scss';

type Props = {
  accountId: string | null,
  customerPlatform: Stripe | null,
  app: *,
  color: string, // Mui theme color: primary, secondary, etc
  owner: User | null,
  open: boolean,
  paymentMethod: PaymentMethod | null,
  setError: ({ error: boolean, message: string }) => *,
  setOpen: (open: boolean) => *,
  stripePromise: Promise<*>,
  subscription: null | Offer,
  user: User
};

const SubscribeModal = (props: Props): Node => {
  const { accountId, app, customerPlatform, open, owner, paymentMethod, setOpen, subscription, user } = props;
  const customerConnect: Stripe | null = userStripeConnectCustomer(app, user, accountId);
  const [connectPaymentMethod, setConnectPaymentMethod] = useState<null | string>(null);
  const [subResult, setSubResult] = useState<boolean>(false);
  const [taxRate, setTaxRate] = useState<TaxRate | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [error, setError] = useState<{ error: boolean, message: string }>({ error: false, message: '' });

  /**
   * Clone payment method from Platform account to connect account if it doesn't already exist
   */
  useEffect(() => {
    if (!customerConnect || !customerPlatform || !paymentMethod) {
      return;
    }

    if (customerConnect.paymentMethods) {
      setConnectPaymentMethod(customerConnect.paymentMethods[0]);
      return;
    }

    const args = {
      accountId,
      customerConnectId: customerConnect.customerId,
      customerPlatformId: customerPlatform.customerId,
      paymentMethod: paymentMethod.id
    };
    app.currentUser
      .callFunction('stripeClonePaymentMethodToConnectAccount', args)
      .then((result) => {
        // result is the cloned ID
        setConnectPaymentMethod(result);
      })
      .catch((err) => console.error('stripeClonePaymentMethodToConnectAccount err', err));
  }, [customerPlatform, customerConnect, paymentMethod]);

  /**
   * get all tax rates
   */
  useEffect(() => {
    if (!accountId) {
      return;
    }

    const args = {
      accountId,
      endpoint: 'tax_rates',
      method: 'get'
    };

    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('tax_rates', result);
        setTaxRate(result.data[0]);
      })
      .catch((err) => {
        console.warn('Stripe error');
        console.error(err);
        setError({
          error: true,
          message: err.message
        });
      });
  }, [accountId]);

  /**
   * get country of customer from IP address
   */
  useEffect(() => {
    if (!app) {
      return;
    }

    ipToCountry();
  }, [app]);

  const ipToCountry = async () => {
    const userCountry = await app.currentUser.callFunction('ipToCountry');
    setCountry(userCountry);
  };

  /**
   * Close the Modal
   */
  const closeModal = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    setOpen(!open);
  };

  /**
   * Subscribe
   */
  const subscribe = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.warn('accountId', accountId);
    console.warn('customerConnect', customerConnect);
    console.warn('subscription', subscription);
    console.warn('owner', owner);
    console.warn('connectPaymentMethod', connectPaymentMethod);
    if (!accountId || !customerConnect || !subscription || !owner || !connectPaymentMethod) {
      return null;
    }

    setSpinner(true);

    let data = {
      application_fee_percent: '10',
      'automatic_tax[enabled]': false,
      customer: customerConnect.customerId,
      'expand[]': 'latest_invoice.payment_intent',
      'items[0][price]': subscription.price.id,
      payment_behavior: 'allow_incomplete',
      metadata: {
        ownerId: owner._id,
        userId: user?._id
      }
    };

    if (country === 'AU' && taxRate) {
      // $FlowFixMe
      data['default_tax_rates[]'] = taxRate.id;
    }

    const args = {
      endpoint: 'subscriptions',
      method: 'post',
      data,
      accountId
    };

    console.warn(args);
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('Stripe CREATE subscription result', result);

        if (!result.plan) {
          setError({
            error: true,
            message: 'Error creating subscription'
          });
          return;
        }
        setSubResult(result?.plan?.active);
        setTimeout(() => {
          window.location.href = `${window.location.origin}${window.location.pathname}`;
        }, 5000);
      })
      .catch((err) => console.error('Subscribe Modal CREATE subscription', err));
  };

  if (!owner || !subscription) return null;
  const { firstName, lastName } = owner;
  const { price } = subscription;
  const { currency, unit_amount, recurring } = price;
  const { interval, interval_count } = recurring;
  const period = interval_count === 1 ? `a ${interval}` : `every ${interval_count} ${interval}s`;
  const symbol = currencySymbol(currency);

  return (
    <Modal open={true} className={styles.SubscribeModal} onBackdropClick={closeModal}>
      <Card className={styles.Card}>
        <Close onClick={closeModal} />
        <ProfileHeaderMinimal link={false} user={owner} parent="subscribe" profileImageClassName="ProfileImageMini" />
        <div className={styles.Content}>
          {subResult ? (
            <div className={styles.Success}>
              <Alert severity="success">
                You are now subscribed to {firstName} {lastName}!
              </Alert>
              <div className={styles.Reloading}>
                <CircularProgress /> Reloading
              </div>
            </div>
          ) : (
            <div className={styles.subscribe}>
              <p>
                Subscribe to {firstName} {lastName} for only {currency.toUpperCase()} {symbol}
                {(unit_amount / 100).toFixed(2)} {period}
                <br />
                with your credit card ending in <strong>{paymentMethod?.card?.last4}</strong>
              </p>
              {error.error ? (
                <Alert severity="error">{error.message}</Alert>
              ) : spinner || !connectPaymentMethod ? (
                <CircularProgress />
              ) : (
                <a onClick={subscribe} href="#confirm-subscription">
                  <Button variant="contained" size="medium" className={styles.Button}>
                    Yes, subscribe!
                  </Button>
                </a>
              )}
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
};

SubscribeModal.defaultProps = {
  color: 'secondary',
  offer: null,
  user: null
};

export default SubscribeModal;
