/**
 * Profile Page
 * @flow
 */
import React, { Fragment, useEffect, useState, type Node } from 'react';
import ProfileHeader from '../../components/profile-header/ProfileHeader';
import Feed from '../../components/feed/Feed';
import Loading from '../loading/Loading';
import SadFace from '../sad-face/SadFace';
import userVerificationStatus from '../../hooks/userVerified';
import type { Stripe, User } from '../../types/Types';
import useGetUserQuery from '../../graphql/userQueries';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  user: User
};

const ProfilePageMain = (props: Props): Node => {
  const { user } = props;
  const parts = window.location.pathname.split('/');
  const userName = parts[parts.length - 1];
  const profile = useGetUserQuery({ userName });
  const [loading, setLoading] = useState<boolean>(true);
  const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
  const [query, setQuery] = useState<*>(null);
  const status = userVerificationStatus(profile);

  useEffect(() => {
    if (!profile || !profile.user || !user) {
      setLoading(false);
      return;
    }

    const isOwner = profile.user._id === user._id ? true : false;
    setUserIsOwner(isOwner);
    setLoading(false);

    if (!query) {
      setQuery({
        userId_in: [profile.user._id]
      });
    }
  }, [profile, query, user]);

  switch (true) {
    case loading || !user:
      return <Loading />;
    case !profile || !profile.user || !profile.user._id:
      return <SadFace text="Not found" />;

    default:
      return (
        <Fragment>
          <ProfileHeader {...props} owner={profile.user} status={status} />
          <Feed {...props} query={query} owner={profile.user} user={user} userIsOwner={userIsOwner} />
        </Fragment>
      );
  }
};

export default ProfilePageMain;
