/**
 * Get Verified
 * @flow
 */
import React, { type Node } from 'react';
import { Button } from '@mui/material';
import { VerifiedRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import styles from './GetVerifiedButton.module.scss';

type Props = {
  text: string,
  to: string
};

const GetVerifiedButton = (props: Props): Node => {
  const { text, to } = props;
  return (
    <div className={styles.GetVerifiedButton}>
      <Link to={to}>
        <Button variant="contained">
          <VerifiedRounded /> {text}
        </Button>
      </Link>
    </div>
  );
};

GetVerifiedButton.defaultProps = {
  text: 'Get Verified to upload',
  to: '/settings/get-verified'
};

export default GetVerifiedButton;
