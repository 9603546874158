/**
 * CreateAccountForm
 * @flow
 */
import React, { useState, type Node } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import * as Realm from 'realm-web';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import UserNameCreateField from '../user-name-create-field/UserNameCreateField';
import validator from 'validator';
import { getEnvironment, getWebhooksUrl, OPTIONS } from '../../data/Data';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './CreateAccountForm.module.scss';

type Props = {
  app: *,
  formRef: *,
  text: string | null
};

const ACTION = 'createAccountOrLogin';
const NO_ERRORS = { email: null, password: null, username: null };

const CreateAccountForm = (props: Props): Node => {
  const { app, formRef, text } = props;
  const [role, setRole] = useState<string>('create');
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [honeypot, setHoneypot] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState(NO_ERRORS);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const updateEmail = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    const isEmail = validator.isEmail(value);
    setError({
      ...error,
      email: !isEmail ? 'Your email is not valid' : null
    });
  };

  const updatePassword = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    setError(NO_ERRORS);
  };

  const updateUsername = (value: string, valid: boolean) => {
    setUsername(value.trim());
    setError({
      ...error,
      username: !valid ? 'Username is not valid' : null
    });
  };

  const changeRole = (e: SyntheticEvent<>) => {
    e.preventDefault();
    setRole(role === 'login' ? 'create' : 'login');
    setError(NO_ERRORS);
  };

  const updateHp = (e: SyntheticInputEvent<HTMLInputElement>) => {
    setHoneypot(e.target.value);
  };

  const handleLogin = async (isFirstLogin: boolean = false) => {
    if (honeypot !== '') {
      return;
    }

    setIsLoggingIn(true);

    try {
      const credentials = Realm.Credentials.emailPassword(email, password);
      await app.logIn(credentials);

      if (isFirstLogin) {
        // Set the username
        const setUsername = await app.currentUser.callFunction('setUsername', { email, username });
        console.warn(setUsername);
      }
      window.location.reload();
    } catch (err) {
      handleAuthenticationError(err, setError);
      setIsLoggedIn(false);
      setIsLoggingIn(false);
    }
  };

  const handleRegistrationAndLogin = async () => {
    if (honeypot !== '') {
      return;
    }

    setIsLoggingIn(true);

    // Check recaptcha to filer out bots
    const token = await executeRecaptcha(ACTION);
    const url = `${getWebhooksUrl(window)}/recaptcha`;
    const options = {
      ...OPTIONS,
      body: JSON.stringify({ token })
    };
    const recaptcha = await fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.warn(error);
        setIsLoggingIn(false);
      });

    /**
     * recaptcha result looks like
     *
     * {
     *    action: "createAccountOrLogin"
     *    challenge_ts: "2022-05-20T07:31:09Z"
     *    hostname: "localhost"
     *    score: 0.9
     *    success: true
     *  }
     */

    // score of 1 is human, 0 is bot
    if (recaptcha && recaptcha.score && recaptcha.score < 0.5 && recaptcha.action === ACTION) {
      console.error('User looks like a bot'); // prevent account creation
      return;
    }

    const isValidEmailAddress = validator.isEmail(email);

    setError((e) => ({
      ...e,
      password: null
    }));

    if (isValidEmailAddress) {
      try {
        // Register the user and, if successful, log them in
        await app.emailPasswordAuth.registerUser(email, password);

        // Log creation of account
        if (getEnvironment(window) === 'prod') {
          logEvent(analytics, 'create_account', {});
        }

        // Login
        await handleLogin(true);
        window.location.reload();
      } catch (err) {
        handleAuthenticationError(err, setError);
        setIsLoggingIn(false);
      }
    } else {
      setError((err) => ({ ...err, email: 'Email is invalid.' }));
    }
  };

  switch (true) {
    case isLoggedIn:
      return <Redirect to="/" />;

    case isLoggingIn && !error.email && !error.password:
      return (
        <div className={styles.LoggingIn}>
          <CircularProgress />
          <div className={styles.text}>Logging in...</div>
        </div>
      );

    default:
      return (
        <section ref={formRef} className={styles.CreateAccountLogin}>
          <h2>{role === 'login' ? 'Login' : 'Create Account'}</h2>
          {text ? <p>{text}</p> : null}
          {error.email || error.password || error.username ? (
            <div className={styles.Error}>
              <WarningRoundedIcon />
              {error.email ? error.email : error.password ? error.password : error.username ? error.username : null}
            </div>
          ) : null}
          <form className={styles.CreateAccountForm} noValidate autoComplete="off">
            {role === 'login' ? null : (
              <UserNameCreateField
                app={app}
                error={error}
                setError={setError}
                updateUsername={updateUsername}
                username={username}
              />
            )}
            <FormControl>
              <TextField
                autoFocus={true}
                error={error.email ? true : false}
                type="email"
                label="Email"
                id="email"
                onChange={updateEmail}
                value={email}
              />
            </FormControl>
            <FormControl>
              <TextField
                error={error.password ? true : false}
                helperText={error.password ? error.password : ''}
                type="password"
                label="Password"
                id="password"
                onChange={updatePassword}
                value={password}
              />
            </FormControl>
            <div className={styles.ShowMeNot}>
              <input type="text" onChange={updateHp} value={honeypot} />
            </div>
            <Button
              disabled={error.email || error.password || error.username ? true : false}
              variant="contained"
              color="primary"
              size="large"
              onClick={role === 'login' ? () => handleLogin(false) : () => handleRegistrationAndLogin()}
            >
              {role === 'login' ? 'Login' : 'Create'}
            </Button>
            <div className={styles.Switch}>
              {role === 'login' ? (
                <a href="/create-account" onClick={changeRole}>
                  Create an account
                </a>
              ) : (
                <a href="/login" onClick={changeRole}>
                  Have an account? Login
                </a>
              )}
            </div>
            {role === 'login' ? (
              <div className={styles.Switch}>
                <a href="/request-password-reset">Trouble logging in?</a>
              </div>
            ) : null}
          </form>
        </section>
      );
  }
};

CreateAccountForm.defaultProps = {
  app: null,
  formRef: null,
  text: null
};

export default CreateAccountForm;

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case 'invalid username':
      setError(() => ({ email: 'Invalid email address.' }));
      break;
    case 'invalid username/password':
    case 'invalid password':
    case '401':
      setError(() => ({ password: 'Email or password incorrect.' }));
      break;
    case 'name already in use':
    case '409':
      setError(() => ({ email: 'Email is already registered.' }));
      break;
    case 'password must be between 6 and 128 characters':
    case '400':
      setError(() => ({
        password: 'Password must be between 6 and 128 characters.'
      }));
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(':');
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: '', message: '' };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}
