/**
 * Post
 * @flow
 */
import React, { useState, useEffect, type Node } from 'react';
import type { Post as PostType, Stripe, User } from '../../types/Types';
import usePostMutations from '../../graphql/usePostMutations';
import userSubscriptions from '../../hooks/userSubscriptions';
import Post from './Post';

type Props = {
  app: *,
  isSingle: boolean,
  post: PostType,
  posts?: Array<PostType>,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  user: User
};

const PostWrapper = (props: Props): null | Node => {
  const { post, user } = props;
  const { _id } = post;
  const [deleted, setDeleted] = useState(false);
  const [isSubbed, setIsSubbed] = useState(false);
  const { deletePost } = usePostMutations();
  const subscriptions = userSubscriptions(user);

  useEffect(() => {
    if (!post || !subscriptions || subscriptions.length === 0) {
      return;
    }

    if (subscriptions.indexOf(post.user._id) > -1) {
      setIsSubbed(true);
    }
  }, [subscriptions, post]);

  /**
   * Delete the post
   */
  const reallyDeletePost = () => {
    console.warn(`deletePost ${_id}`);
    deletePost(post);
    setDeleted(true);
  };

  if (!post) {
    return null;
  }

  return <Post {...props} deleted={deleted} post={post} reallyDeletePost={reallyDeletePost} isSubbed={isSubbed} />;
};

PostWrapper.defaultProps = {
  isReply: false,
  isSingle: false,
  post: ({}: { ... })
};

export default PostWrapper;
