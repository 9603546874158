/**
 * Logo
 * @flow
 */
import React, { type Node } from 'react';
import Squirrel from '../../../assets/icons/supreme-squirrel.svg';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/windowResize';
import styles from './Logo.module.scss';

const Logo = (): Node => {
  const { width } = useWindowSize();

  if (width > 1000) {
    return (
      <NavLink to="/" className={styles.NavLogo} exact={true}>
        <img src={Squirrel} alt="Nuttify: Supreme Squirrel" />
        nuttify
        <div className={styles.Beta}>Beta</div>
      </NavLink>
    );
  }

  return (
    <NavLink to="/" className={styles.Logo}>
      <img src={Squirrel} alt="Nuttify: Supreme Squirrel" />
    </NavLink>
  );
};

export default Logo;
