/**
 * Share
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { EmailRounded } from '@mui/icons-material';
import isEmail from 'validator/lib/isEmail';
import { Button, FormControl, Input } from '@mui/material';
import usePostText from '../../../hooks/usePostText';
import type { Post, User } from '../../../types/Types';
import styles from './ShareEmail.module.scss';

type Props = {
  closePopover: () => *,
  footer: string,
  owner: User | null, // the owner of this profile
  post: Post | null,
  setMessage: ({
    text: string,
    severity: string,
    open: boolean
  }) => *,
  target: 'profile' | 'post',
  user: User | null // the logged in user
};

const ShareEmail = (props: Props): Node | null => {
  const { closePopover, footer, owner, user, post, setMessage, target } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('post URL');
  const [email, setEmail] = useState<{ value: string, error: boolean }>({ value: '', error: false });
  const [url, setUrl] = useState<string>('');
  const { contentString, contentArray } = usePostText(post);
  const newLine = '%0d%0a';

  useEffect(() => {
    const emailFooter = footer.replace(/\n/g, newLine);
    console.warn('ShareEmail emailFooter', emailFooter);

    if (target === 'profile' && user && owner) {
      let shareUrl = `${window.location.origin}/${owner.userName}`;
      const isOwner = owner._id === user._id ? true : false;
      setText(isOwner ? 'your profile URL' : `@${owner.userName}'s profile URL`);

      const subject = isOwner
        ? 'Hey, Check out my profile on nuttify!'
        : `Hey, Check out this @${owner.userName}'s profile on nuttify`;

      const body = `I'm on nuttify!,${newLine}${newLine}View my profile ${shareUrl}${newLine}${newLine}`;
      const content = [`mailto:${email.value}`, `?subject=${subject}`, `&body=${body}${emailFooter}`];
      setUrl(content.join(''));
      return;
    }

    if (target === 'post' && post) {
      let shareUrl = `${window.location.origin}/post/${post._id}`;
      const snippet = contentString ? contentString : contentArray.length > 0 ? contentArray[0].children[0].text : '';
      const body = snippet
        ? `@${post.user.userName} posted: "${snippet}..."${newLine}${newLine}Read more at ${shareUrl}`
        : `View @${post.user.userName}'s post on nuttify at ${shareUrl}`;
      const content = [
        `mailto:${email.value}`,
        `?subject=I just found this great post by @${post.user.userName} on nuttify`,
        `&body=${body}${emailFooter}`
      ];
      setUrl(content.join(''));
    }
  }, [footer, owner, user, email, target, contentString, contentArray]);

  /**
   * Check if email(s) are valid
   */
  const isValid = (value: string) => {
    const re = new RegExp(/,\s*/, 'gi');

    // if is a bunch of emails separated by `;`
    if (re.test(value)) {
      let parts = value.split(re);

      for (let part of parts) {
        if (!isEmail(part)) {
          return true;
        }
      }
      return false;
    }

    return !isEmail(value);
  };

  const changeEmail = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const error = isValid(value);

    setEmail({ value, error });
  };

  /**
   * When Email is chosen
   */
  const onClick = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  /**
   * Do the email thing
   */
  const emailAction = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (email.error || email.value === '') {
      setMessage({
        text: 'Invalid email address(es)',
        severity: 'error',
        open: true
      });
      return;
    }

    closePopover();
    window.location.href = url;
  };

  return (
    <li className={styles.ShareEmail}>
      <a href="#send-email" onClick={onClick}>
        <EmailRounded />
        Email {text}
      </a>
      {open ? (
        <div className={styles.EmailInput}>
          <form className={styles.InputContainer}>
            <FormControl>
              <Input
                id="email-address"
                autoFocus={true}
                error={email.error}
                fullWidth={true}
                value={email.value}
                placeholder="Email address"
                onChange={changeEmail}
              />
            </FormControl>
            <p className={styles.info}>Enter an email address, or several comma (,) separated addresses</p>
            <Button className={styles.button} disabled={email.error} variant="contained" onClick={emailAction}>
              Go
            </Button>
          </form>
        </div>
      ) : null}
    </li>
  );
};

ShareEmail.defaultProps = {
  closePopover: (): null => null,
  target: 'post',
  post: null,
  setMessage: (): null => null,
  owner: null,
  user: null
};

export default ShareEmail;
