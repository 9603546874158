/**
 * Share
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { LinkRounded } from '@mui/icons-material';
import type { Post, User } from '../../../types/Types';
import styles from './ShareCopyUrl.module.scss';

type Props = {
  closePopover: () => *,
  owner: User | null, // the owner of this profile
  post: Post | null,
  setMessage: ({
    text: string,
    severity: string,
    open: boolean
  }) => *,
  target: 'profile' | 'post',
  user: User | null // the logged in user
};

const ShareCopyUrl = (props: Props): Node | null => {
  const { closePopover, owner, user, post, setMessage, target } = props;
  const [text, setText] = useState<string>('post URL');
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (target === 'profile' && user && owner) {
      const isOwner = owner._id === user._id ? true : false;
      setText(isOwner ? 'your profile URL' : `@${owner.userName}'s profile URL`);
      setUrl(window.location.href);
      return;
    }
    if (target === 'post' && post) {
      setUrl(`${window.location.origin}/post/${post._id}`);
    }
  }, [owner, user, target]);

  /**
   * Copy the URL of the user's profile with GA tracking params
   */
  const copyUrl = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetTitle = `${target.substr(0, 1).toUpperCase()}${target.substr(1)}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setMessage({
          open: true,
          severity: 'success',
          text: `${targetTitle} URL copied`
        });
        closePopover();
      })
      .catch(() => {
        setMessage({
          open: true,
          severity: 'error',
          text: `${targetTitle} URL failed to copy`
        });
        closePopover();
      });
  };

  return (
    <li className={styles.ShareCopyUrl}>
      <a href="#copy-url" onClick={copyUrl}>
        <LinkRounded />
        Copy {text}
      </a>
    </li>
  );
};

ShareCopyUrl.defaultProps = {
  closePopover: (): null => null,
  target: 'post',
  post: null,
  setMessage: (): null => null,
  owner: null,
  user: null
};

export default ShareCopyUrl;
