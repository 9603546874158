/**
 * Get user subscriptions details
 * @flow
 */
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import type { User } from '../types/Types';

export default function userSubscriptions(user: User): Array<string> | null {
  const [subscriptions, setSubscriptions] = useState<null | Array<string>>(null);

  useEffect(() => {
    if (!user || !user.subscriptions) {
      return;
    }

    let subs = [];

    user.subscriptions.map((sub) => {
      const { creatorId, currentPeriodEnd } = sub;
      const now = dayjs();
      const subEnd = dayjs(currentPeriodEnd);
      const diff = subEnd.diff(now, 'seconds');

      if (diff >= 0) {
        subs.push(creatorId);
      }
    });

    if (subs.length > 0) {
      setSubscriptions(subs);
    }
  }, [user]);
  return subscriptions;
}
