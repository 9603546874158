/**
 * Text for post
 * @flow
 */
import React, { type Node } from 'react';
import Paragraph from '../paragraph/Paragraph';
import usePostText from '../../hooks/usePostText';
import type { Post as PostType, User } from '../../types/Types';
import styles from './Text.module.scss';

type Props = {
  post: PostType,
  show: boolean,
  user: User
};

const Text = (props: Props): Node => {
  const { post } = props;
  const { contentString, contentArray } = usePostText(post);

  switch (true) {
    case !post:
      return null;

    default:
      return (
        <div className={styles.Container}>
          {post.premium && contentArray.length > 0 ? (
            <Paragraph element={contentArray[0]} />
          ) : contentArray.length > 0 ? (
            contentArray.map((item, index) => {
              return <Paragraph key={index} element={item} />;
            })
          ) : null}
          {contentString}
        </div>
      );
  }
};

Text.defaultProps = {
  show: false
};

export default Text;
