/**
 * useWindowSize hook
 * @flow
 */

import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

// Hook
export function useWindowSize(delay: number = 100): { width: any, height: any } {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    const debouncedHandleResize = debounce(handleResize, delay);
    // Add event listener
    window.addEventListener('resize', debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size
    debouncedHandleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [delay]);

  return windowSize;
}
