/**
 * Spacer
 * @flow
 */
import React, { type Node } from 'react';
import styles from './Spacer.module.scss';

type Props = {
  height: string
};

const Spacer = (props: Props): Node => {
  const { height } = props;
  return <div className={styles.Spacer} style={{ height }} />;
};

Spacer.defaultProps = {
  height: '1rem'
};

export default Spacer;
