import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import usePostMutations from './usePostMutations';

const usePosts = (query, limit = 10) => {
  const { posts, loading } = getAllPosts(query, limit);

  const { addPost, updatePost } = usePostMutations();
  return {
    loading,
    posts,
    updatePost,
    addPost
  };
};
export default usePosts;

function getAllPosts(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetPosts($query: PostQueryInput!, $limit: Int) {
        posts(query: $query, sortBy: POSTID_DESC, limit: $limit) {
          _id
          title
          content
          comments
          created
          likes
          mediaOrder
          parent {
            _id
            user {
              userName
            }
          }
          photos {
            _id
            filePath
            width
            height
          }
          premium
          replies {
            _id
            title
            content
            comments
            created
            likes
            parent {
              _id
              user {
                userName
              }
            }
            photos {
              _id
              filePath
              width
              height
            }
            premium
            replies {
              _id
            }
            user {
              _id
              userName
              profileImage
              firstName
              lastName
              verified
              stripe {
                _id
                accountId
                bankAccount
                customerId
                setupIntents
                paymentMethods
                stripeId
              }
            }
            videos {
              _id
              filePath
              poster {
                _id
                filePath
              }
            }
          }
          user {
            _id
            userName
            profileImage
            firstName
            lastName
            verified
            stripe {
              _id
              accountId
              bankAccount
              customerId
              setupIntents
              paymentMethods
              stripeId
            }
          }
          videos {
            _id
            filePath
            poster {
              _id
              filePath
            }
          }
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch posts: ${error.message}`);
  }

  // If the query has finished, return the posts from the result data
  // Otherwise, return an empty list
  const posts = data?.posts ?? [];
  return { posts, loading };
}
