/**
 * ProfileImage
 * @flow
 */
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import { Modal } from '@mui/material';
import ProfileImageEditor from '../profile-image-editor/ProfileImageEditor';
import useGetUserQuery from '../../graphql/userQueries';
import { useRealmApp } from '../../RealmApp';
import { getProfileImageUrl } from '../../data/Data';
import type { MediaSubtypes } from '../../types/Types';
import styles from './ProfileImage.module.scss';

type Props = {
  aspectRatio: number,
  className: string,
  clickable: boolean,
  edit: boolean,
  field: MediaSubtypes,
  image: string,
  id: string,
  updateImage: (field: MediaSubtypes, image: *) => *
};

const ProfileImage = (props: Props): Node => {
  const { edit, field, className, clickable, image, updateImage } = props;
  const app = useRealmApp();
  const { pathname } = window.location;
  const parts = pathname.substr(1).split('/');
  const pageUserName = parts[parts.length - 1];
  const current = useGetUserQuery({ userId: app?.currentUser?.id });
  const profile = useGetUserQuery({ userName: pageUserName });
  const { user } = profile;
  const editable = current && current.user && user && current.user._id === user._id ? true : false;
  const [modal, setModal] = useState(false);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (!profile.user) {
      return;
    }

    switch (true) {
      case image === '':
        return;

      case /^data:image/.test(image):
        setSrc(image);
        break;

      default:
        setSrc(`${getProfileImageUrl(window)}/${profile.user._id}/${field}.jpg`);
    }
  }, [field, image, src, profile]);

  /**
   * View image in Modal
   */
  const view = () => {
    if (!edit && clickable && image) {
      setModal(!modal);
    }
  };

  const style = src ? { backgroundImage: `url('${src}')` } : {};

  if (modal) {
    return (
      <Modal open={modal} onBackdropClick={view} className={styles.FullscreenContainer}>
        <img className={styles.FullScreen} src={src} alt="" onClick={view} tabIndex="0" />
      </Modal>
    );
  }
  return (
    <div className={styles[className]} style={style} onClick={view} tabIndex="0">
      {edit && editable ? <ProfileImageEditor {...props} updateImage={updateImage} /> : null}
    </div>
  );
};

ProfileImage.defaultProps = {
  aspectRatio: 1,
  className: 'ProfileImage',
  clickable: true,
  edit: false,
  field: 'profileImage',
  id: 'profile-image',
  image: '',
  updateImage: (): null => null
};

export default ProfileImage;
