/**
 * Share
 * @flow
 */
import React, { useEffect, useState, Fragment, type Node } from 'react';
import { IosShareRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ShareCopyUrl from './share-copy-url/ShareCopyUrl';
import ShareEmail from './share-email/ShareEmail';
import { Alert, ClickAwayListener, Popover, Snackbar } from '@mui/material';
import { useWindowSize } from '../../hooks/windowResize';
import type { Post, User } from '../../types/Types';
import styles from './Share.module.scss';

type Props = {
  target: 'profile' | 'post',
  post: Post | null,
  owner: User | null, // the owner of this profile
  user: User | null // the logged in user
};

const Share = (props: Props): Node | null => {
  const { owner, target, user } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [footer, setFooter] = useState<string>('');
  const [message, setMessage] = useState<{
    open: boolean,
    text: string,
    severity: string
  }>({
    open: false,
    text: '',
    severity: 'info'
  });
  const { width } = useWindowSize();

  /**
   * Setup footer text
   */
  useEffect(() => {
    if (!user) {
      return;
    }

    const defaultText = `Nuttify is your newest Twitter alternative\nJoin now and start posting.\nVerify your account to earn money from your premium content\n${window.location.origin}`;

    switch (true) {
      case target === 'profile' && owner && owner._id === user._id:
        setFooter(defaultText);
        return;

      case target === 'profile' && owner && owner._id !== user._id:
      case target === 'post':
        setFooter(`\n\nView my profile on nuttify at ${window.location.origin}/${user.userName}\n\n${defaultText}`);
        return;

      default:
      // no op
    }
  }, [owner, setFooter, target, user]);

  /**
   * Open Popover
   */
  const togglePopover = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorEl(e.target);
    setOpen(!open);
  };

  /**
   * Close popover
   */
  const closePopover = () => {
    setOpen(false);
  };

  /**
   * HandleClose
   */
  const handleClose = () => {
    setMessage({
      ...message,
      open: false
    });
  };

  return (
    <Fragment>
      <a href="#share-link" className={styles.Share} onClick={togglePopover} title="Share">
        <IosShareRounded />
      </a>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: width < 768 ? 'right' : 'left', vertical: 'bottom' }}
      >
        <ClickAwayListener onClickAway={closePopover}>
          <div className={styles.AlertInner}>
            <ul>
              <ShareCopyUrl {...props} setMessage={setMessage} closePopover={closePopover} />
              <ShareEmail {...props} footer={footer} setMessage={setMessage} closePopover={closePopover} />
            </ul>
          </div>
        </ClickAwayListener>
      </Popover>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={message.open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ bottom: { xs: 100, sm: 100 } }}
      >
        <Link to="/notifications" onClick={handleClose}>
          <Alert severity={message.severity}>{message.text}</Alert>
        </Link>
      </Snackbar>
    </Fragment>
  );
};

Share.defaultProps = {
  target: 'post',
  post: null,
  owner: null,
  user: null
};

export default Share;
