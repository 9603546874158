/**
 * Video
 * @flow
 */
import React, { useRef, useState } from 'react';
import type { Node } from 'react';
import { Slider } from '@mui/material';
import styles from './PlayBar.module.scss';

type Props = {
  duration: number,
  time: number,
  play: boolean,
  setJumpTime: (time: number) => *,
  togglePlay: (e: SyntheticEvent<*>) => *
};

const VideoControls = (props: Props): Node => {
  const { duration, play, setJumpTime, time, togglePlay } = props;
  const [mouseDown, setMouseDown] = useState(false);
  const [touch, setTouch] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const playBarRef = useRef(null);

  /**
   * onClick
   */
  const onClick = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { button, clientX } = e;

    if (!playBarRef || !playBarRef.current || button > 0) {
      return;
    }

    const { width, left } = playBarRef.current.getBoundingClientRect();
    const jump = ((clientX - left) / width) * duration;
    setJumpTime(jump);
  };

  /**
   * onMouseDownCapture
   */
  const onMouseDownCapture = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { button } = e;

    if (!playBarRef || !playBarRef.current || button > 0) {
      return;
    }
    setWasPlaying(play);
    setMouseDown(true);
  };

  /**
   * onMouseUpCapture
   */
  const onMouseUpCapture = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { button } = e;

    if (!playBarRef || !playBarRef.current || button > 0) {
      return;
    }

    if (!play && wasPlaying) {
      togglePlay(e);
    }

    setMouseDown(false);
  };

  /**
   * onMouseMove
   */
  const onMouseMove = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!playBarRef || !playBarRef.current || !mouseDown) {
      return;
    }

    if (play) {
      setWasPlaying(true);
      togglePlay(e);
    }

    const { clientX } = e;
    const { width, left } = playBarRef.current.getBoundingClientRect();
    const jump = ((clientX - left) / width) * duration;
    setJumpTime(jump);
  };

  /**
   * onTouchStart
   */
  const onTouchStart = (e: SyntheticTouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { touches } = e;

    // not doing multitouch
    if (!playBarRef || !playBarRef.current || touches.length > 1) {
      return;
    }

    const { clientX } = touches[0];
    setTouch(true);
    if (play) {
      setWasPlaying(true);
      togglePlay(e);
    }
    const { width, left } = playBarRef.current.getBoundingClientRect();
    const jump = ((clientX - left) / width) * duration;
    setJumpTime(jump);
  };

  /**
   * onTouchEnd
   */
  const onTouchEnd = (e: SyntheticTouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { touches } = e;

    // not doing multitouch
    if (!playBarRef || !playBarRef.current || touches.length > 1) {
      return;
    }

    if (!play && wasPlaying) {
      togglePlay(e);
    }
    setTouch(false);
  };

  /**
   * onTouchMove
   */
  const onTouchMove = (e: SyntheticTouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { touches } = e;

    // not doing multitouch
    if (!playBarRef || !playBarRef.current || touches.length > 1 || !touch) {
      return;
    }

    const { clientX } = touches[0];
    setTouch(true);
    const { width, left } = playBarRef.current.getBoundingClientRect();
    const jump = ((clientX - left) / width) * duration;
    setJumpTime(jump);
  };

  const value = time && duration ? (time / duration) * 100 : 0;

  return (
    <div
      // $FlowFixMe
      ref={playBarRef}
      className={styles.PlayBar}
      onClick={onClick}
      onMouseDownCapture={onMouseDownCapture}
      onMouseUpCapture={onMouseUpCapture}
      onMouseMove={onMouseMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      <Slider color="primary" value={value} />
    </div>
  );
};

VideoControls.defaultProps = {
  duration: 0,
  setJumpTime: (): null => null,
  play: false,
  time: 0,
  togglePlay: (): null => null
};

export default VideoControls;
