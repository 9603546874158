import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function usePhotoMutations() {
  return {
    useAddPhoto: useAddPhoto(),
    updatePhoto: useUpdatePhoto(),
    deletePhoto: useDeletePhoto()
  };
}

// This uses a custom resolver to insert a new photo.
// See app/functions/customPhotoInputResolver.js
const AddPhotoMutation = gql`
  mutation CustomAddPhoto($photo: CustomPhotoInput!) {
    addedPhoto: CustomPhotoInput(input: $photo) {
      _id
      created
      filePath
      title
      type
      width
      height
      user {
        _id
        userName
        firstName
        lastName
      }
    }
  }
`;

const UpdatePhotoMutation = gql`
  mutation UpdatePhoto($photoId: ObjectId!, $updates: PhotoUpdateInput!) {
    updatedPhoto: updateOnePhoto(query: { _id: $photoId }, set: $updates) {
      _id
    }
  }
`;

const DeletePhotoMutation = gql`
  mutation DeletePhoto($photoId: ObjectId!) {
    deletedPhoto: deleteOnePhoto(query: { _id: photoId }) {
      _id
    }
  }
`;

const PhotoFieldsFragment = gql`
  fragment PhotoFields on Photo {
    _id
    created
    filePath
    title
    width
    height
    type
    user {
      _id
      userName
      firstName
      lastName
    }
  }
`;

function useAddPhoto() {
  const [addPhotoMutation, { data, loading, error }] = useMutation(AddPhotoMutation, {
    // Manually save added Photos into the Apollo cache so that Photo queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedPhoto } }) => {
      cache.modify({
        fields: {
          photos: (existingPhotos = []) => [
            ...existingPhotos,
            cache.writeFragment({
              data: addedPhoto,
              fragment: PhotoFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addPhoto = async (photo) => {
    const { id, ...params } = photo;
    const { addedPhoto } = await addPhotoMutation({
      variables: {
        photo: {
          _id: id,
          photoId: id,
          ...params
        }
      }
    });
    return addedPhoto;
  };

  return { addPhoto, data, loading, error };
}

function useUpdatePhoto() {
  const [updatePhotoMutation] = useMutation(UpdatePhotoMutation);
  const updatePhoto = async (photo, updates) => {
    const { updatedPhoto } = await updatePhotoMutation({
      variables: { photoId: photo._id, updates }
    });
    return updatedPhoto;
  };
  return updatePhoto;
}

function useDeletePhoto() {
  const [deletePhotoMutation] = useMutation(DeletePhotoMutation);
  const deletePhoto = async (photo) => {
    const { deletedPhoto } = await deletePhotoMutation({
      variables: { photoId: photo._id }
    });
    return deletedPhoto;
  };
  return deletePhoto;
}
