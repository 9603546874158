/**
 * Squirrel
 * @flow
 */
import React, { type Node } from 'react';
import squirrel from '../../assets/images/supreme-squirrel-right-300.png';
import styles from './Squirrel.module.scss';

const Squirrel = (): Node => {
  return (
    <div className={styles.Squirrel}>
      <img src={squirrel} className={styles.phone} />
    </div>
  );
};

export default Squirrel;
