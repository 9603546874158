/**
 * EditPost
 * @flow
 */
import React, { Fragment, useEffect, useRef, useState, type Node } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '../button/Button';
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor } from 'slate';
import { SLATE_INITIAL_VALUE, slateHasValue, slateToMessage } from '../../lib/slate/Slate';
import { ImageRounded } from '@mui/icons-material';
import { Modal, CircularProgress } from '@mui/material';
import CarouselEdit from '../carousel-edit/CarouselEdit';
import ErrorComponent from '../error-component/ErrorComponent';
import ImagePicker from '../image-picker/ImagePicker';
import UserAvatar from '../user-avatar/UserAvatar';
import PremiumContentCheckbox from '../premium-content-checkbox/PremiumContentCheckbox';
import IosMediaAlert from '../ios-media-alert/IosMediaAlert';
import usePostMutations from '../../graphql/usePostMutations';
import usePhotoMutations from '../../graphql/usePhotoMutations';
import useVideoMutations from '../../graphql/useVideoMutations';
import userVerificationStatus from '../../hooks/userVerified';
import { maxDim } from '../../lib/photos';
import { getSignedUrlUpload } from '../../lib/upload/upload';
import type { MediaEdit, Post, Slate as SlateType, Uploads, User } from '../../types/Types';
import { IMAGE_TYPE, OPTIONS, IMAGES, VIDEOS } from '../../data/Data';
import styles from './EditPost.module.scss';

type Props = {
  isEditing: boolean,
  jest: boolean,
  post?: Post,
  parent: string | null,
  author: string | null,
  user: User
};

const RANDOM = (Math.random() * 1000).toFixed(0);

const EditPost = (props: Props): Node => {
  const { isEditing, jest, post, parent, user } = props;
  const { useAddPost /*, updatePost */ } = usePostMutations();
  const { useAddPhoto } = usePhotoMutations();
  const { useAddVideo } = useVideoMutations();
  const [postAdded, setPostAdded] = useState(null);
  const editor = withReact(createEditor());

  if (useAddPost.data?.addedPost && !postAdded && !isEditing) {
    setPostAdded(useAddPost.data.addedPost);
  }

  const [editedPost, setEditedPost] = useState(
    post
      ? {
          content: post.content,
          premium: false,
          photos: post.photos?.map((photo) => {
            return photo._id;
          }),
          userId: post.user._id,
          videos: post.videos?.map((video) => {
            return video._id;
          })
        }
      : {
          content: SLATE_INITIAL_VALUE,
          premium: false,
          photos: [],
          userId: user._id,
          parent: parent ? parent : '',
          videos: []
        }
  );
  const [photos, setPhotos] = useState<Array<MediaEdit>>([]);
  const [videos, setVideos] = useState<Array<MediaEdit>>([]);
  const [creatingPost, setCreatingPost] = useState(false);
  // useState doesn't work with nested components updating the state array values
  // So we are using a ref, instead.
  const posters = useRef({ current: {} });
  const mediaRef = useRef(null);
  const [creatingPostText, setCreatingPostText] = useState('');
  const [canPost, setCanPost] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const id = jest ? 'post-jest' : `post-${RANDOM}`;
  const status = userVerificationStatus(user);
  const [mediaOrder, setMediaOrder] = useState<Array<string>>([]);

  // Errors on creating Post
  if (useAddPost.errors) {
    setCreatingPostText('Error Creating post');

    setTimeout(() => {
      setCreatingPost(false);
    }, 2000);
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    setEditedPost({
      ...editedPost,
      userId: user._id
    });
  }, [user]);

  useEffect(() => {
    const { content } = editedPost;

    switch (true) {
      case isEditing:
        setCanPost(true);
        break;

      case slateHasValue(content) || videos.length > 0 || photos.length > 0:
        setCanPost(true);
        break;

      default:
        setCanPost(false);
    }
  }, [editedPost, photos, videos]);

  const updateValue = (value: SlateType) => {
    const updatedPost = {
      ...editedPost,
      content: value
    };

    switch (true) {
      case !slateHasValue(value):
        setCanPost(false);
        break;

      case user.emailConfirmed && slateHasValue(value):
        setCanPost(true);
        break;

      default:
        setCanPost(false);
    }

    setEditedPost(updatedPost);
  };

  /**
   * setPremium
   */
  const setPremium = (premium: boolean) => {
    setEditedPost({
      ...editedPost,
      premium
    });
  };

  const updateMedia = (media: Array<any>) => {
    let newPhotos = [...photos];
    let newVideos = [...videos];
    let newMediaOrder = [...mediaOrder];

    media.map((item) => {
      const { _id } = item;
      switch (item.type) {
        case IMAGES:
          newPhotos.push(item);
          newMediaOrder.push(_id);
          break;

        case VIDEOS:
          newVideos.push(item);
          newMediaOrder.push(_id);
          break;

        default:
        // no op
      }

      setPhotos(newPhotos);
      setVideos(newVideos);
      setMediaOrder(newMediaOrder);
    });
  };

  /**
   * set poster for loaded video
   */
  const setPoster = (index: number, src: string) => {
    posters.current[index] = { src };
  };

  /**
   * Submit the form and create the post
   */
  const submit = async (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!canPost || creatingPost) {
      return;
    }

    if (isEditing) {
      setCreatingPost(true);
      setCreatingPostText('Editing post');
    } else {
      setCreatingPost(true);
      setCreatingPostText('Preparing to upload');
    }

    switch (true) {
      case photos.length > 0 && !isEditing: {
        for (let i = 0; i < photos.length; i++) {
          let image = new Image();
          // $FlowFixMe
          image.src = photos[i].src;
          image.onload = () => {
            // resize the images to max 1280px on shortest side
            let { width, height } = maxDim(image);
            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            photos[i] = { ...photos[i], src: canvas.toDataURL(IMAGE_TYPE, 0.7) };
          };
        }
        setTimeout(async () => {
          let promises = [];

          for (const photo of photos) {
            promises.push(await getSignedUrlUpload(photo, user));
          }

          Promise.all(promises)
            .then(async (files) => {
              await uploadSingleMedia(0, files, 'image');
            })
            .catch((err) => console.warn(err));
        }, 1000);
        break;
      }

      case videos.length > 0 && !isEditing: {
        uploadPosters();
        break;
      }

      default: {
        const updatedContent = slateHasValue(editedPost.content)
          ? JSON.stringify(slateToMessage(editedPost.content))
          : '';

        const newPost = {
          ...editedPost,
          mediaOrder: mediaOrder.join(','),
          content: updatedContent
        };

        console.warn('submit newPost', newPost);
        useAddPost.addPost(newPost);
      }
    }
  };

  /**
   * uploadPosters
   * upload the video posters and set their URLs in the relevant video objects
   */
  const uploadPosters = async () => {
    // upload poster image
    const keys = Object.keys(posters.current);
    let media: Array<MediaEdit> = [];
    if (keys.length > 0) {
      for (let key of keys) {
        if (posters.current[key].src) {
          media.push(posters.current[key]);
        }
      }
    }

    // We have no poster files on ios due to autoplay being blocked
    if (media.length === 0) {
      let promises = [];

      for (const video of videos) {
        promises.push(await getSignedUrlUpload(video, user));
      }

      Promise.all(promises)
        .then(async (files) => {
          await uploadSingleMedia(0, files, 'video');
        })
        .catch((err) => console.warn(err));
      return;
    }

    // limit width / height of poster images
    for (let i = 0; i < media.length; i++) {
      let image = new Image();
      // $FlowFixMe
      image.src = media[i].src;
      image.onload = () => {
        // resize the images to max 1280px on shortest side
        let { width, height } = maxDim(image);
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);
        media[i] = {
          src: canvas.toDataURL(IMAGE_TYPE, 0.7),
          type: 'images',
          width,
          height
        };
      };
    }

    setTimeout(async () => {
      let promises = [];

      for (const poster of media) {
        promises.push(await getSignedUrlUpload(poster, user));
      }

      Promise.all(promises)
        .then(async (posters) => {
          console.warn('poster getSignedUrlUpload posters', posters);
          await uploadSingleMedia(0, posters, 'poster');
        })
        .catch((err) => console.warn(err));
    }, 1000);
  };

  /**
   * upload single image
   */
  const uploadSingleMedia = async (index: number, files: Uploads, type: 'image' | 'video' | 'poster') => {
    setCreatingPostText(`Uploading ${type} ${index + 1}`);
    const { filePath, id, media, url } = files[index];
    const { src, title, width, height } = media;
    // $FlowFixMe
    const file = await fetch(src);
    const blob = await file.blob();
    const contentType = type === 'video' ? 'video/mp4' : IMAGE_TYPE;
    const putOptions = {
      ...OPTIONS,
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
        Accept: 'application/json'
      },
      body: blob,
      isBase64Encoded: true
    };

    return await fetch(url, putOptions)
      .then(async (response) => {
        switch (true) {
          case response.status === 200 && type === 'poster': {
            const newPhoto = {
              id,
              filePath,
              title,
              type, // set type to exclude from photos lists on other pages
              userId: user._id,
              width,
              height
            };
            await useAddPhoto.addPhoto(newPhoto);
            let updateVideos = [...videos];
            updateVideos[index].poster = id.toString();
            setVideos(updateVideos);

            if (index < videos.length - 1) {
              uploadSingleMedia(index + 1, files, 'poster');
            } else {
              let promises = [];

              for (const video of videos) {
                promises.push(await getSignedUrlUpload(video, user));
              }

              Promise.all(promises)
                .then(async (videos) => {
                  console.warn('poster uploadSingleMedia videos', videos);
                  await uploadSingleMedia(0, videos, 'video');
                })
                .catch((err) => console.warn(err));

              const videoFiles = await getSignedUrlUpload(videos, 'videos', user);
              // $FlowFixMe
              uploadSingleMedia(0, videoFiles, 'video');
            }
            break;
          }

          case response.status === 200 && type === 'image': {
            // Set values for local caching
            const newPhoto = {
              id,
              filePath,
              title,
              type: 'photo',
              userId: user._id,
              width,
              height
            };
            await useAddPhoto.addPhoto(newPhoto);

            let newPost = { ...editedPost };
            newPost.photos.push(id.toString());
            setEditedPost(newPost);

            if (index < photos.length - 1) {
              uploadSingleMedia(index + 1, files, 'image');
            } else if (videos.length > 0) {
              uploadPosters();
            } else {
              const updatedContent = slateHasValue(newPost.content) ? slateToMessage(newPost.content) : '';

              useAddPost.addPost({
                ...newPost,
                mediaOrder: mediaOrder.join(','),
                content: JSON.stringify(updatedContent)
              });
            }
            break;
          }

          case response.status === 200 && type === 'video': {
            const newVideo = {
              id,
              filePath,
              poster: videos[index].poster ? videos[index].poster : null,
              title,
              userId: user._id
            };
            await useAddVideo.addVideo(newVideo);

            let newPost = { ...editedPost };
            newPost.videos.push(id.toString());
            setEditedPost(newPost);

            if (index < videos.length - 1) {
              uploadSingleMedia(index + 1, files, 'video');
            } else {
              const updatedContent = slateHasValue(editedPost.content) ? slateToMessage(editedPost.content) : '';

              useAddPost.addPost({
                ...editedPost,
                mediaOrder: mediaOrder.join(','),
                content: JSON.stringify(updatedContent)
              });
            }
            break;
          }

          default:
            setCreatingPostText(`Error uploading ${type} ${index + 1}`);
            setCreatingPost(false);
            setError(true);
            throw new Error(`Error posting ${type}`);
        }
      })
      .catch((err) => {
        console.warn(err);
        setCreatingPostText(`Error uploading ${type} ${index + 1}: ${err.message}`);
        setCreatingPost(false);
        setError(true);
        setErrorMessage(err.message);
      });
  };

  const EditableTextElement = (props) => {
    const { attributes, children, element } = props;

    switch (element.type) {
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  if (postAdded) {
    return <Redirect to={`/post/${postAdded._id}`} />;
  }

  return (
    <Fragment>
      <Modal className={styles.Modal} open={creatingPost}>
        <div className={styles.CreatingPost}>
          <CircularProgress />
          <span className={styles.title}>Creating Post...</span>
          <span className={styles.info}>{creatingPostText}</span>
        </div>
      </Modal>
      <div className={styles.EditPost}>
        <ErrorComponent error={error} message={`There has been an error creating your post: ${errorMessage}`} />
        <form>
          <div className={styles.InputContainer}>
            <UserAvatar size={64} user={user} />
            <Slate id={id} editor={editor} value={editedPost.content} onChange={updateValue}>
              <div className={styles.PostContentInput}>
                <Editable renderElement={EditableTextElement} placeholder="What's new?" />
              </div>
            </Slate>
            {photos.length + videos.length > 0 ? (
              <div ref={mediaRef} className={styles.Media}>
                <CarouselEdit
                  embedded={true}
                  mediaOrder={mediaOrder}
                  photos={photos}
                  videos={videos}
                  containerRef={mediaRef}
                  setPhotos={setPhotos}
                  setVideos={setVideos}
                  setPoster={setPoster}
                />
              </div>
            ) : null}
            <div className={styles.PostInputFooter}>
              <div className={styles.AddMedia}>
                <div className={photos.length < 10 && videos.length === 0 ? styles.AddImage : styles.CantAddImage}>
                  {photos.length + videos.length < 10 ? (
                    <ImagePicker
                      field={photos.length + videos.length}
                      icon="camera"
                      id={`postImage${photos.length + videos.length}`}
                      setMedia={updateMedia}
                    />
                  ) : (
                    <ImageRounded />
                  )}
                </div>
                <PremiumContentCheckbox user={user} post={editedPost} setPremium={setPremium} status={status} />
                <div className={styles.Buttons}>
                  <Button
                    action={submit}
                    className={!canPost || creatingPost ? 'NotAllowed' : 'Primary'}
                    href="/edit-post/submit"
                    type="button"
                  >
                    {isEditing ? 'Edit' : 'Post'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <IosMediaAlert />
      </div>
    </Fragment>
  );
};

EditPost.defaultProps = {
  author: null,
  isEditing: false,
  jest: false,
  parent: null
};

export default EditPost;
