/**
 * FollowButton
 * @flow
 */
import React, { type Node } from 'react';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { FOLLOW, UNFOLLOW, SNACKBAR_STYLE } from '../../data/Data';
import type { User, PopoverType } from '../../types/Types';
import styles from './FollowButton.module.scss';

type Props = {
  app: *,
  buttonText: string,
  followAction: () => *,
  closePopover: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  loading: boolean,
  popover: PopoverType,
  user: User, // the logged in user
  owner: User // the owner of this profile
};

const FollowButton = (props: Props): Node => {
  const { buttonText, closePopover, followAction, loading, popover } = props;

  /**
   * onClick of button
   */
  const onClick = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    followAction();
  };

  return (
    <>
      <Button
        className={buttonText === UNFOLLOW ? styles.UnFollowButton : styles.FollowButton}
        variant="contained"
        onClick={onClick}
      >
        {loading ? <CircularProgress size={12} /> : null}
        {buttonText}
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={popover.show}
        autoHideDuration={6000}
        sx={SNACKBAR_STYLE}
      >
        <Alert severity="info" onClick={closePopover}>
          {popover.text}
        </Alert>
      </Snackbar>
    </>
  );
};

FollowButton.defaultProps = {
  buttonText: FOLLOW,
  closePopover: (): null => null,
  follow: (): null => null,
  loading: false,
  popover: { show: false, text: '' }
};

export default FollowButton;
