/**
 * MoreButtons
 * @flow
 */
import React, { useState, type Node } from 'react';
import CloseIcon from '@mui/icons-material/CloseRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
// import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Modal } from '@mui/material';
import type { User } from '../../types/Types';
import styles from './MoreButtons.module.scss';

type Props = {
  alignment: 'horizontal' | 'vertical',
  isCurrentUser: boolean,
  deleteItem: () => *,
  showMore: boolean,
  user: User,
  toggleEdit: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *
};

const MoreButton = (props: Props): Node => {
  const { alignment, deleteItem, isCurrentUser, showMore } = props;
  const [more, setMore] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  /**
   * toggle the Edit and delete buttons
   */
  const toggleMore = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMore(!more);
  };

  /**
   * Toggle the confirm delete modal
   */
  const toggleConfirmDeleteModal = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setConfirmDeleteModal(!confirmDeleteModal);
  };

  /**
   * Really delete
   */
  const reallyDelete = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    deleteItem();
    setConfirmDeleteModal(!confirmDeleteModal);
  };
  const cancelDelete = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setConfirmDeleteModal(!confirmDeleteModal);
    setMore(false);
  };

  switch (true) {
    case confirmDeleteModal:
      return (
        <Modal open={confirmDeleteModal} className={styles.ConfirmDeleteModal}>
          <div className={styles.ConfirmDelete}>
            <p>Are you sure you want to delete this?</p>
            <div className={styles.Buttons}>
              <a href={'/delete'} className={styles.DeleteButton} onClick={reallyDelete}>
                <DeleteRoundedIcon />
                Delete
              </a>
              <a href={'/cancel-delete'} className={styles.CancelDeleteButton} onClick={cancelDelete}>
                <CloseIcon />
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      );

    case showMore && isCurrentUser && more:
      return (
        <div className={alignment === 'vertical' ? styles.MoreButtonsVertical : styles.MoreButtons}>
          <a href={'/delete-modal'} className={styles.DeleteButton} onClick={toggleConfirmDeleteModal}>
            <DeleteRoundedIcon />
            Delete
          </a>
          <a href={'/close-more'} className={styles.CloseButton} onClick={toggleMore}>
            <CancelRoundedIcon />
          </a>
        </div>
      );

    case showMore && isCurrentUser && alignment === 'vertical':
      return (
        <a className={styles.EditMoreVertical} onClick={toggleMore}>
          <MoreVertRoundedIcon />
        </a>
      );

    case showMore && isCurrentUser:
      return (
        <a className={styles.EditMore} onClick={toggleMore}>
          <MoreHorizRoundedIcon />
        </a>
      );

    default:
      return null;
  }
};

MoreButton.defaultProps = {
  alignment: 'horizontal',
  deleteItem: (): null => null,
  showMore: true,
  toggleEdit: (): null => null
};

export default MoreButton;
