/**
 * PremiumContentCheckbox
 * @flow
 */
import React, { useState, type Node } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Checkbox } from '@mui/material';
import { APPROVED, ADMIN, DECLINED, PENDING, PROVISIONAL, UNVERIFIED, UNKNOWN } from '../../data/Data';
import type { Post, VerificationStatus } from '../../types/Types';
import styles from './PremiumContentCheckbox.module.scss';

type Props = {
  post: Post,
  status: VerificationStatus,
  setPremium: (premium: boolean) => *
};

const PremiumContentCheckbox = (props: Props): Node => {
  const { post, setPremium, status } = props;
  const [info, setInfo] = useState<Node | null>(null);

  const onClick = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    switch (status) {
      case APPROVED:
      case PROVISIONAL:
        setPremium(!post.premium);
        setInfo(null);
        break;

      case UNVERIFIED:
      case UNKNOWN:
        setInfo(<Link to="/settings/get-verified">Get verified to post premium content</Link>);
        break;

      case PENDING:
        setInfo(<span>You'll be able to post premium content once your verification is approved</span>);
        break;

      case DECLINED:
        setInfo(<span>Your verification has been declined</span>);
        break;

      default:
      // no op
    }
  };

  return status === ADMIN ? null : (
    <>
      <div className={styles.PremiumContentCheckbox} onClick={onClick}>
        <Checkbox checked={post.premium} /> Premium
      </div>
      {info ? (
        <Alert className={styles.Alert} severity="info">
          {info}
        </Alert>
      ) : null}
    </>
  );
};

export default PremiumContentCheckbox;
