/**
 * User avatar
 * @flow
 */
import React, { type Node } from 'react';
import { Avatar } from '@mui/material';
import { getProfileImageUrl } from '../../data/Data';
import type { User } from '../../types/Types';

type Props = {
  size: number,
  user: User
};

const UserAvatar = (props: Props): Node | null => {
  const { size, user } = props;

  switch (true) {
    case !user:
      return null;

    case user && user.profileImage:
      return (
        <Avatar src={`${getProfileImageUrl(window)}/${user._id}/profileImage.jpg`} sx={{ width: size, height: size }} />
      );

    default:
      return (
        <Avatar sx={{ bgcolor: '#c3c3c3', width: size, height: size }}>
          {user.userName.substr(0, 1).toUpperCase()}
        </Avatar>
      );
  }
};

UserAvatar.defaultProps = {
  size: 64
};

export default UserAvatar;
