/**
 * Video
 * @flow
 */
import React from 'react';
import type { Node } from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayBar from '../play-bar/PlayBar';
import { timeToFramesDisplay } from '../../../lib/video';
import styles from './VideoControls.module.scss';

type Props = {
  controlRef: *,
  duration: number,
  isIos: boolean,
  isFullscreen: boolean,
  orientation: 'landscape' | 'portrait' | 'square' | '',
  muted: boolean,
  play: boolean,
  playsInline: boolean,
  setJumpTime: (time: number) => *,
  time: number,
  toggleFullscreen: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  toggleMute: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  togglePlay: (e: SyntheticEvent<*>) => *,
  togglePlaysInline: (e: SyntheticTouchEvent<>) => *,
  videoContainerMouseMove: boolean,
  vidWidth: null | number
};

const VideoControls = (props: Props): Node => {
  const {
    controlRef,
    duration,
    isFullscreen,
    isIos,
    orientation,
    muted,
    play,
    playsInline,
    time,
    setJumpTime,
    toggleFullscreen,
    toggleMute,
    togglePlay,
    togglePlaysInline,
    videoContainerMouseMove,
    vidWidth
  } = props;

  const classes = [
    isFullscreen && videoContainerMouseMove
      ? styles.VideoControlsFullscreen
      : isFullscreen && !videoContainerMouseMove
      ? styles.VideoControlsFullscreenHidden
      : styles.VideoControls,
    styles[orientation]
  ];

  return (
    <div
      ref={controlRef}
      className={classes.join(' ')}
      style={vidWidth && orientation === 'landscape' && !isFullscreen ? { maxWidth: vidWidth } : null}
    >
      <a href="/toggle-play" className="play" onClick={togglePlay}>
        {play ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
      </a>
      <div className="currentTime">{timeToFramesDisplay(time)}</div>
      <PlayBar duration={duration} play={play} setJumpTime={setJumpTime} time={time} togglePlay={togglePlay} />
      <div className="duration">{timeToFramesDisplay(duration)}</div>
      <a href="#toggle-mute" onClick={toggleMute}>
        {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </a>
      {isIos ? (
        <a href="/video-isFullscreen" onClick={togglePlaysInline}>
          {playsInline ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
        </a>
      ) : (
        <a href="/video-isFullscreen" onClick={toggleFullscreen}>
          {isFullscreen ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
        </a>
      )}
    </div>
  );
};

VideoControls.defaultProps = {
  controlRef: null,
  duration: 0,
  orientation: 'landscape',
  play: false,
  setJumpTime: (): null => null,
  toggleFullscreen: (): null => null,
  togglePlay: (): null => null,
  vidWidth: null
};

export default VideoControls;
