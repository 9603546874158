/**
 * Error component
 * @flow
 */
import React, { type Node } from 'react';
import { Alert } from '@mui/material';
import styles from './ErrorComponent.module.scss';

type Props = {
  error: boolean,
  message: string
};

const ErrorComponent = (props: Props): Node => {
  const { error, message } = props;

  if (!error) {
    return false;
  }

  return (
    <Alert severity="error" className={styles.Error}>
      {message}
    </Alert>
  );
};

export default ErrorComponent;
