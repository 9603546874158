/**
 * Nav
 * !important the logic is complex in this component for responsive layout
 * See the other Nav components in the /src/components/header folder
 * @flow
 */
import React, { Fragment, useEffect, useState, useRef, type Node } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../logo/Logo';
import ProfileNav from '../profile-nav/ProfileNav';
import AdminNav from '../../admin/admin-nav/AdminNav';
import ProfileNavButton from '../profile-nav-button/ProfileNavButton';
import NotificationsMenuItem from '../notifications-menu-item/NotificationsMenuItem';
import MessagesMenuItem from '../messages-menu-item/MessagesMenuItem';
import { AddCircle, HomeRounded, StarRounded, VerifiedUserRounded } from '@mui/icons-material';
import { useWindowSize } from '../../../hooks/windowResize';
import userVerificationStatus from '../../../hooks/userVerified';
import { APPROVED, PROVISIONAL, ADMIN, SHOW_MIN_WIDTH } from '../../../data/Data';
import type { User } from '../../../types/Types';
import styles from './Nav.module.scss';

type Props = {
  app: *,
  drawer: boolean,
  headerRef: *,
  user: User
};

const Nav = (props: Props): Node => {
  const { headerRef, user } = props;
  const navRef = useRef(null);
  const [style, setStyle] = useState({ padding: 0, margin: '.5rem 0' });
  const { width } = useWindowSize();
  const status = userVerificationStatus(user);

  /**
   * Set Sidebar as position fixed using the refs to <header> and <main>
   */
  useEffect(() => {
    window.addEventListener('scroll', scrollListener, true);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('scroll', scrollListener, true);
    };
  }, [style]);

  /**
   * scrollListener
   */
  const scrollListener = () => {
    if (!navRef || !navRef.current || width < SHOW_MIN_WIDTH || !headerRef || !headerRef.current) {
      setStyle(null);
      return;
    }

    const { y } = navRef.current.getBoundingClientRect();

    if (y < -10) {
      const header = headerRef.current.getBoundingClientRect();
      const left = width < SHOW_MIN_WIDTH ? 8 : header.right - 11 * 16;

      setStyle({
        position: 'fixed',
        top: 0,
        left
      });
    } else {
      setStyle(null);
    }
  };

  return (
    <Fragment>
      {/* $FlowFixMe */}
      <nav className={styles.Nav} ref={navRef}>
        <ul style={style}>
          {!width || width >= SHOW_MIN_WIDTH ? (
            <li>
              <Logo />
            </li>
          ) : null}
          <li>
            <NavLink to="/" exact={true}>
              <HomeRounded fontSize="medium" />
              <span className={styles.Text}>Home</span>
            </NavLink>
          </li>
          {user && !user.providerType ? ( // i.e. no Anonymous
            <Fragment>
              {status === APPROVED || status === PROVISIONAL || status === ADMIN || width < SHOW_MIN_WIDTH ? null : (
                <li>
                  <NavLink to="/settings/get-verified">
                    <VerifiedUserRounded />
                    <span className={styles.Text}>Get Verified</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NotificationsMenuItem {...props} />
              </li>
              <li>
                <NavLink to="/create-post" className={styles.CreatePost}>
                  <AddCircle fontSize="medium" />
                  <span className={styles.Text}>New post</span>
                </NavLink>
              </li>
              <li>
                <MessagesMenuItem {...props} />
              </li>
              {width >= SHOW_MIN_WIDTH ? (
                <Fragment>
                  <li>
                    <NavLink to="/saved">
                      <StarRounded fontSize="medium" />
                      <span className={styles.Text}>Saved</span>
                    </NavLink>
                  </li>
                  <AdminNav {...props} />
                  <ProfileNav {...props} />
                </Fragment>
              ) : (
                <ProfileNavButton {...props} />
              )}
            </Fragment>
          ) : null}
        </ul>
      </nav>
    </Fragment>
  );
};

Nav.defaultProps = {
  drawer: false
};

export default Nav;
