/**
 * Carousel
 * @flow
 */
import React, { useState, useEffect, type Node } from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import CarouselNumbers from '../carousel-numbers/CarouselNumbers';
import CarouselSlideContents from '../carousel-slide/CarouselSlide';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import type { MediaCache, Photo, Video } from '../../types/Types';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from './Carousel.module.scss';

type Props = {
  containerRef: *,
  embedded: boolean,
  mediaCache: MediaCache,
  mediaOrder: string,
  isModal: boolean,
  photos: Array<Photo>,
  videos: Array<Video>,
  toggleModal?: (e: SyntheticMouseEvent<HTMLDivElement>) => *
};

const Carousel = (props: Props): Node => {
  const { containerRef, embedded, mediaCache, mediaOrder, isModal, toggleModal, photos, videos } = props;
  const [carouselDim, setCarouselDim] = useState<{ width: number, height: number }>({
    width: 0,
    height: 0
  });
  const [media, setMedia] = useState<Array<Photo | Video>>([]);
  const total = photos.length + videos.length;

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }
    const { width, height } = containerRef.current.getBoundingClientRect();

    setCarouselDim({ width, height });
  }, [containerRef]);

  /**
   * Reorder the media using mediaOrder prop in message
   */
  useEffect(() => {
    const order = mediaOrder
      ? mediaOrder.split(',')
      : videos
          .map((video) => {
            return video._id;
          })
          .concat(
            photos.map((photo) => {
              return photo._id;
            })
          );
    let newMedia = [];

    for (const item of order) {
      photos.map((photo) => {
        if (photo._id === item) {
          newMedia.push(photo);
        }
      });

      videos.map((video) => {
        if (video._id === item) {
          newMedia.push(video);
        }
      });
    }

    setMedia(newMedia);
  }, [mediaOrder, photos, videos]);

  switch (true) {
    case media.length === 0:
      return null;

    case media.length === 1:
      return (
        <div ref={containerRef} className={styles.Slider}>
          <CarouselSlideContents
            embedded={embedded}
            carouselDim={carouselDim}
            item={media[0]}
            mediaCache={mediaCache}
            isModal={isModal}
            toggleModal={toggleModal}
          />
        </div>
      );

    default:
      return (
        <div ref={containerRef} className={styles.Carousel}>
          <CarouselProvider
            naturalSlideWidth={carouselDim.width}
            naturalSlideHeight={carouselDim.height}
            totalSlides={total}
            className={styles.Carousel}
            infinite={true}
          >
            <CarouselNumbers isModal={isModal} />
            <Slider className={styles.Slider}>
              {media.map((item, index) => {
                return (
                  <Slide key={index} className={styles.Slide}>
                    <CarouselSlideContents
                      embedded={embedded}
                      carouselDim={carouselDim}
                      item={item}
                      mediaCache={mediaCache}
                      isModal={isModal}
                      toggleModal={toggleModal}
                    />
                  </Slide>
                );
              })}
            </Slider>
            <ButtonBack className={styles.Back}>
              <div className={styles.Circle}>
                <ArrowBackIos />
              </div>
            </ButtonBack>
            <ButtonNext className={styles.Forward}>
              <div className={styles.Circle}>
                <ArrowForwardIos />
              </div>
            </ButtonNext>
          </CarouselProvider>
        </div>
      );
  }
};

Carousel.defaultProps = {
  containerRef: null,
  embedded: false,
  isModal: false,
  mediaCache: ([]: Array<empty>),
  mediaOrder: '',
  photos: ([]: Array<empty>),
  videos: ([]: Array<empty>),
  toggleModal: (): null => null
};

export default Carousel;
