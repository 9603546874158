import { ObjectId } from 'bson';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function useSaveMutations() {
  return {
    useAddSave: useAddSave(),
    updateSave: useUpdateSave(),
    deleteSave: useDeleteSave()
  };
}

const AddSaveMutation = gql`
  mutation CustomAddSave($save: CustomSaveInput!) {
    addedSave: CustomSaveInput(input: $save) {
      _id
      created
      post {
        _id
        content
        photos {
          _id
          filePath
          width
          height
        }
        videos {
          _id
          filePath
        }
      }
      photo {
        _id
        filePath
      }
      video {
        _id
        filePath
      }
      user {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

const UpdateSaveMutation = gql`
  mutation UpdateSave($saveId: ObjectId!, $updates: SaveUpdateInput!) {
    updatedSave: updateOneSave(query: { _id: $saveId }, set: $updates) {
      _id
      created
      post {
        _id
        content
        photos {
          _id
          filePath
          width
          height
        }
        videos {
          _id
          filePath
        }
      }
      photo {
        _id
        filePath
      }
      video {
        _id
        filePath
      }
      user {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

const DeleteSaveMutation = gql`
  mutation DeleteSave($saveId: ObjectId!) {
    deletedSave: deleteOneSave(query: { _id: $saveId }) {
      _id
    }
  }
`;

const SaveFieldsFragment = gql`
  fragment SaveFields on Save {
    _id
    created
    post {
      _id
      content
      photos {
        _id
        filePath
        width
        height
      }
      videos {
        _id
        filePath
      }
    }
    photo {
      _id
      filePath
    }
    video {
      _id
      filePath
    }
    user {
      _id
      userName
      profileImage
      firstName
      lastName
    }
  }
`;

function useAddSave() {
  const [addSaveMutation, { data, loading, error }] = useMutation(AddSaveMutation, {
    // Manually save added Saves into the Apollo cache so that Save queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedSave } }) => {
      console.warn('updateCache addedSave', addedSave);

      cache.modify({
        fields: {
          saves: (existingSaves = []) => [
            ...existingSaves,
            cache.writeFragment({
              data: addedSave,
              fragment: SaveFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addSave = async (save) => {
    console.warn('useSaveMutation addSave save', save);
    const id = new ObjectId();
    const { addedSave } = await addSaveMutation({
      variables: {
        save: {
          _id: id,
          ...save
        }
      }
    });
    return addedSave;
  };

  return { addSave, data, loading, error };
}

function useUpdateSave() {
  const [updateSaveMutation] = useMutation(UpdateSaveMutation);
  const updateSave = async (save, updates) => {
    const { updatedSave } = await updateSaveMutation({
      variables: { saveId: save._id, updates }
    });
    return updatedSave;
  };
  return updateSave;
}

function useDeleteSave() {
  const [deleteSaveMutation] = useMutation(DeleteSaveMutation);
  const deleteSave = async (save) => {
    const { deletedSave } = await deleteSaveMutation({
      variables: { saveId: save._id }
    });
    return deletedSave;
  };
  return deleteSave;
}
