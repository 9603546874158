/**
 * Replying To
 * @flow
 */
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import type { Post } from '../../types/Types';
import styles from './ReplyingTo.module.scss';

type Props = {
  post: Post
};

const ReplyingTo = (props: Props): Node => {
  const { post } = props;
  const { parent } = post;
  const { pathname } = window.location;
  const link = parent ? `/post/${parent._id}` : null;

  return parent && parent?._id && parent?.user?.userName ? (
    <div className={styles.ReplyingTo}>
      {link && link !== pathname ? (
        <Link to={`/post/${parent._id}`}>Replying to @{parent.user.userName}</Link>
      ) : (
        <>Replying to @{parent.user.userName}</>
      )}
    </div>
  ) : null;
};

export default ReplyingTo;
