/**
 * Home
 * @flow
 */
import React, { useRef, Fragment, type Node } from 'react';
import Helmet from 'react-helmet';
import Copyright from '../../components/copyright/Copyright';
import Logo from '../../components/logo/Logo';
import CreateAccountForm from '../../components/create-account-form/CreateAccountForm';
import Squirrel from '../../components/squirrel/Squirrel';
import FooterNav from '../../components/footer/footer-nav/FooterNav';
import type { User } from '../../types/Types';
import styles from './Home.module.scss';

type Props = {
  app: *,
  user: User,
  mainRef: *,
  headerRef: *
};

const Home = (props: Props): Node => {
  const formRef = useRef();
  const homeRef = useRef();

  return (
    <Fragment>
      <Helmet>
        <title>Nuttify</title>
        <meta name="description" content="Twitter alternative with premium content" />
      </Helmet>
      <div className={styles.Home} ref={homeRef}>
        <div className={styles.Inner}>
          <Logo showStrap={false} />
          <div className={styles.Form}>
            <div className={styles.Blurb}>
              <p>Nuttify is your newest Twitter alternative</p>
              <p>Join now and start posting.</p>
              <p>Verify your account to earn money from your premium content</p>
            </div>
            <CreateAccountForm {...props} formRef={formRef} />
          </div>
          <Squirrel />
        </div>
      </div>
      <footer className={styles.Footer}>
        <FooterNav showTitle={true} />
        <Copyright />
      </footer>
    </Fragment>
  );
};

export default Home;
