/**
 * Nav
 * @flow
 */
import React, { Fragment, useEffect, useState, type Node } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AccountBalance,
  ExitToAppRounded,
  LocalOfferRounded,
  ReceiptRounded,
  SubscriptionsRounded,
  AccountCircleRounded,
  PeopleAltRounded
} from '@mui/icons-material';
import { Button, Card, Modal } from '@mui/material';
import ProfileNavButton from '../profile-nav-button/ProfileNavButton';
import { userHasStripeAcc } from '../../../lib/stripe/stripeHelpers';
import StripeIcon from '../../icons/Stripe';
import { PROVIDER_TYPES } from '../../../data/Data';
import { useWindowSize } from '../../../hooks/windowResize';
import type { User } from '../../../types/Types';
import styles from './ProfileNav.module.scss';

type Props = {
  app: *,
  drawer: boolean,
  toggle: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  user: User
};

const ProfileNav = (props: Props): Node => {
  const { app, drawer, toggle, user } = props;
  const { width } = useWindowSize();

  if (!app || !user || user.providerType === PROVIDER_TYPES.apiKey) {
    return null;
  }

  const [confirmLogout, setConfirmLogout] = useState(false);
  const { pathname } = window.location;
  const parts = pathname.split('/');
  const pageUserName = parts[parts.length - 1];
  const useProfilePage = (user && pageUserName === user.userName) || parts[1] === 'settings' ? true : false;
  const [hasStripe, setHasStripe] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    const hasStripeAcc = userHasStripeAcc(user);
    setHasStripe(hasStripeAcc !== null ? true : false);
  }, [user]);

  /**
   * Confirm logout
   */
  const reallyLogout = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setConfirmLogout(!confirmLogout);
  };

  /**
   * Logout
   */
  const logOut = async (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await app.logOut();
    window.location = window.location.origin;
  };

  const userLinks = () => {
    let links = [];
    let i = 0;

    links.push(
      <li key={i++} onClick={toggle}>
        <NavLink to="/settings/followers">
          <AccountCircleRounded />
          <span className={styles.Text}>Followers</span>
        </NavLink>
      </li>
    );

    links.push(
      <li key={i++} onClick={toggle}>
        <NavLink to="/settings/following">
          <PeopleAltRounded />
          <span className={styles.Text}>Following</span>
        </NavLink>
      </li>
    );

    if (hasStripe) {
      links.push(
        <li key={i++} onClick={toggle}>
          <NavLink to="/settings/stripe">
            <StripeIcon />
            <span className={styles.Text}>Stripe</span>
          </NavLink>
        </li>
      );
      links.push(
        <li key={i++} onClick={toggle}>
          <NavLink to="/settings/offers">
            <LocalOfferRounded />
            <span className={styles.Text}>Offers</span>
          </NavLink>
        </li>
      );
      links.push(
        <li key={i++} onClick={toggle}>
          <NavLink to="/settings/bank-details">
            <AccountBalance />
            <span className={styles.Text}>Bank details</span>
          </NavLink>
        </li>
      );
    }

    if (links.length > 0) {
      return links;
    }

    return (
      <Fragment>
        <li onClick={toggle}>
          <NavLink to="/settings/subscriptions">
            <SubscriptionsRounded />
            <span className={styles.Text}>Subscriptions</span>
          </NavLink>
        </li>
        <li onClick={toggle}>
          <NavLink to="/settings/invoices">
            <ReceiptRounded />
            <span className={styles.Text}>Invoices</span>
          </NavLink>
        </li>
      </Fragment>
    );
  };

  const currentUserLinks = () => {
    if (width && width >= 1000 && !useProfilePage && !drawer) {
      return null;
    }

    return (
      <Fragment>
        {userLinks()}
        <li>
          <a href="/" onClick={reallyLogout}>
            <ExitToAppRounded fontSize={window.innerWidth < 768 ? 'large' : 'medium'} />
            <span className={styles.Text}>Logout</span>
          </a>
        </li>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <li className={styles.ProfileNav}>
        <ProfileNavButton {...props} />
        {width >= 1000 ? <ul>{currentUserLinks()}</ul> : null}
      </li>
      {!width || width < 1000 ? currentUserLinks() : null}
      <Modal open={confirmLogout} onBackdropClick={reallyLogout} className={styles.Modal}>
        <Card className={styles.ConfirmLogout}>
          <h3>Are you sure you want to logout?</h3>
          <div className={styles.Buttons}>
            <a href="#cancel-logout" onClick={reallyLogout}>
              <Button variant="outlined">Cancel</Button>
            </a>
            <a href="#confirm-logout" onClick={logOut}>
              <Button variant="contained">Logout</Button>
            </a>
          </div>
        </Card>
      </Modal>
    </Fragment>
  );
};

ProfileNav.defaultProps = {
  app: null,
  drawer: false,
  toggle: (): null => null,
  user: null
};

export default ProfileNav;
