/**
 * Copyright
 * @flow
 */
import React, { type Node } from 'react';
import dayjs from 'dayjs';
import { version } from '../../../package.json';
import styles from './Copyright.module.scss';

type Props = {
  style: *
};

const Copyright = (props: Props): Node => {
  const { style } = props;

  return (
    <div className={styles.Copyright} style={style}>
      <div>&copy; Nuttify Pty Ltd 2021-{dayjs().format('YYYY')}</div>
      <div>Version: {version}</div>
    </div>
  );
};

Copyright.defaultProps = {
  style: {
    textAlign: 'center'
  }
};

export default Copyright;
