/**
 * UserField
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import { LinkRounded } from '@mui/icons-material';
import type { User, UserFields } from '../../types/Types';
import styles from './UserField.module.scss';

type Props = {
  field: string,
  open: boolean,
  multiline: boolean,
  user: UserFields | User | null
};

const UserField = (props: Props): Node => {
  const { field, multiline, open, user } = props;

  // if we have no field, render null
  if (!user || !user[field]) {
    return null;
  }

  const renderField = () => {
    switch (field) {
      case 'url': {
        const url = new URL(user[field]);
        const { host, href, pathname } = url;
        return (
          <Fragment>
            <LinkRounded />
            <a href={encodeURI(href)} target="_blank" rel="noopener noreferrer">
              {`${host}${pathname}`}
            </a>
          </Fragment>
        );
      }

      case 'twitter':
        return null;

      case 'userName':
        return <div className={styles.userName}>@{user[field]}</div>;

      default:
        return user[field];
    }
  };

  return (
    <div
      className={`${styles[field]} ${
        open ? styles.UserFieldOpen : multiline ? styles.UserFieldMultiline : styles.UserField
      }`}
    >
      {renderField()}
    </div>
  );
};

UserField.defaultProps = {
  field: 'bio',
  open: false,
  multiline: false,
  user: {
    _id: '0',
    bio: ''
  }
};

export default UserField;
