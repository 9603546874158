/**
 * DateTime
 * @flow
 */
import React from 'react';
import type { Node } from 'react';
import styles from './DateTime.module.scss';
import { MONGO_DATE_FORMAT } from '../../data/Data';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

type Props = {
  className?: string,
  datetime: string
};

const DateTime = (props: Props): Node => {
  const { className, datetime } = props;

  if (!datetime) {
    return null;
  }
  let display = null;
  const dateTime = dayjs(datetime, MONGO_DATE_FORMAT);

  switch (true) {
    case dateTime.isBefore(dayjs().subtract(2, 'day')):
      display = dateTime.format('D MMM h:mm a');
      break;

    case dateTime.isBefore(dayjs().subtract(1, 'day')):
      display = dateTime.format('ddd h:mm a');
      break;

    default:
      display = dateTime.fromNow();
  }

  return <div className={`${styles.DateTime}${className ? ` ${className}` : ''}`}>{display}</div>;
};

export default DateTime;
