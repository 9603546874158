/**
 * Footer Nav
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import { Link } from 'react-router-dom';
import { Article, Email, InfoRounded, Security, SupportRounded } from '@mui/icons-material';
import styles from './FooterNav.module.scss';

const FOOTER_NAV = [
  {
    title: 'Support / Help',
    link: '/help',
    icon: <SupportRounded />,
    external: false
  },
  {
    title: 'About',
    link: '/about',
    icon: <InfoRounded />,
    external: false
  },
  {
    title: 'Email Support',
    link: 'mailto:support@nuttify.com',
    icon: <Email />,
    external: true
  },
  {
    title: 'Terms',
    link: '/terms-and-conditions',
    icon: <Article />,
    external: false
  },
  {
    title: 'Privacy',
    link: '/privacy',
    icon: <Security />,
    external: false
  }
];

type Props = {
  drawer: boolean,
  showTitle: boolean,
  toggle: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *
};

const FooterNav = (props: Props): null | Node => {
  const { drawer, showTitle, toggle } = props;

  const list = FOOTER_NAV.map((item, index) => {
    const { link, icon, title, external } = item;

    return external ? (
      <li key={index}>
        <a href={link} target="_blank" rel="noreferrer">
          {icon ? icon : null}
          {showTitle ? title : null}
        </a>
      </li>
    ) : (
      <li key={index} onClick={toggle}>
        <Link to={link} title={title}>
          {icon ? icon : null} {showTitle ? title : <span className={styles.hidden}>{title}</span>}
        </Link>
      </li>
    );
  });

  return !drawer ? (
    <div className={styles.FooterNavContainer}>
      <ul className={styles.FooterNav}>{list}</ul>
    </div>
  ) : (
    <Fragment>{list}</Fragment>
  );
};

FooterNav.defaultProps = {
  drawer: false,
  showTitle: false,
  toggle: (): null => null
};

export default FooterNav;
