/**
 * ProfileEdit
 * @flow
 */
import React from 'react';
import type { Node } from 'react';
import FormControl from '@mui/material/FormControl';
import UserFieldEdit from '../user-field-edit/UserFieldEdit';
import type { User, UserFields } from '../../types/Types';
import styles from './ProfileEdit.module.scss';

type Props = {
  editValues: (field: string, value: string) => *,
  user: User,
  values: UserFields
};

const ProfileEdit = (props: Props): Node => {
  const onSubmit = (e: SyntheticEvent<>) => {
    e.preventDefault();
  };

  return (
    <form className={styles.ProfileEditForm} onSubmit={onSubmit}>
      <FormControl>
        <UserFieldEdit {...props} field="firstName" label="Your first name" multiline={false} />
        <UserFieldEdit {...props} field="lastName" label="Your last name" multiline={false} />
        <UserFieldEdit {...props} field="bio" label="Describe yourself (300 chars max)" multiline={true} />
        <UserFieldEdit {...props} field="url" label="Your website, or other link" multiline={false} />
      </FormControl>
    </form>
  );
};

ProfileEdit.defaultProps = {
  currentUser: null,
  user: {
    _id: '0',
    bio: '',
    firstName: '',
    headerImage: '',
    lastName: '',
    profileImage: ''
  }
};

export default ProfileEdit;
