/**
 * RecommendedFollows
 * @flow
 */
import React, { type Node } from 'react';
import ProfileHeaderMinimal from '../profile-header-minimal/ProfileHeaderMinimal';
import FollowButtonWrapper from '../follow-button/FollowButtonWrapper';
import { Alert } from '@mui/material';
import Loading from '../loading/Loading';
import type { Stripe, User } from '../../types/Types';
import styles from './RecommendedFollows.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  loading: boolean,
  stripePromise: Promise<*>,
  user: User,
  users: Array<User>
};

const RecommendedFollows = (props: Props): Node => {
  const { users, loading } = props;
  return (
    <section className={styles.RecommendedFollows}>
      <h2>New users</h2>
      {loading ? (
        <Loading />
      ) : users && users.length > 0 ? (
        users.map((person) => {
          return (
            <div key={person._id} className={styles.Row}>
              <ProfileHeaderMinimal user={person} owner={person} />
              <div className={styles.FollowButtonContainer}>
                <FollowButtonWrapper {...props} owner={person} />
              </div>
            </div>
          );
        })
      ) : (
        <Alert severity="error">No users found</Alert>
      )}
    </section>
  );
};

RecommendedFollows.defaultProps = {
  loading: false,
  users: ([]: Array<empty>)
};

export default RecommendedFollows;
