/**
 * ImageEditor
 * @flow
 */
import React, { useEffect, useState, useRef } from 'react';
import type { Node } from 'react';
import { Button, Modal } from '@mui/material';
import CameraAltRounded from '@mui/icons-material/CameraAltRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useKeyPress } from '../../hooks/useKeyPress';
import { IMAGE_TYPE } from '../../data/Data';
import type { MediaSubtypes } from '../../types/Types';
import styles from './ProfileImageEditor.module.scss';

type Props = {
  aspectRatio?: number,
  cropBoxMovable: boolean,
  cropBoxResizable: boolean,
  field: MediaSubtypes,
  icon: 'camera' | 'picture',
  id: string,
  img: string,
  resizable: boolean,
  updateImage: (field: MediaSubtypes, uri: string) => *
};

const ImageEditor = (props: Props): Node => {
  const { aspectRatio, cropBoxMovable, cropBoxResizable, field, icon, id, img, resizable, updateImage } = props;
  const [editing, setEditing] = useState(false);
  const [image, setImage] = useState(img);
  const [error, setError] = useState(false);
  const cropperRef = useRef(null);
  const inputRef = useRef(null);
  const isEsc = useKeyPress('Escape');

  useEffect(() => {
    if (isEsc) {
      setEditing(false);
      setImage(null);
    }
  }, [isEsc]);
  /**
   * click input
   */
  const clickInput = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    e.stopPropagation();
    if (inputRef && inputRef.current) {
      setError(false);
      inputRef.current.click();
    }
  };

  /**
   * Image chosen
   *
   * @param {object} event - the synthetic event
   */
  const chooseImage = (e: SyntheticInputEvent<>) => {
    if (!e.target.files[0]) {
      return false;
    }

    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      let img = new Image();
      // $FlowFixMe
      img.src = reader.result;
      img.onload = () => {
        let { width, height } = img;
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const uri = canvas.toDataURL(IMAGE_TYPE, 0.7);
        setImage(uri);
        setEditing(true);
      };
    };

    reader.readAsDataURL(file);
  };

  /**
   * crop
   */
  const cropImage = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    if (cropperRef && cropperRef.current) {
      const imageElement: any = cropperRef.current;
      const cropper: any = imageElement.cropper;
      const img = cropper.getCroppedCanvas().toDataURL(IMAGE_TYPE);
      updateImage(field, img);
      setImage(img);
      setEditing(false);
    }
  };

  /**
   * cancelCrop
   */
  const cancelCrop = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    setEditing(false);
    setImage(null);
  };

  switch (true) {
    case editing:
      return (
        <Modal className={styles.Modal} open={editing}>
          <div className={styles.ModalInner}>
            <Cropper
              ref={cropperRef}
              src={image}
              className={styles.Cropper}
              cropBoxMovable={cropBoxMovable}
              cropBoxResizable={cropBoxResizable}
              zoomOnWheel={true}
              zoomOnTouch={true}
              movable={true}
              resizable={resizable}
              dragMode={'move'}
              viewMode={1}
              aspectRatio={aspectRatio ? aspectRatio : undefined}
              autoCropArea={1}
            />
            <div className={styles.Buttons}>
              <a href="#cancel-image-edit" onClick={cancelCrop}>
                <Button variant="outlined">Cancel</Button>
              </a>
              <a href="#crop-image" onClick={cropImage}>
                <Button variant="contained">Crop</Button>
              </a>
            </div>
          </div>
        </Modal>
      );

    default:
      return (
        <div className={styles.ProfileImageEditor}>
          <label htmlFor={id} className={styles.chooseImage} onClick={clickInput}>
            {icon === 'camera' ? <CameraAltRounded /> : <ImageRoundedIcon />}
          </label>
          <input
            // $FlowFixMe
            ref={inputRef}
            className="input"
            id={id}
            name={id}
            type="file"
            accept="image/*"
            onChange={chooseImage}
            value=""
          />
          {error ? <div className={styles.error}>Error editing image</div> : null}
        </div>
      );
  }
};

ImageEditor.defaultProps = {
  cropBoxMovable: false,
  cropBoxResizable: false,
  icon: 'camera',
  id: 'edit-image',
  img: '',
  resizable: false,
  setMedia: (): null => null
};

export default ImageEditor;
