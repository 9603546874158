/**
 * hook for user verification status
 * @flow
 */
import { useEffect, useState } from 'react';
import { verificationStatus } from '../lib/user/user';
import { UNVERIFIED } from '../data/Data';
import type { User, VerificationStatus as StatusType } from '../types/Types';

export default function userVerificationStatus(user: User): StatusType {
  const [userStatus, setUserStatus] = useState<StatusType>(UNVERIFIED);

  useEffect(() => {
    const status = verificationStatus(user);
    setUserStatus(status);
  }, [user]);

  return userStatus;
}
