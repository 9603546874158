/**
 * Header
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from './nav/Nav';
import { useWindowSize } from '../../hooks/windowResize';
import type { User } from '../../types/Types';
import styles from './Header.module.scss';

type Props = {
  app: *,
  user: User,
  headerRef: *,
  mainRef: *
};

const Header = (props: Props): Node => {
  const { user } = props;
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const [showMobileMenu, setMobileMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!pathname) {
      return;
    }

    if (!/^\/chat\/.*/.test(pathname)) {
      setMobileMenu(true);
    }
  }, [pathname]);

  switch (true) {
    case user && user.providerType && width < 768:
      return null;

    case (width <= 1000 && showMobileMenu) || width > 1000:
      return (
        <header className={styles.Header} ref={props.headerRef}>
          <Nav {...props} />
        </header>
      );

    default:
      return null;
  }
};

export default Header;
