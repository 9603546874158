/**
 * Get  messages
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { DEFAULT_LOAD_MSG_LIMIT } from '../data/Data';

const useMessages = (query, limit = DEFAULT_LOAD_MSG_LIMIT) => {
  const { messages, loading } = getMessages(query, limit);

  return {
    loading,
    messages
  };
};
export default useMessages;

function getMessages(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetMessages($query: MessageQueryInput!, $limit: Int) {
        messages(query: $query, sortBy: MESSAGEID_DESC, limit: $limit) {
          _id
          content
          created
          mediaOrder
          reactions {
            _id
            user {
              _id
              userName
            }
          }
          photos {
            _id
            filePath
            width
            height
          }
          status
          threadId
          user {
            _id
            userName
            profileImage
            firstName
            lastName
          }
          videos {
            _id
            filePath
            poster {
              _id
              filePath
            }
          }
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'network-only'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch messages: ${error.message}`);
  }

  // If the query has finished, return the messages from the result data
  // Otherwise, return an empty list
  const messages = data?.messages ?? [];
  return { messages, loading };
}
