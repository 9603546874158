/**
 * Routes
 * @flow
 */
import React, { Suspense, lazy, useRef, type Node } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from '../components/loading/Loading';
import FeedPage from '../pages/feed/Feed';
import ConfirmEmailNag from '../components/confirm-email-nag/ConfirmEmailNag';
import NotificationsSnackBar from '../components/snackbar/NotificationsSnackBar';
import MessagesSnackBar from '../components/snackbar/MessagesSnackBar';
import MobileNav from '../components/header/mobile-nav/MobileNav';
const AboutPage = lazy(() => import('../pages/about/About'));
const CreatePostPage = lazy(() => import('../pages/create-post/CreatePostPage'));
const CreatorsPage = lazy(() => import('../pages/creators/CreatorsPage'));
const CreateUserName = lazy(() => import('../pages/settings/create-user-name/CreateUserName'));
const FavouritePage = lazy(() => import('../pages/favourites/FavouritePage'));
const GetVerifiedPage = lazy(() => import('../pages/settings/get-verified/GetVerified'));
const HelpArticleListing = lazy(() => import('../pages/help/Help'));
const HelpArticleSingle = lazy(() => import('../pages/help-single/HelpArticleSingle'));
const PostPage = lazy(() => import('../pages/post/Post'));
const ProfilePage = lazy(() => import('../pages/profile/Profile'));
const VideoPage = lazy(() => import('../pages/video/Video'));
const PhotoPage = lazy(() => import('../pages/photo/Photo'));
const MessagesPage = lazy(() => import('../pages/messages/Messages'));
const MessageThread = lazy(() => import('../pages/message-thread/MessageThread'));
const NotificationsPage = lazy(() => import('../pages/notifications/Notifications'));
const VerifyEmailPage = lazy(() => import('../pages/settings/verify-email/VerifyEmailPage'));
const BankDetailsPage = lazy(() => import('../pages/settings/bank-details/BankDetailsPage'));
const VerificationPage = lazy(() => import('../pages/settings/verification/VerificationPage'));
const VerifyPhonePage = lazy(() => import('../pages/settings/verify-phone/VerifyPhonePage'));
const StripeSettings = lazy(() => import('../pages/settings/stripe-setup/StripeSetupPage'));
const InvoicesSettingsPage = lazy(() => import('../pages/settings/invoices/Invoices'));
const FollowersPage = lazy(() => import('../pages/settings/followers/Followers'));
const FollowingPage = lazy(() => import('../pages/settings/following/Following'));
const Subscriptions = lazy(() => import('../pages/settings/subscriptions/Subscriptions'));
const OffersPage = lazy(() => import('../pages/settings/offers/Offers'));
const TermsOfUse = lazy(() => import('../pages/terms-of-use/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
const Admin = lazy(() => import('../pages/admin/Admin'));
const Verifications = lazy(() => import('../pages/admin/verifications/Verifications'));
const VerificationAdminPage = lazy(() => import('../pages/admin/verification/Verification'));
const UsersPage = lazy(() => import('../pages/admin/users/UsersPage'));
const UserPage = lazy(() => import('../pages/admin/user/User'));
const SitemapPage = lazy(() => import('../pages/admin/sitemap/Sitemap'));
import useGetUserQuery from '../graphql/useSingleUserAdminQuery';
import userStripePlatformCustomer from '../hooks/userStripePlatformCustomer';
import { useWindowSize } from '../hooks/windowResize';
import type { Stripe, User } from '../types/Types';
import { useRealmApp } from '../RealmApp';

type Props = {
  stripePromise: *
};

const Routes = (props: Props): Node => {
  const { stripePromise } = props;
  const app = useRealmApp();
  const { user }: { user: User } = useGetUserQuery({ userId: app?.currentUser?.id });
  const customerPlatform: Stripe | null = userStripePlatformCustomer(app, user);
  const { width } = useWindowSize();
  const headerRef = useRef<*>(null);
  const mainRef = useRef<*>(null);

  if (!user) {
    return <Loading />;
  }

  const childProps = {
    app,
    customerPlatform,
    headerRef,
    mainRef,
    stripePromise,
    user
  };

  return (
    <>
      {width < 1000 ? <MobileNav {...childProps} /> : null}
      <ConfirmEmailNag {...childProps} />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/" exact={true}>
            <FeedPage {...childProps} />
          </Route>
          <Route path="/about" exact={true}>
            <AboutPage {...childProps} />
          </Route>
          <Route path="/creators" exact={true}>
            <CreatorsPage {...childProps} />
          </Route>
          <Route path="/help" exact={true}>
            <HelpArticleListing {...childProps} />
          </Route>
          <Route path="/help/:id">
            <HelpArticleSingle {...childProps} />
          </Route>
          <Route path="/saved" exact={true}>
            <FavouritePage {...childProps} />
          </Route>
          <Route path="/create-post" exact={true}>
            <CreatePostPage {...childProps} />
          </Route>
          <Route path="/notifications" exact={true}>
            <NotificationsPage {...childProps} />
          </Route>
          <Route path="/chat" exact={true}>
            <MessagesPage {...childProps} />
          </Route>
          <Route path="/chat/:id" exact={true}>
            <MessageThread {...childProps} />
          </Route>
          <Route path="/post/:id">
            <PostPage {...childProps} />
          </Route>
          <Route path="/photo/:id">
            <PhotoPage {...childProps} />
          </Route>
          <Route path="/video/:id">
            <VideoPage {...childProps} />
          </Route>
          <Route path="/settings/followers" exact={true}>
            <FollowersPage {...childProps} />
          </Route>
          <Route path="/settings/following" exact={true}>
            <FollowingPage {...childProps} />
          </Route>
          <Route path="/settings/bank-details" exact={true}>
            <BankDetailsPage {...childProps} />
          </Route>
          <Route path="/settings/create-username" exact={true}>
            <CreateUserName {...childProps} />
          </Route>
          <Route path="/settings/get-verified" exact={true}>
            <GetVerifiedPage {...childProps} />
          </Route>
          <Route path="/settings/offers" exact={true}>
            <OffersPage {...childProps} />
          </Route>
          <Route path="/settings/stripe" exact={true}>
            <StripeSettings {...childProps} />
          </Route>
          <Route path="/settings/subscriptions" exact={true}>
            <Subscriptions {...childProps} />
          </Route>
          <Route path="/settings/invoices" exact={true}>
            <InvoicesSettingsPage {...childProps} />
          </Route>
          <Route path="/settings/verify-email" exact={true}>
            <VerifyEmailPage {...childProps} />
          </Route>
          <Route path="/settings/verify-phone">
            <VerifyPhonePage {...childProps} />
          </Route>
          <Route path="/settings/verify-identity">
            <VerificationPage {...childProps} />
          </Route>
          <Route path="/admin" exact={true}>
            <Admin {...childProps} />
          </Route>
          <Route path="/admin/sitemap" exact={true}>
            <SitemapPage {...childProps} />
          </Route>
          <Route path="/admin/users" exact={true}>
            <UsersPage {...childProps} />
          </Route>
          <Route path="/admin/users/:id">
            <UserPage {...childProps} />
          </Route>
          <Route path="/admin/verifications" exact={true}>
            <Verifications {...childProps} />
          </Route>
          <Route path="/admin/verifications/:id">
            <VerificationAdminPage {...childProps} />
          </Route>
          <Route path="/terms">
            <TermsOfUse {...childProps} />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy {...childProps} />
          </Route>
          <Route path="/:userName" exact={true}>
            <ProfilePage {...childProps} stripePromise={stripePromise} />
          </Route>
        </Switch>
      </Suspense>
      {/* Notifications subscription */}
      <NotificationsSnackBar {...childProps} />
      {/* Messages subscription */}
      <MessagesSnackBar {...childProps} />
    </>
  );
};

export default Routes;
