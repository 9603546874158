import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function useStripeMutations() {
  return {
    useAddStripe: useAddStripe(),
    useUpdateStripe: useUpdateStripe(),
    useDeleteStripe: useDeleteStripe()
  };
}

// This uses a custom resolver to insert a new stripe.
// See app/functions/customStripeInputResolver.js
const AddStripeMutation = gql`
  mutation CustomAddStripe($stripe: CustomStripeInput!) {
    addedStripe: CustomStripeInput(input: $stripe) {
      _id
      accountId
    }
  }
`;

const UpdateStripeMutation = gql`
  mutation UpdateStripe($stripeId: ObjectId!, $updates: StripeUpdateInput!) {
    updatedStripe: updateOneStripe(query: { _id: $stripeId }, set: $updates) {
      _id
    }
  }
`;

const DeleteStripeMutation = gql`
  mutation DeleteStripe($stripeId: ObjectId!) {
    deletedStripe: deleteOneStripe(query: { _id: stripeId }) {
      _id
    }
  }
`;

const StripeFieldsFragment = gql`
  fragment StripeFields on Stripe {
    _id
    accountId
    ccHash
  }
`;

function useAddStripe() {
  const [addStripeMutation, { data, loading, error }] = useMutation(AddStripeMutation, {
    // Manually save added Stripes into the Apollo cache so that Stripe queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedStripe } }) => {
      cache.modify({
        fields: {
          stripes: (existingStripes = []) => [
            ...existingStripes,
            cache.writeFragment({
              data: addedStripe,
              fragment: StripeFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addStripe = async (stripe) => {
    const { id, ...params } = stripe;
    const { addedStripe } = await addStripeMutation({
      variables: {
        stripe: {
          _id: id,
          ...params
        },
        fetchPolicy: 'cache-and-network'
      }
    });
    return addedStripe;
  };

  return { addStripe, data, loading, error };
}

function useUpdateStripe() {
  const [updateStripeMutation] = useMutation(UpdateStripeMutation);
  const updateStripe = async (stripeId, updates) => {
    const { updatedStripe } = await updateStripeMutation({
      variables: {
        stripeId,
        updates
      },
      fetchPolicy: 'network-only'
    });
    return updatedStripe;
  };
  return updateStripe;
}

function useDeleteStripe() {
  const [deleteStripeMutation] = useMutation(DeleteStripeMutation);
  const deleteStripe = async (stripe) => {
    const { deletedStripe } = await deleteStripeMutation({
      variables: { stripeId: stripe._id }
    });
    return deletedStripe;
  };
  return deleteStripe;
}
