/**
 * SearchResult
 * @flow
 */
import React, { type Node } from 'react';
import { CircularProgress } from '@mui/material';
import { SentimentVeryDissatisfiedRounded } from '@mui/icons-material';
import UserAvatar from '../../user-avatar/UserAvatar';
import type { User } from '../../../types/Types';
import styles from './SearchResult.module.scss';

type Props = {
  loading: boolean,
  result: User | null
};

const SearchResult = (props: Props): Node => {
  const { loading, result } = props;

  if (loading) {
    return (
      <div className={styles.NoResult}>
        <CircularProgress size={23} /> Loading
      </div>
    );
  }

  if (!result) {
    return (
      <div className={styles.NoResult}>
        <SentimentVeryDissatisfiedRounded size={20} />
        No Results
      </div>
    );
  }

  const { firstName, lastName, userName } = result;

  return (
    <a href={`/${userName}`} className={styles.SearchResult}>
      <UserAvatar user={result} size={40} />
      <div className={styles.Name}>
        {firstName} {lastName}
      </div>
      <div className={styles.Username}>@{userName}</div>
    </a>
  );
};

export default SearchResult;
