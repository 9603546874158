/**
 * SetupIntent
 * @flow
 */
import React, { useState, useEffect, type Node } from 'react';
import type { Stripe, User } from '../../types/Types';
import useStripeMutations from '../../graphql/useStripeMutations';
import { Elements } from '@stripe/react-stripe-js';

type Props = {
  accountId: null | string,
  app: *,
  customer: null | Stripe,
  children: Node,
  owner: User | null, // the owner of the content
  stripePromise: Promise<*>
};

const SetupIntent = (props: Props): Node => {
  const { accountId, app, children, customer, stripePromise } = props;
  const [options, setOptions] = useState<null | { clientSecret: string }>(null);
  const { useUpdateStripe } = useStripeMutations();

  /**
   * Setup payment intent
   */
  useEffect(() => {
    if (options || !accountId || !customer || !customer.customerId) {
      return;
    }

    const intent = customer.setupIntents ? customer.setupIntents[0] : false;

    if (intent !== false) {
      getSetupIntent(intent);
    } else {
      createSetupIntent(customer.customerId);
    }
  }, [accountId, customer, options]);

  /**
   * get a setup intent
   */
  const getSetupIntent = (intent: string) => {
    const args = {
      endpoint: `setup_intents/${intent}`,
      method: 'get'
      // accountId
    };
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('Stripe GET setup_intents', result);
        setOptions({ clientSecret: result.client_secret });
      })
      .catch((err) => console.error('Stripe GET setup_intents', err));
  };

  /**
   * Create payment intent
   */
  const createSetupIntent = (customerId: string) => {
    const args = {
      endpoint: 'setup_intents',
      method: 'post',
      data: {
        customer: customerId
      }
    };
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('Stripe CREATE setup_intents', result);
        setOptions({
          clientSecret: result.client_secret
        });
        saveIntent(result.id);
      })
      .catch((err) => console.error('Routes stripe err', err));
  };

  /**
   * Save a setup intent via graphql
   */
  const saveIntent = (id: string) => {
    if (!customer) return;
    const setupIntents = customer.setupIntents ? customer.setupIntents : [];
    setupIntents.unshift(id);
    useUpdateStripe(customer.stripeId, { setupIntents });
  };

  if (!options || !options.clientSecret) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default SetupIntent;
