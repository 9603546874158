/**
 * SadFace
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import Squirrel from '../../assets/images/supreme-squirrel-right-300.png';
import { Alert, CircularProgress } from '@mui/material';
import styles from './SadFace.module.scss';

type Props = {
  text: string,
  timer: boolean
};

const SadFace = (props: Props): Node => {
  const { text, timer } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!timer) {
      setShow(true);
      return;
    }

    const timeToShow = setTimeout(() => {
      setShow(true);
    }, 4000);

    return () => {
      clearTimeout(timeToShow);
    };
  }, [timer]);

  return show ? (
    <article className={styles.SadFace}>
      <img src={Squirrel} width={200} />
      <Alert severity="info">{text}</Alert>
    </article>
  ) : (
    <article className={styles.Loading}>
      <CircularProgress />
    </article>
  );
};

SadFace.defaultProps = {
  text: 'Sorry',
  timer: true
};

export default SadFace;
