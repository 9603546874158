/**
 * UserNameCreate
 * @flow
 */
import React, { useState, type Node } from 'react';
import { CircularProgress, FormControl, TextField } from '@mui/material';
import { CheckRounded, CloseRounded } from '@mui/icons-material';
import styles from './UserNameCreateField.module.scss';

type Props = {
  app: *,
  updateUsername: (username: string, valid: boolean) => *,
  username: string
};

const TAKEN = 'That user name is not available';
const NOT_VALID = 'That username is not valid';

const UserNameCreate = (props: Props): Node => {
  const { app, updateUsername, username } = props;
  const usernameIsValid = (username: string) => {
    return username.length === 0 ? false : /^(\w){1,15}$/.test(username);
  };
  const [valid, setValid] = useState<boolean>(usernameIsValid(username));
  const [spinner, setSpinner] = useState<boolean>(false);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean | null>(null);

  /**
   * onChange of field
   */
  const onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = usernameIsValid(value);

    switch (true) {
      case isValid:
        searchUsername(value);
        updateUsername(value, isValid);
        break;

      default:
        updateUsername(value, isValid);
    }
  };

  /**
   * Search usernames
   */
  const searchUsername = async (value: string) => {
    setSpinner(true);
    const available = await app.currentUser.callFunction('checkUsernameAvailable', value);
    console.warn('available', available);
    setUsernameAvailable(available);
    setSpinner(false);
    setValid(available);
  };

  return (
    <div className={styles.CreateUsernameField}>
      <FormControl>
        <TextField
          error={usernameAvailable === false ? true : valid === null ? false : !valid}
          label="Choose Username"
          id="choose-username"
          helperText={!usernameAvailable ? TAKEN : !valid ? NOT_VALID : ''}
          onChange={onChange}
          value={username}
          color="primary"
          fullWidth={true}
        />
      </FormControl>
      <div className={styles.Feedback}>
        {spinner ? (
          <CircularProgress size={24} />
        ) : valid ? (
          <CheckRounded sx={{ color: 'green' }} />
        ) : (
          <CloseRounded sx={{ color: 'red' }} />
        )}
      </div>
    </div>
  );
};

UserNameCreate.defaultProps = {
  username: '',
  updateUsername: (): null => null
};

export default UserNameCreate;
