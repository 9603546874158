/**
 * Thread mutations
 */
import { ObjectId } from 'bson';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
// import useThreadMutations from './useThreadMutations';

const useThreadMutations = () => {
  return {
    useAddThread: useAddThread(),
    updateThread: useUpdateThread()
  };
};

export default useThreadMutations;

// This uses a custom resolver to insert a new thread.
// See app/functions/customThreadInputResolver.js
const AddThreadMutation = gql`
  mutation CustomAddThread($thread: CustomThreadInput!) {
    addedThread: CustomThreadInput(input: $thread) {
      _id
      latest
      messages {
        _id
        content
        status
      }
      users {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

const UpdateThreadMutation = gql`
  mutation UpdateThread($threadId: ObjectId!, $updates: ThreadUpdateInput!) {
    updatedThread: updateOneThread(query: { _id: $threadId }, set: $updates) {
      _id
      latest
      messages {
        _id
        content
        status
      }
      users {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

export const ThreadFieldsFragment = gql`
  fragment ThreadFields on Thread {
    _id
    latest
    messages {
      _id
      content
      status
    }
    users {
      _id
      userName
      profileImage
      firstName
      lastName
    }
  }
`;

function useAddThread() {
  const [addThreadMutation, { data, loading, error }] = useMutation(AddThreadMutation, {
    // Manually save added Threads into the Apollo cache so that Thread queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedThread } }) => {
      cache.modify({
        fields: {
          threads: (existingThreads = []) => [
            ...existingThreads,
            cache.writeFragment({
              data: addedThread,
              fragment: ThreadFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addThread = async (thread) => {
    const id = new ObjectId();
    const { addedThread } = await addThreadMutation({
      variables: {
        thread: {
          _id: id,
          ...thread
        }
      }
    });
    return addedThread;
  };

  return { addThread, data, loading, error };
}

const useUpdateThread = () => {
  const [updateThreadMutation] = useMutation(UpdateThreadMutation);
  const updateThread = async (thread, updates) => {
    const { updatedThread } = await updateThreadMutation({
      variables: {
        threadId: new ObjectId(thread._id),
        updates
      }
    });
    return updatedThread;
  };
  return updateThread;
};
