/**
 * Profile Page
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/header/Header';
import ProfilePageMain from '../../components/profile-page-main/ProfilePageMain';
import Sidebar from '../../components/sidebar/Sidebar';
import type { Stripe, User } from '../../types/Types';
import styles from './Profile.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  user: User,
  mainRef: *,
  headerRef: *
};

const ProfilePage = (props: Props): Node => {
  const parts = window.location.pathname.split('/');
  const userName = parts[parts.length - 1];

  return (
    <Fragment>
      <Helmet>
        <title>{userName} | Nuttify</title>
        <meta name="description" content={`View ${userName}'s profile`} />
      </Helmet>
      <div className={styles.ProfilePage}>
        <Header {...props} />
        <main ref={props.mainRef}>
          <ProfilePageMain {...props} />
        </main>
        <Sidebar {...props} />
      </div>
    </Fragment>
  );
};

export default ProfilePage;
