/**
 * Like
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { ArrowUpwardRounded, ArrowDownwardRounded } from '@mui/icons-material';
import type { Like as LikeType, User, Vote } from '../../types/Types';
import styles from './Like.module.scss';

type Props = {
  action: (e: SyntheticMouseEvent<HTMLAnchorElement>, vote: Vote) => *,
  alignment: 'horizontal' | 'vertical',
  like: LikeType | null,
  num: number,
  user: User
};

const Like = (props: Props): Node => {
  const { action, like, num } = props;
  const [params, setParams] = useState<{
    up: { style: string, value: Vote },
    down: { style: string, value: Vote }
  }>({
    up: { style: styles.Vote, value: 1 },
    down: { style: styles.Vote, value: -1 }
  });
  const display = num >= 1000000 ? `${(num / 1000000).toFixed(1)}M` : num >= 1000 ? `${(num / 1000).toFixed(1)}K` : num;

  useEffect(() => {
    if (!like) {
      setParams({
        up: { style: styles.Vote, value: 1 },
        down: { style: styles.Vote, value: -1 }
      });
      return;
    }

    switch (like.vote) {
      case -1:
        setParams({
          up: { style: styles.Vote, value: 1 },
          down: { style: styles.Selected, value: 0 }
        });
        break;

      case 0:
        setParams({
          up: { style: styles.Vote, value: 1 },
          down: { style: styles.Vote, value: -1 }
        });
        break;

      case 1:
        setParams({
          up: { style: styles.Selected, value: 0 },
          down: { style: styles.Vote, value: -1 }
        });
        break;
    }
  }, [like]);

  return (
    <div className={styles.Like}>
      <a href="#post-up-vote" className={params.up.style} onClick={(e) => action(e, params.up.value)}>
        <ArrowUpwardRounded />
      </a>
      <div className={styles.Display}>{display}</div>
      <a href="#post-down-vote" className={params.down.style} onClick={(e) => action(e, params.down.value)}>
        <ArrowDownwardRounded />
      </a>
    </div>
  );
};

Like.defaultProps = {
  action: (): null => null,
  alignment: 'horizontal',
  like: null,
  num: 0
};

export default Like;
