/**
 * Video helper functions
 * @flow
 **/

/**
 * pad a number with a leading 0
 **/
const pad = (input: number) => {
  return String(input).padStart(2, '0');
};

/**
 * timeToFramesDisplay
 * Assumes 30 fps
 *
 * @param {number} time - the video time in seconds
 * @returns {string} video time in hours:minutes:frames
 */

export const timeToFramesDisplay = (time: number, showFrames: boolean = false): string => {
  const hours = time / 3600;
  let minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time % 60);
  const frames = Math.floor((time % 1) * 30);
  return `${Math.floor(hours) ? `${Math.floor(hours)}:` : ''}${pad(minutes)}:${pad(seconds)}${
    showFrames ? `:${pad(frames)}` : ''
  }`;
};
