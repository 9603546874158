/**
 * ThemeOptions
 */
import { createTheme } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
// import { buttonInputClasses } from '@mui/material/Button';

const FORM_BG_COLOR = '#222';

export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#9A1F33'
    },
    secondary: {
      light: '#3b3835',
      main: '#b3e5fc',
      dark: '#3b3835'
    },
    background: {
      default: '#141414'
    },
    error: {
      main: '#ff1744'
    },
    text: {
      primary: '#f1f1f1',
      secondary: '#e2e2e2',
      disabled: '#bfbfbf',
      hint: '#585858'
    },
    typography: {
      htmlFontSize: '1rem',
      fontSize: '1rem',
      fontFamily: 'Lato, Helvetica, Arial, sans-serif'
    }
  },
  components: {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          background: FORM_BG_COLOR
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          disabled: {
            color: '#fff'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses['notchedOutline']}`]: {
            border: '1px solid #bfbfbf'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: FORM_BG_COLOR
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          background: FORM_BG_COLOR
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        '& + .MuiSwitch-track': {
          opacity: 0.7,
          backgroundColor: '#fff'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#333'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '1rem .5rem'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          background: FORM_BG_COLOR,
          color: 'rgba(255, 255, 255, .75)',
          '&.Mui-selected': {
            color: '#9A1F33'
          }
        }
      }
    }
  }
});
