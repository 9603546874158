/**
 * CreateAccountRoutes
 * @flow
 */
import React, { Suspense, lazy, useRef, type Node } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import MobileNav from '../components/header/mobile-nav/MobileNav';
import Loading from '../components/loading/Loading';
// import RealmApolloProvider from '../graphql/RealmApolloProvider';
const AboutPage = lazy(() => import('../pages/about/About'));
// const BlogPostListing = lazy(() => import('../pages/blog/Blog'));
// const BlogPostSingle = lazy(() => import('../pages/blog-single/BlogPostSingle'));
// const CreatorsPage = lazy(() => import('../pages/creators/CreatorsPage'));
const HelpArticleListing = lazy(() => import('../pages/help/Help'));
const HelpArticleSingle = lazy(() => import('../pages/help-single/HelpArticleSingle'));
const VerifyEmailPage = lazy(() => import('../pages/settings/verify-email/VerifyEmailPage'));
const PostPage = lazy(() => import('../pages/post/Post'));
import ProfilePage from '../pages/profile/Profile';
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
const RequestPasswordResetPage = lazy(() => import('../pages/request-password-reset/RequestPasswordResetPage'));
const RequestPasswordPage = lazy(() => import('../pages/password-reset/PasswordResetPage'));
const VerificationPage = lazy(() => import('../pages/settings/verification/VerificationPage'));
const TermsOfUse = lazy(() => import('../pages/terms-of-use/TermsOfUse'));
import { useWindowSize } from '../hooks/windowResize';
import type { User } from '../types/Types';

type Props = {
  app: *,
  stripePromise: *,
  user: User
};

const Routes = (props: Props): Node => {
  const { app, stripePromise, user } = props;
  const headerRef = useRef<*>(null);
  const mainRef = useRef<*>(null);
  const { width } = useWindowSize();

  const childProps = {
    app,
    headerRef,
    mainRef,
    user,
    stripePromise,
    customerPlatform: null
  };

  return (
    <Suspense fallback={<Loading />}>
      {width < 1000 ? <MobileNav {...childProps} /> : null}
      <Switch>
        <Route path="/" exact={true}>
          <Home {...childProps} />
        </Route>
        <Route path="/about" exact={true}>
          <AboutPage {...childProps} />
        </Route>
        {/* <Route path="/blog" exact={true}>
            <BlogPostListing {...childProps} />
          </Route>
          <Route path="/blog/:id">
            <BlogPostSingle {...childProps} />
          </Route> */}
        {/* <Route path="/creators" exact={true}>
            <CreatorsPage {...childProps} />
          </Route> */}
        <Route path="/help" exact={true}>
          <HelpArticleListing {...childProps} />
        </Route>
        <Route path="/help/:id">
          <HelpArticleSingle {...childProps} />
        </Route>
        <Route path="/settings/verify-email" exact={true}>
          <VerifyEmailPage {...childProps} />
        </Route>
        <Route path="/settings/verify-identity" exact={true}>
          <VerificationPage {...childProps} />
        </Route>
        <Route path="/reset-password" exact={true}>
          <RequestPasswordPage {...childProps} />
        </Route>
        <Route path="/request-password-reset" exact={true}>
          <RequestPasswordResetPage {...childProps} />
        </Route>
        <Route path="/chat" exact={true}>
          <Home {...childProps} />
        </Route>
        <Route path="/chat/:id">
          <Home {...childProps} />
        </Route>
        <Route path="/post/:id">
          <PostPage {...childProps} />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsOfUse {...childProps} />
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy {...childProps} />
        </Route>
        <Route path="/:userName" exact={true}>
          <ProfilePage {...childProps} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
