/**
 * Search Modal
 * @flow
 */
import React, { type Node } from 'react';
import Search from '../search/Search';
import { Modal } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import type { User } from '../../types/Types';
import styles from './SearchModal.module.scss';

type Props = {
  app: *,
  showSearch: boolean,
  toggleSearch: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  user: User
};

const SearchModal = (props: Props): Node => {
  const { showSearch, toggleSearch, ...otherProps } = props;

  return (
    <Modal className={styles.SearchModal} open={showSearch} onBackdropClick={toggleSearch}>
      <div className={styles.SearchModalInner}>
        <a href="#close-search" className={styles.Close} onClick={toggleSearch}>
          <CloseRounded />
        </a>
        <Search {...otherProps} autoFocus={true} />
      </div>
    </Modal>
  );
};

SearchModal.defaultProps = {
  app: null,
  showSearch: false,
  toggleSearch: (): null => null,
  user: null
};

export default SearchModal;
