/**
 * SearchResults
 * @flow
 */
import React, { type Node } from 'react';
import SearchResult from '../search-result/SearchResult';
import type { User } from '../../../types/Types';
import styles from './SearchResults.module.scss';

type Props = {
  loading: boolean,
  results: Array<User>,
  value: string
};

const SearchResults = (props: Props): Node => {
  const { loading, results, value } = props;

  if (results.length === 0 && !value) {
    return null;
  }

  return (
    <div id="search-results" className={styles.SearchResults}>
      {loading ? (
        <SearchResult loading={loading} result={null} value={value} />
      ) : results && results.length > 0 ? (
        results.map((result, index) => {
          return <SearchResult key={index} loading={loading} result={result} />;
        })
      ) : (
        <SearchResult loading={loading} result={null} value={value} />
      )}
    </div>
  );
};

SearchResults.defaultProps = {
  loading: false,
  results: ([]: Array<empty>),
  value: ''
};

export default SearchResults;
