/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import GetVerifiedImage from '../../assets/images/get-verified-nuttify.jpg';
import IphoneCameraSettings from '../../assets/images/iphone-set-most-compatible.jpg'
// import ProfileImage from '../../assets/images/gay-only-fans-alternative-profile-nuttify.jpg'

export const HELP_ARTICLES = [
  {
    title: 'How to get verified on Nuttify',
    slug: 'how-to-get-verified-nuttify',
    image: GetVerifiedImage,
    contents: [
      {
        type: 'p',
        content: 'To upload premium content to nuttify to earn money, you need to verify your account. These are the steps to verifying your account:'
      },
      {
        type: 'ol',
        content: [
          'Sign up on the <a href="/">nuttify homepage</a> and signup with your email address and password',
          'You will be sent an email with a link that confirms your email address.',
          'Check your email and click the link in the verification email.',
          'The next step is to <a href="/settings/get-phone" target="_blank">verify your mobile number</a>',
          'Then <a href="/settings/get-identity" target="_blank">verify your identity</a>. Your mobile number, identity documents and information is private and only used to verify your identity. We handle all personal information inline with our <a href="/privacy" target="_blank">privacy policy</a>',
          'Once you have submitted the verification form, you can setup your Stripe account, and start uploading content.'
        ]
      },
      {
        type: 'p',
        content: 'The <a href="/settings/get-verified" target="_blank">get verified page</a> walks you through each of these steps.'
      }
    ]
  },
  {
    title: 'iPhone video settings - set to "Most Compatible"',
    slug: 'iphone-video-settings',
    image: IphoneCameraSettings,
    contents: [
      {
        type: 'p',
        content: 'iPhones default to using "High Efficiency" format for images and videos. While it may seem that high efficiency would be better, it isn\'t. Why is this?'
      },
      {
        type: 'p',
        content: "The \"High Efficiency\" setting saves space on your phone's storage, but uses formats that aren't compatible with most web browsers. What this means is that any one with an iPhone will be able to see your video, but people on Android, or on Chrome on their laptops won't."
      },
      {
        type: 'p',
        content: "But don't worry - there's an easy fix. Just change your Camera settings to use \"Most Compatible\":"
      },

      {
        type: 'ol',
        content: [
          "Go to your phone's Settings > Camera > Formats",
          "Change to \"Most Compatible\""
        ]
      },
      {
        type: 'p',
        content: "You're all done! Now you can record and upload video from your iPhone, and everyone (who has subscribed to you) will be able to view it."
      }
    ]
  }
];
