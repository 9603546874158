/**
 * userStripeCustomer hook
 * Gets the user's stripe customer account for the owner accountId
 * @flow
 */
import { useEffect, useState } from 'react';
import useStripeMutations from '../graphql/useStripeMutations';
import { BSON } from 'realm-web';
import type { User, Stripe } from '../types/Types';

export default function userStripeConnectCustomer(
  app: *,
  user: User,
  accountId: null | string,
  create: boolean = true
): Stripe | null {
  const [customer, setCustomer] = useState<Stripe | null>(null);
  const { useAddStripe } = useStripeMutations();
  const { addStripe } = useAddStripe;
  const { pathname } = window.location;

  /**
   * I suspect that users are getting 2 stripe customer accounts created when verifying email,
   * So we won't create a customer account for them if they reach this page.
   */

  useEffect(() => {
    if (!app || !user || !accountId || pathname === '/settings/verify-email') {
      return;
    }

    // If user has a stripe attribute - find the relevant customer ID for the owner account.
    if (user && user.stripe) {
      const customer = user.stripe.find((id) => {
        return id.accountId === accountId;
      });

      if (customer) {
        console.warn('userStripeConnectCustomer customer exists', customer);
        setCustomer(customer);
        return;
      }
    }

    if (!create) {
      return;
    }

    const { firstName, lastName } = user;
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : '';

    const args = {
      endpoint: 'customers',
      method: 'post',
      data: {
        email: user.email,
        name
      },
      accountId
    };
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('userStripeConnectCustomer hook create Customer result', result);
        // Attach customer Id to user
        if (result.id) {
          const id = new BSON.ObjectID().toString();
          addStripe({
            id,
            accountId,
            customerId: result.id,
            userId: user._id
          });
          // $FlowFixMe
          setCustomer({
            accountId,
            customerId: result.id,
            userId: user._id,
            stripeId: id.toString()
          });
        } else {
          throw new Error(result.message);
        }
      })
      .catch((err) => console.error('Routes stripe err', err));
  }, [app, user]);

  return customer;
}
