/**
 * userPaymentMethods hook
 * Gets the user's stripe customer account, or if null, creates one
 * @flow
 */
import { useEffect, useState } from 'react';
import type { PaymentMethod, Stripe } from '../types/Types';

export default function userPaymentMethods(app: *, customer?: null | Stripe): PaymentMethod | null {
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    if (!customer || !customer.paymentMethods) {
      return;
    }

    const args = {
      endpoint: `payment_methods/${customer?.paymentMethods[0]}`,
      method: 'get'
    };
    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        setPaymentMethod(result);
      })
      .catch((err) => console.error('userPaymentMethods stripe err', err));
  }, [app, customer]);

  return paymentMethod;
}
