/**
 * ImagePicker for EditPost
 * Choose images or videos for adding to a post.
 * @flow
 */
import React, { useState, useRef } from 'react';
import type { Node } from 'react';
import { Button, CircularProgress } from '@mui/material';
import {
  CameraAltRounded,
  CameraRollRounded,
  ImageRounded,
  PlayCircleOutlineRounded,
  UploadRounded
} from '@mui/icons-material';
import { ObjectId } from 'bson';
import { IMAGE_TYPE, IMAGES, VIDEOS } from '../../data/Data';
import styles from './ImagePicker.module.scss';

type Props = {
  button: boolean,
  buttonText: string,
  field: number | string,
  icon: null | 'camera' | 'picture' | 'play' | 'upload',
  id: string,
  setMedia: (media: Array<any>) => *,
  type: string
};

const ImagePicker = (props: Props): Node => {
  const { button, buttonText, icon, id, setMedia, type } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  const accept = type === IMAGES ? '.jpg,.jpeg,.png' : '.jpg,.jpeg,.png,.mp4,.mov,.MOV';

  /**
   * click input
   */
  const clickInput = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    e.stopPropagation();

    if (inputRef && inputRef.current) {
      setError(false);
      inputRef.current.click();
    }
  };

  /**
   * Image chosen
   *
   * @param {object} event - the synthetic event
   */
  const chooseMedia = async (e: SyntheticInputEvent<*>) => {
    if (!e.target.files[0]) {
      return false;
    }

    setLoading(true);
    const { files } = e.target;
    console.warn('files', files);
    const max = files.length < 10 ? files.length : 10;
    let readers = [];
    let urls = [];

    for (let i = 0; i < max; i++) {
      readers[i] = new FileReader();

      if (/^image/.test(files[i].type)) {
        readers[i].onload = async () => {
          let image = new Image();
          // $FlowFixMe
          image.src = readers[i].result;
          image.onload = async () => {
            let { width, height } = image;
            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);
            urls[i] = {
              _id: new ObjectId().toString(),
              src: canvas.toDataURL(IMAGE_TYPE, 0.7),
              title: prettifyTitle(files[i].name),
              type: IMAGES, // this is tied to the S3 bucket name, which is plural
              width,
              height
            };
          };
        };
      } else if (/^video/.test(files[i].type)) {
        // load videos
        urls[i] = {
          _id: new ObjectId().toString(),
          // $FlowFixMe
          src: URL.createObjectURL(files[i]).replace('quicktime', 'mp4'),
          title: prettifyTitle(files[i].name),
          type: VIDEOS // this is tied to the S3 bucket name, which is plural
        };
      }
      readers[i].readAsDataURL(files[i]);
    }

    setTimeout(() => {
      setMedia([...urls]);
      setLoading(false);
    }, 2000);
  };

  /**
   * prettifyTitle
   */
  const prettifyTitle = (name: string) => {
    const parts = name.split('.');
    const title = parts[0].replace(/\\_\\-/, ' ');
    return title;
  };

  /**
   * RenderIcon
   */
  const renderIcon = () => {
    switch (true) {
      case loading:
        return <CircularProgress size={16} color="secondary" />;

      case icon === 'camera':
        return <CameraAltRounded />;

      case icon === 'camera-roll':
        return <CameraRollRounded />;

      case icon === 'play':
        return <PlayCircleOutlineRounded />;

      case icon === 'upload':
        return <UploadRounded />;

      default:
        return <ImageRounded />;
    }
  };

  /**
   * Render button
   */
  const renderButton = () => {
    return (
      <Button variant="contained" className={styles.UploadButton} onClick={clickInput}>
        {renderIcon()} <span>{buttonText}</span>
      </Button>
    );
  };

  switch (true) {
    default:
      return (
        <div className={styles.ImagePicker}>
          <label htmlFor={id} className={styles.chooseMedia} onClick={clickInput}>
            {button ? renderButton() : renderIcon()}
          </label>
          <input
            // $FlowFixMe
            ref={inputRef}
            id={id}
            name={id}
            type="file"
            accept={accept}
            onChange={chooseMedia}
            multiple={true}
          />
          {error ? <div className={styles.error}>Error editing image</div> : null}
        </div>
      );
  }
};

ImagePicker.defaultProps = {
  button: false,
  buttonText: 'Upload image',
  icon: null,
  id: 'pick-image',
  updateMedia: (): null => null
};

export default ImagePicker;
