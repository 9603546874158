/**
 * Post
 * @flow
 */
import React, { useEffect, useRef, useState, type Node } from 'react';
import { Redirect } from 'react-router-dom';
import PremiumPostTeaser from '../premium-post-teaser/PremiumPostTeaser';
import PremiumLabel from '../premium-label/PremiumLabel';
import ProfileHeaderMinimal from '../../components/profile-header-minimal/ProfileHeaderMinimal';
import PostActions from '../post-actions/PostActions';
import PostReply from '../post-reply/PostReply';
import Text from '../text/Text';
import MediaModal from '../media-modal/MediaModal';
import Carousel from '../carousel/Carousel';
import ReplyingTo from '../replying-to/ReplyingTo';
import { getUrls } from '../../lib/upload/upload';
import { IMAGES, VIDEOS } from '../../data/Data';
import type { Post as PostType, Stripe, User } from '../../types/Types';
import styles from './Post.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  isSingle: boolean,
  stripePromise: Promise<*>,
  deleted: boolean,
  post: PostType,
  posts?: Array<PostType>,
  reallyDeletePost: () => *,
  isSubbed: boolean,
  user: User
};

const Post = (props: Props): null | Node => {
  const { deleted, isSingle, isSubbed, post, reallyDeletePost, user } = props;
  const [mediaCache, setMediaCache] = useState<Array<{ _id: string, url: string }>>([]);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { created, likes, mediaOrder, premium, photos, replies, videos } = post;
  const postRef = useRef(null);
  const mediaRef = useRef(null);

  useEffect(() => {
    let filePaths = [];
    if (photos && photos.length > 0) {
      photos.map((item) => {
        const { _id, filePath } = item;
        filePaths.push({ _id, path: filePath, media: IMAGES });
      });
    }
    if (videos && videos.length > 0) {
      videos.map((item) => {
        const { _id, filePath } = item;
        filePaths.push({ _id, path: filePath, media: VIDEOS });
      });
    }
    getUrls(filePaths)
      .then((urls) => {
        let media = [];
        urls.map((url, index) => {
          media.push({
            _id: filePaths[index]._id,
            url
          });
        });
        setMediaCache(media);
      })
      .catch((err) => console.error(err));
  }, []);

  /**
   * View Single Post
   */
  const viewSinglePost = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setRedirect(true);
  };

  const toggleModal = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  switch (true) {
    case redirect:
      return <Redirect to={`/post/${post._id ? post._id : ''}`} />;

    case deleted:
      return null;

    default:
      return (
        <>
          <article ref={postRef} className={styles.Post}>
            <div className={styles.PreHeader}>
              <ReplyingTo post={post} />
              <PremiumLabel post={post} />
            </div>
            <ProfileHeaderMinimal created={created} user={post.user} />
            {isSingle ? (
              <Text {...props} />
            ) : (
              <div className={styles.LinkPost} onClick={viewSinglePost} tabIndex={0}>
                <Text {...props} />
              </div>
            )}

            {premium && !isSubbed && user._id !== post.user._id ? (
              <PremiumPostTeaser {...props} />
            ) : (photos && photos.length > 0) || (videos && videos.length > 0) ? (
              <div ref={mediaRef} className={styles.Media}>
                <Carousel
                  embedded={true}
                  mediaCache={mediaCache}
                  mediaOrder={mediaOrder}
                  // $FlowFixMe
                  photos={photos}
                  // $FlowFixMe
                  videos={videos}
                  containerRef={mediaRef}
                  toggleModal={toggleModal}
                />
              </div>
            ) : null}
            <div className={styles.PostFooter}>
              <PostActions {...props} deleteItem={reallyDeletePost} likes={likes} />
            </div>
            {replies && replies.length > 0 ? (
              <div className={styles.Replies}>
                {replies.map((reply) => {
                  return reply.content ? <PostReply {...props} key={reply._id} post={reply} parent={post} /> : null;
                })}
              </div>
            ) : null}
          </article>
          <MediaModal
            mediaCache={mediaCache}
            photos={photos}
            videos={videos}
            show={showModal}
            toggleModal={toggleModal}
          />
        </>
      );
  }
};

Post.defaultProps = {
  deleted: false,
  post: ({}: { ... }),
  posts: ([]: Array<empty>),
  reallyDeletePost: (): null => null
};

export default Post;
