/**
 * User functions
 * @flow
 */
import { ADMIN, ANON, API_KEY, CREATOR, EMAIL_UNVERIFIED, PENDING, UNVERIFIED, UNKNOWN } from '../../data/Data';
import dayjs from 'dayjs';
import type { User, UserRelation, VerificationAction, VerificationStatus } from '../../types/Types';

export const verificationStatus = (user: User | null): VerificationStatus => {
  const { verifications } = user ? user : { verifications: null };
  const { actions } = verifications && verifications.length > 0 ? verifications[0] : { actions: null };

  if (!user) {
    return UNKNOWN;
  }
  switch (true) {
    case user.providerType === API_KEY:
      return ANON;

    case !user.emailConfirmed:
      return EMAIL_UNVERIFIED;

    case user.role === ADMIN:
      return ADMIN;

    case !verifications || verifications.length === 0:
      return UNVERIFIED;

    case actions && actions.length > 0: {
      // $FlowFixMe
      let sortedActions = [...actions];
      sortedActions.sort((a: VerificationAction, b: VerificationAction) => {
        return dayjs(a.actionDate).isAfter(dayjs(b.actionDate)) ? -1 : 1;
      });

      return sortedActions[0].action;
    }

    default:
      return PENDING;
  }
};

export const userIsCreator = (user: User): boolean => {
  return user && user.role === CREATOR ? true : false;
};

/**
 * Deduplicate mutual follows
 */
export const dedupeFollows = (relations: Array<UserRelation>): Array<UserRelation> => {
  let newDeduped = [];

  for (const relation of relations) {
    const { sourceUser, targetUser } = relation;

    const inverseFollow = newDeduped.find((rel) => {
      return rel.targetUser._id === sourceUser._id && rel.sourceUser._id === targetUser._id;
    });

    if (inverseFollow) {
      continue;
    }

    newDeduped.push(relation);
  }

  return newDeduped;
};
