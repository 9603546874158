/**
 * Admin Nav
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import Logo from '../../header/logo/Logo';
import { AccountCircleRounded, AdminPanelSettings, MapRounded, VerifiedUserRounded } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import type { User } from '../../../types/Types';
import { useWindowSize } from '../../../hooks/windowResize';

type Props = {
  toggle: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  user: User
};

const AdminNav = (props: Props): Node => {
  const { toggle, user } = props;
  const { width } = useWindowSize();
  const { pathname } = window.location;
  const isAdmin = /admin/.test(pathname);

  if (!user || user?.role !== 'admin') {
    return null;
  }

  return (
    <Fragment>
      {isAdmin && width >= 768 ? (
        <li>
          <Logo />
        </li>
      ) : null}
      <li onClick={toggle}>
        <NavLink to="/admin">
          <AdminPanelSettings /> Admin
        </NavLink>
      </li>
      <li onClick={toggle}>
        <NavLink to="/admin/users">
          <AccountCircleRounded /> Users
        </NavLink>
      </li>
      <li onClick={toggle}>
        <NavLink to="/admin/verifications">
          <VerifiedUserRounded /> Verifications
        </NavLink>
      </li>
      <li onClick={toggle}>
        <NavLink to="/admin/sitemap">
          <MapRounded /> Sitemap
        </NavLink>
      </li>
    </Fragment>
  );
};

AdminNav.defaultProps = {
  toggle: (): null => null,
  user: null
};

export default AdminNav;
