import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerServiceWorker } from './register-sw';
import { getEnvironment, BUGSNAG_TOKEN } from './data/Data';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { version } from '../package.json';
import TagManager from 'react-gtm-module';
const stage = getEnvironment(window);
const releaseStage = stage === 'dev' ? 'development' : stage === 'stage' ? 'staging' : 'production';
console.warn(`nuttify v${version} ${releaseStage}`);

Bugsnag.start({
  apiKey: BUGSNAG_TOKEN,
  plugins: [new BugsnagPluginReact()],
  appVersion: version,
  beforeSend: function (report) {
    report.app.releaseStage = releaseStage;
  }
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

if (releaseStage === 'production') {
  let tagManagerArgs = {
    gtmId: 'GTM-5MR5F62'
  };

  TagManager.initialize(tagManagerArgs);
}

if (navigator.serviceWorker) {
  registerServiceWorker();
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
