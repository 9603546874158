import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useRelation = (query) => {
  const { relation, loading } = getRelationByQuery(query);

  return {
    loading,
    relation
  };
};
export default useRelation;

function getRelationByQuery(query) {
  const { data, loading, error } = useQuery(
    gql`
      query GetRelationById($query: RelationQueryInput!) {
        relation(query: $query) {
          _id
          created
          relationship
          sourceUser {
            _id
            userName
            profileImage
            firstName
            lastName
          }
          targetUser {
            _id
            userName
            profileImage
            firstName
            lastName
          }
        }
      }
    `,
    {
      variables: {
        query
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch relation: ${error.message}`);
  }

  // If the query has finished, return the relation from the result data
  // Otherwise, return null
  const relation = data?.relation ?? null;
  return { relation, loading };
}
