import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useSave = (query) => {
  const { save, loading } = getSaveById(query);

  return {
    loading,
    save
  };
};
export default useSave;

function getSaveById(query) {
  const { data, loading, error } = useQuery(
    gql`
      query GetSaveById($query: SaveQueryInput!) {
        save(query: $query) {
          _id
          userId
        }
      }
    `,
    {
      variables: {
        query
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch save: ${error.message}`);
  }

  // If the query has finished, return the save from the result data
  // Otherwise, return null
  const save = data?.save ?? null;
  return { save, loading };
}
