/**
 * Feed
 * @flow
 */
import React, { type Node } from 'react';
import Loading from '../loading/Loading';
import usePosts from '../../graphql/usePosts';
import FeedList from './FeedList';
import type { Stripe, User } from '../../types/Types';
import styles from './Feed.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  query: *,
  user: User,
  userIsOwner: boolean
};

const Feed = (props: Props): Node => {
  const { query } = props;
  const { posts, loading } = usePosts(query);

  return (
    <div className={styles.Feed}>{!query ? <Loading /> : <FeedList {...props} loading={loading} posts={posts} />}</div>
  );
};

export default Feed;
