/**
 * Favourite
 * @flow
 */
import React, { type Node } from 'react';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import type { User } from '../../types/Types';
import styles from './Favourite.module.scss';

type Props = {
  action: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  favoured: boolean,
  user: User
};

const Favourite = (props: Props): Node => {
  const { favoured, action } = props;

  const icon = favoured ? <StarRoundedIcon /> : <StarBorderRoundedIcon />;

  return (
    <a href="#save" className={styles.Favourite} onClick={action}>
      {icon}
    </a>
  );
};

Favourite.defaultProps = {
  action: (): null => null,
  favoured: false
};

export default Favourite;
