/**
 * CarouselSlide
 * @flow
 */
import React, { type Node } from 'react';
import CarouselImage from '../carousel-image/CarouselImage';
import CarouselVideo from '../carousel-video/CarouselVideo';
import type { MediaCache, Photo, Video } from '../../types/Types';
import styles from './CarouselSlide.module.scss';

type Props = {
  carouselDim: { width: number, height: number },
  embedded: boolean,
  mediaCache: MediaCache,
  item: Photo | Video,
  isModal: boolean,
  toggleModal?: (e: SyntheticMouseEvent<HTMLDivElement>) => *
};

const CarouselSlideContents = (props: Props): Node => {
  const { carouselDim, embedded, mediaCache, item, toggleModal } = props;
  const { __typename } = item;

  return __typename === 'Photo' ? (
    <>
      <CarouselImage
        embedded={embedded}
        mediaCache={mediaCache}
        // $FlowFixMe
        photo={item}
        carouselDim={carouselDim}
      />
      {embedded ? <div className={styles.OpenModal} onClick={toggleModal} /> : null}
    </>
  ) : (
    <>
      {/** $FlowFixMe */}
      <CarouselVideo mediaCache={mediaCache} video={item} carouselDim={carouselDim} />
    </>
  );
};

CarouselSlideContents.defaultProps = {
  carouselDim: { width: 0, height: 0 },
  embedded: true,
  mediaCache: ([]: Array<empty>),
  item: null,
  isModal: false
};

export default CarouselSlideContents;
