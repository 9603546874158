/**
 * Loading
 * @flow
 */
import React, { type Node } from 'react';
import { withStyles } from '@mui/styles';
import Squirrel from '../../assets/icons/supreme-squirrel.svg';
import { CircularProgress } from '@mui/material';
import styles from './Loading.module.scss';

type Props = {
  size: number, // for spinner
  type: 'spinner' | 'squirrel'
};

const CustomSpinner = withStyles({
  root: {
    color: '#9A1F33'
  }
})(CircularProgress);

const Loading = (props: Props): Node => {
  const { size, type } = props;

  return type === 'spinner' ? (
    <div className={styles.Loading}>
      <CustomSpinner size={size} />
    </div>
  ) : (
    <div className={styles.LoadingSquirrel}>
      <img src={Squirrel} />
    </div>
  );
};

Loading.defaultProps = {
  size: 48,
  type: 'spinner'
};

export default Loading;
