/**
 * Relation mutations
 */
import { ObjectId } from 'bson';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const useRelationMutations = () => {
  return {
    useAddRelation: useAddRelation(),
    updateRelation: useUpdateRelation(),
    useDeleteRelation: useDeleteRelation()
  };
};

export default useRelationMutations;

// This uses a custom resolver to insert a new relation.
// See app/functions/customRelationInputResolver.js
const AddRelationMutation = gql`
  mutation CustomAddRelation($relation: CustomRelationInput!) {
    addedRelation: CustomRelationInput(input: $relation) {
      _id
      created
      relationship
      sourceUser {
        _id
        userName
        profileImage
        firstName
        lastName
      }
      targetUser {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

const UpdateRelationMutation = gql`
  mutation UpdateRelation($relationId: ObjectId!, $updates: RelationUpdateInput!) {
    updatedRelation: updateOneRelation(query: { _id: $relationId }, set: $updates) {
      _id
      created
      relationship
      sourceUser {
        _id
        userName
        profileImage
        firstName
        lastName
      }
      targetUser {
        _id
        userName
        profileImage
        firstName
        lastName
      }
    }
  }
`;

const DeleteRelationMutation = gql`
  mutation DeleteRelation($relationId: ObjectId!) {
    deletedRelation: deleteOneRelation(query: { _id: $relationId }) {
      _id
    }
  }
`;

export const RelationFieldsFragment = gql`
  fragment RelationFields on Relation {
    _id
    created
    relationship
    sourceUser {
      _id
      userName
      profileImage
      firstName
      lastName
    }
    targetUser {
      _id
      userName
      profileImage
      firstName
      lastName
    }
  }
`;

function useAddRelation() {
  const [addRelationMutation, { data, loading, error }] = useMutation(AddRelationMutation, {
    // Manually save added Relations into the Apollo cache so that Relation queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedRelation } }) => {
      cache.modify({
        fields: {
          relations: (existingRelations = []) => [
            ...existingRelations,
            cache.writeFragment({
              data: addedRelation,
              fragment: RelationFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addRelation = async (relation) => {
    const id = new ObjectId();
    const { addedRelation } = await addRelationMutation({
      variables: {
        relation: {
          _id: id,
          ...relation
        }
      }
    });
    return addedRelation;
  };

  return { addRelation, data, loading, error };
}

const useUpdateRelation = () => {
  const [updateRelationMutation] = useMutation(UpdateRelationMutation);
  const updateRelation = async (relation, updates) => {
    const { updatedRelation } = await updateRelationMutation({
      variables: {
        relationId: new ObjectId(relation._id),
        updates
      }
    });
    return updatedRelation;
  };
  return updateRelation;
};

function useDeleteRelation() {
  const [deleteRelationMutation] = useMutation(DeleteRelationMutation);
  const deleteRelation = async (relation) => {
    const { deletedRelation } = await deleteRelationMutation({
      variables: { relationId: new ObjectId(relation._id) }
    });
    return deletedRelation;
  };
  return deleteRelation;
}
