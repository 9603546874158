/**
 * Premium Post Teaser
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import SubscribeButton from '../subscribe-button/SubscribeButton';
import { getUrls } from '../../lib/upload/upload';
import { IMAGES } from '../../data/Data';
import type { Post as PostType, Stripe, User } from '../../types/Types';
import styles from './PremiumPostTeaser.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  post: PostType,
  user: User
};

const PremiumPostTeaser = (props: Props): Node => {
  const { post } = props;
  const [style, setStyle] = useState(null);

  useEffect(() => {
    const { photos } = post;

    if (!photos || photos.length === 0) {
      return;
    }

    let filePaths = [{ path: photos[0].filePath, media: IMAGES }];

    getUrls(filePaths)
      .then((urls) => {
        setStyle({ backgroundImage: `url(${urls[0]})` });
      })
      .catch((err) => console.error(err));
  }, [post]);

  return (
    <div className={styles.PremiumPostTeaser}>
      {style ? <div className={styles.Background} style={style} /> : null}
      <div className={styles.Content}>
        <div className={styles.Lock}>
          <HttpsRoundedIcon size={100} />
        </div>
        <SubscribeButton {...props} owner={post.user} />
      </div>
    </div>
  );
};

export default PremiumPostTeaser;
