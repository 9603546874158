/**
 * Close icon
 * @flow
 */
import React, { type Node } from 'react';
import { CloseRounded } from '@mui/icons-material';
import styles from './Close.module.scss';

type Props = {
  onClick: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *
};

const Close = (props: Props): Node => {
  return (
    <a className={styles.Close} href="#close" onClick={props.onClick}>
      <CloseRounded />
    </a>
  );
};

export default Close;
