/**
 * Extract JSON post content
 * @flow
 */
import { useState, useEffect } from 'react';
import type { Post, Slate } from '../types/Types';

const usePostText = (post: Post | null): { contentString: string, contentArray: Slate } => {
  const [contentString, setContentString] = useState<string>('');
  const [contentArray, setContentArray] = useState<Slate>([]);

  useEffect(() => {
    if (!post || !post.content) {
      return;
    }

    try {
      // $FlowFixMe
      const newContentArr = JSON.parse(post.content);
      setContentArray(newContentArr);
    } catch (e) {
      // $FlowFixMe
      setContentString(post.content);
    }
  }, [post]);

  return { contentString, contentArray };
};

export default usePostText;
