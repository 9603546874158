/**
 * SnackBar
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { getRealmDb, UNREAD, SNACKBAR_STYLE } from '../../data/Data';
import type { User } from '../../types/Types';

type Props = {
  app: *,
  user: User
};

const NotificationsSnackbar = (props: Props): Node => {
  const { app, user } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [breakAsyncIterator, setBreakAsyncIterator] = useState<boolean>(false);
  const [hasListener, setHasListener] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (hasListener) {
      return;
    }

    listen();

    return () => {
      setBreakAsyncIterator(true);
    };
  }, [hasListener]);

  const listen = async () => {
    const DB = getRealmDb(window);
    const mongo = app.currentUser.mongoClient('mongodb-atlas');
    const notifications = mongo.db(DB).collection('notifications');
    setHasListener(true);

    /**
     * @important
     * The docs on Mongo DB are wrong. We don't use `$match` in an aggregate pipeline.
     * Reading the source for node_modules/realm-cli reveals that we need to
     * use the property `filter` to check watch specific records.
     *
     * node_modules/realm-web/types/realm/services.d.ts line 544
     */
    for await (let item of notifications.watch({ userId: user._id, status: UNREAD })) {
      // @todo check that we are not in the thread that this message belongs to
      const { status, userId } = item.fullDocument;
      console.warn('Notification Snackbar item.fullDocument', item.fullDocument);
      if (status === UNREAD && userId === user._id) {
        setMessage(item.fullDocument.title);
        setOpen(true);
      }

      if (breakAsyncIterator) {
        console.warn('Snackbar: breaking async iterator for Realm watch()');
        break;
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={SNACKBAR_STYLE}
    >
      <Link to="/notifications" onClick={handleClose}>
        <Alert severity="info">{message}</Alert>
      </Link>
    </Snackbar>
  );
};

export default NotificationsSnackbar;
