/**
 * Like
 * @flow
 */
import React, { type Node } from 'react';
// import { Link } from 'react-router-dom';
import { ChatBubbleOutlineRounded } from '@mui/icons-material';
// import { verificationStatus } from '../../lib/user/user';
// import { ANON } from '../../data/Data';
import type { Post, User } from '../../types/Types';
import styles from './ReplyIcon.module.scss';

type Props = {
  post: Post,
  toggleReply: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  user: User
};

const ReplyIcon = (props: Props): Node => {
  const { post, toggleReply } = props;
  const { replies } = post;
  // const status = verificationStatus(user);
  // const to = status === ANON ? `/post/${_id}` : `/create-post/?reply=${_id}&author=${post.user.userName}`;

  return (
    <a href="#reply" className={styles.ReplyIcon} onClick={toggleReply}>
      <ChatBubbleOutlineRounded /> {replies ? replies.length : null}
    </a>
  );
};

ReplyIcon.defaultProps = {
  replies: 0,
  alignment: 'horizontal'
};

export default ReplyIcon;
