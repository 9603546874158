/**
 * maxDims for images
 * @flow
 */
import { IMAGE_MAX } from '../data/Data';

export const maxDim = (image: Image): { width: number, height: number } => {
  let { width, height } = image;
  switch (true) {
    case width > IMAGE_MAX && width > height:
      height = (height / width) * IMAGE_MAX;
      width = IMAGE_MAX;
      break;

    case width > IMAGE_MAX && width < height:
      height = (height / width) * IMAGE_MAX;
      width = IMAGE_MAX;
      break;

    case height > IMAGE_MAX && width < height:
      width = (height / width) * IMAGE_MAX;
      height = IMAGE_MAX;
      break;

    case width > IMAGE_MAX && width === height:
      width = IMAGE_MAX;
      height = IMAGE_MAX;
      break;

    default:
    // no op
  }

  return { width, height };
};
