/**
 * FeedList
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import Loading from '../loading/Loading';
import SadFace from '../sad-face/SadFace';
import PostWrapper from '../post/PostWrapper';
import RecommendedFollowsWrapper from '../recommended-follows/RecommendedFollowsWrapper';
import type { Post as PostType, Stripe, User } from '../../types/Types';
import styles from './Feed.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  posts: Array<PostType>,
  stripePromise: *,
  loading: boolean,
  user: User
};

const FeedList = (props: Props): Node => {
  const { posts, loading } = props;
  let i = 0;

  return (
    <div className={styles.FeedList}>
      {loading ? (
        <Loading />
      ) : posts.length > 0 ? (
        posts.map((post) => {
          i++;
          if (i === 3) {
            return (
              <Fragment key={i}>
                <RecommendedFollowsWrapper {...props} />
                <PostWrapper {...props} key={post._id} post={post} />
              </Fragment>
            );
          }
          return <PostWrapper {...props} key={i} post={post} />;
        })
      ) : (
        <SadFace text="Nothing here, yet" />
      )}
    </div>
  );
};

FeedList.defaultProps = {
  loading: false,
  posts: ([]: Array<empty>)
};

export default FeedList;
