/**
 * UserField
 * @flow
 */
import React, { useState, type Node } from 'react';
import { TextField } from '@mui/material';
import type { UserFields, User } from '../../types/Types';
import styles from './UserFieldEdit.module.scss';

type Props = {
  edit: boolean,
  editValues: (field: string, value: string) => *,
  field: string,
  label: string, // edit label
  multiline: boolean,
  open: boolean,
  values: UserFields,
  user: User
};

const TWIT_RE = new RegExp(/^@.*/);
const LINK_RE = new RegExp(/^http(s)*:\/\/.*.\..*/);

const UserFieldEdit = (props: Props): Node => {
  const { editValues, field, label, multiline, values } = props;
  const [valid, setValid] = useState(null);

  /*
   * onChange of field
   */
  const onChange = (e: SyntheticEvent<>) => {
    // $FlowFixMe
    const { value } = e.target;
    let isValid = null;

    switch (field) {
      case 'bio':
        isValid = value.length <= 300;
        break;

      case 'twitter':
        isValid = TWIT_RE.test(value);
        break;

      case 'url':
        isValid = LINK_RE.test(value);
        break;

      default:
      // no op
    }

    setValid(isValid);
    editValues(field, value);
  };

  return (
    <div className={valid === false ? styles.UserFieldEditInvalid : styles.UserFieldEdit}>
      <div className={styles.InputWrapper}>
        <TextField
          multiline={multiline}
          label={label}
          id={`edit-${field}-field`}
          onChange={onChange}
          value={values[field]}
          rows={window.innerWidth < 768 ? 8 : 4}
          color="primary"
          valid={valid}
        />
      </div>
    </div>
  );
};

UserFieldEdit.defaultProps = {
  edit: false,
  editValues: (): null => null,
  multiline: false,
  open: false,
  user: {
    _id: '0',
    bio: ''
  }
};

export default UserFieldEdit;
