/**
 * Carousel
 * @flow
 */
import React, { useState, useEffect, useRef, type Node } from 'react';
import { ArrowBackIos, ArrowForwardIos, CancelRounded } from '@mui/icons-material';
import Video from '../video/Video';
import CarouselNumbers from '../carousel-numbers/CarouselNumbers';
import ImageEditable from '../image-editable/ImageEditable';
import { useWindowSize } from '../../hooks/windowResize';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { IMAGES, VIDEOS } from '../../data/Data';
import type { MediaEdit } from '../../types/Types';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from '../carousel/Carousel.module.scss';

type Props = {
  embedded: boolean,
  mediaOrder: Array<string>,
  isModal: boolean,
  photos: Array<MediaEdit>,
  setPhotos: (photos: Array<MediaEdit>) => *,
  setVideos: (videos: Array<MediaEdit>) => *,
  setPoster: (index: number, src: string) => *,
  videos: Array<MediaEdit>
};

const CarouselEdit = (props: Props): Node => {
  const { embedded, mediaOrder, isModal, photos, setPhotos, setPoster, setVideos, videos } = props;
  const [carouselDim, setCarouselDim] = useState<{ width: number, height: number }>({
    width: 400,
    height: 300
  });

  const windowDim = useWindowSize();
  const containerRef = useRef(null);
  const total = photos.length + videos.length;

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }
    const { width, height } = containerRef.current.getBoundingClientRect();

    setCarouselDim({ width, height });
  }, [windowDim]);

  /**
   * Remove a video from the post
   */
  const removeVideo = (e: SyntheticMouseEvent<HTMLAnchorElement>, index: number) => {
    e.preventDefault();
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  switch (true) {
    case photos.length + videos.length === 0:
      return null;

    case photos.length === 1 && videos.length === 0: {
      return (
        <div className={styles.Slider}>
          <ImageEditable field={0} carouselDim={carouselDim} media={photos} setMedia={setPhotos} />
        </div>
      );
    }

    case photos.length === 0 && videos.length === 1: {
      return (
        <div className={styles.Slider}>
          {/** $FlowFixMe */}
          <div className={styles.VideoContainer}>
            <a className={styles.RemoveVideo} href="#remove-video" onClick={(e) => removeVideo(e, 0)}>
              <CancelRounded />
            </a>
            <Video
              embedded={embedded}
              // $FlowFixMe
              video={videos[0]}
              carouselDim={carouselDim}
              setPoster={setPoster}
              keyDownListen={false}
            />
          </div>
        </div>
      );
    }

    default:
      return (
        <div ref={containerRef} className={styles.Carousel}>
          <CarouselProvider
            naturalSlideWidth={carouselDim.width}
            naturalSlideHeight={carouselDim.height}
            totalSlides={total}
            className={styles.Carousel}
            infinite={true}
          >
            <CarouselNumbers isModal={isModal} />
            <Slider className={styles.Slider}>
              {mediaOrder.map((id, index) => {
                let type = IMAGES;
                let i = photos.findIndex(({ _id }) => {
                  return _id === id ? true : false;
                });

                if (i === -1) {
                  i = videos.findIndex(({ _id }) => {
                    return _id === id ? true : false;
                  });

                  if (i === -1) {
                    return null;
                  } else {
                    type = VIDEOS;
                  }
                }

                return (
                  <Slide key={index} className={styles.Slide}>
                    {type === IMAGES ? (
                      <ImageEditable field={i} carouselDim={carouselDim} media={photos} setMedia={setPhotos} />
                    ) : type === VIDEOS ? (
                      <div className={styles.VideoContainer}>
                        <a className={styles.RemoveVideo} href="#remove-video" onClick={(e) => removeVideo(e, i)}>
                          <CancelRounded />
                        </a>
                        <Video
                          embedded={embedded}
                          // $FlowFixMe
                          video={videos[i]}
                          containerDim={carouselDim}
                          setPoster={setPoster}
                          keyDownListen={false}
                        />
                      </div>
                    ) : null}
                  </Slide>
                );
              })}
            </Slider>
            <ButtonBack className={styles.Back}>
              <div className={styles.Circle}>
                <ArrowBackIos />
              </div>
            </ButtonBack>
            <ButtonNext className={styles.Forward}>
              <div className={styles.Circle}>
                <ArrowForwardIos />
              </div>
            </ButtonNext>
          </CarouselProvider>
        </div>
      );
  }
};

CarouselEdit.defaultProps = {
  embedded: true,
  isModal: false,
  mediaOrder: ([]: Array<empty>),
  photos: ([]: Array<empty>),
  setPhotos: (): null => null,
  setVideos: (): null => null,
  setPoster: (): null => null,
  videos: ([]: Array<empty>)
};

export default CarouselEdit;
