/**
 * Feed Page
 * @flow
 */
import React, { useState, useEffect, Fragment, type Node } from 'react';
import Helmet from 'react-helmet';
import Header from '../../components/header/Header';
import Loading from '../../components/loading/Loading';
import Feed from '../../components/feed/Feed';
import Spacer from '../../components/spacer/Spacer';
import EditPost from '../../components/edit-post/EditPost';
import Sidebar from '../../components/sidebar/Sidebar';
import type { User } from '../../types/Types';
import { getEditors, FOLLOW } from '../../data/Data';
import useRelations from '../../graphql/useRelations';
import type { Stripe } from '../../types/Types';
import styles from './Feed.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  user: User,
  mainRef: *,
  stripePromise: *,
  headerRef: *
};

const FeedPage = (props: Props): Node => {
  const { user } = props;
  const [query, setQuery] = useState<{ userId_in: Array<string> } | null>(null);
  const { relations, loading } = useRelations({
    relationship: FOLLOW,
    AND: [{ sourceUser: { userId: user._id } }]
  });

  useEffect(() => {
    const editor: string = getEditors(window);
    let ids = editor ? [editor] : [];

    // get subscriptions
    if (user && user.subscriptions && user.subscriptions.length > 0) {
      user.subscriptions.map((sub) => {
        const { creatorId } = sub;
        if (ids.indexOf(creatorId) === -1) {
          ids.push(creatorId);
        }
      });
    }

    // following
    if (relations && relations.length > 0) {
      relations.map((relationship) => {
        ids.push(relationship.targetUser._id);
      });
    }

    // console.warn(ids);

    setQuery({
      userId_in: ids
    });
  }, [user, relations]);

  return (
    <Fragment>
      <Helmet>
        <title>Home | Nuttify</title>
        <meta name="description" content="Your nuttify feed" />
      </Helmet>
      <div className={styles.FeedPage}>
        <Header {...props} />
        <main ref={props.mainRef}>
          <EditPost {...props} />
          <Spacer height="2rem" />
          {loading ? <Loading /> : <Feed {...props} query={query} userIsOwner={true} />}
        </main>
        <Sidebar {...props} />
      </div>
    </Fragment>
  );
};

export default FeedPage;
