/**
 * ConfirmEmailNag
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Alert } from '@mui/material';
import { getRealmDb } from '../../data/Data';
import type { User } from '../../types/Types';
import styles from './ConfirmEmailNag.module.scss';

type Props = {
  app: *,
  user: User
};

const TEXT = 'Check your email to verify your email address';

const ConfirmEmailNag = (props: Props): null | Node => {
  const { app, user } = props;
  const [open, setOpen] = useState<boolean>(user?.emailConfirmed ? false : true);
  const [breakAsyncIterator, setBreakAsyncIterator] = useState<boolean>(false);
  const [hasListener, setHasListener] = useState<boolean>(false);

  /**
   * Listen to changes on user
   */
  useEffect(() => {
    if (hasListener || !app || !user) {
      return;
    }

    listen();

    return () => {
      setBreakAsyncIterator(true);
    };
  }, [app, user, hasListener]);

  /**
   * MongoDB Realm listen
   */
  const listen = async () => {
    const mongo = app.currentUser.mongoClient('mongodb-atlas');
    const DB = getRealmDb(window);
    const users = mongo.db(DB).collection('users');

    setHasListener(true);

    for await (let item of users.watch({
      userId: user.userId
    })) {
      if (item.fullDocument.emailConfirmed) {
        setOpen(false);
        setBreakAsyncIterator(true);
        break;
      }

      if (breakAsyncIterator) {
        break;
      }
    }
  };

  switch (true) {
    case !user:
    case user && user.emailConfirmed:
    case !open:
      return null;

    default:
      return (
        <div className={styles.ConfirmEmailNag}>
          <Alert variant="filled" severity="warning">
            <div className={styles.inner}>{TEXT}</div>
          </Alert>
        </div>
      );
  }
};

export default ConfirmEmailNag;
