/**
 * MessageButton
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ChatBubbleRounded from '@mui/icons-material/ChatBubbleRounded';
import useThreads from '../../graphql/useThreads';
import useThreadMutations from '../../graphql/useThreadMutations';
import { verificationStatus } from '../../lib/user/user';
import { ANON } from '../../data/Data';
import type { Thread, User } from '../../types/Types';
import styles from './MessageButton.module.scss';

type Props = {
  children?: *,
  user: User, // the logged in user
  owner: User // the owner of this profile
};

const MessageButton = (props: Props): Node => {
  const { children, user, owner } = props;
  const status = verificationStatus(user);
  const [redirect, setRedirect] = useState<boolean>(false);
  const query = {
    users_in: [
      {
        userId: user._id
      },
      {
        AND: {
          userId: owner._id
        }
      }
    ]
  };
  console.warn('MessageButton query', query);
  const { threads, loading } = useThreads(query);
  const [thread, setThread] = useState<Thread | null>(null);
  const { useAddThread } = useThreadMutations();
  const { data, error, addThread } = useAddThread;

  useEffect(() => {
    if (!threads || threads.length === 0) {
      return;
    }

    const thread = threads.find((thread) => {
      const hasUser = thread.users.find((person) => {
        return user._id === person._id;
      });

      const hasOwner = thread.users.find((person) => {
        return owner._id === person._id;
      });

      if (!!hasUser && !!hasOwner) {
        return true;
      }
      return false;
    });

    if (thread !== undefined) {
      setThread(thread);
    }
  }, [threads, setThread, user, owner]);

  /**
   * useEffect to redirect the page after creating a thread
   */
  useEffect(() => {
    if (error) {
      console.error('Error', error);
    }

    if (!data || !data.addedThread) {
      return;
    }

    setRedirect(true);
  }, [data, error]);

  /**
   * Create a thread
   */
  const createThread = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    addThread({ users: [user._id, owner._id] });
  };

  console.warn('Message Button thread && thread._id', thread && thread._id);

  switch (true) {
    case redirect:
      return <Redirect to={`/chat/${data.addedThread._id}`} />;

    case status === ANON:
      return null;

    case loading:
      return <div className={styles.MessageButtonLoading}>{children ? children : <ChatBubbleRounded />}</div>;

    case thread !== null:
      return (
        <>
          {/** $FlowFixMe */}
          <Link to={`/chat/${thread._id}`} className={styles.MessageButton}>
            {children ? children : <ChatBubbleRounded />}
          </Link>
        </>
      );

    default:
      return (
        <a href="#create-thread" className={styles.MessageButton} onClick={createThread}>
          {children ? children : <ChatBubbleRounded />}
        </a>
      );
  }
};

export default MessageButton;
