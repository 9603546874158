/**
 * Data
 * @flow
 */
type Window = {
  location: Location
};
export const ACHIEVEMENTS = [
  {
    title: 'Verified Email',
    trigger: 'verifyEmail',
    badge: ''
  },
  {
    title: 'Verified Phone',
    trigger: 'verifyPhone',
    badge: ''
  },
  {
    title: 'Verified Identity',
    trigger: 'verifyIdentity',
    badge: ''
  }
];

export const RECAPTCHA_KEY = '6LeiNAQgAAAAAMk95AVrW99pPbpmPtk32U4snzjX';

export const REALM_API_KEYS = {
  dev: 'e5KR0TlgC2ZJ7GmYEjJw61Q2mEJu3JrgqcKp7oUM7NyLUIVuB64uyZVCiklp4VSx',
  stage: '1nIN4JEku8pjRrOBuf9B2mx2xrSc83raiPnPp07rMknDh5cfAQT5OhHLkWpBR8eb',
  prod: '1nIN4JEku8pjRrOBuf9B2mx2xrSc83raiPnPp07rMknDh5cfAQT5OhHLkWpBR8eb'
};

export const DOMAINS = {
  dev: 'dev.nuttify.com',
  stage: 'stage.nuttify.com', // master branch
  prod: 'nuttify.com' // tagged releases
};

export const REALM_APPS = {
  dev: 'fluffr-rmlhd',
  stage: 'nuttify-ltmsm',
  prod: 'nuttify-ltmsm' // Update when app setup
};

export const REALM_DBS = {
  dev: 'Fluffr',
  stage: 'nuttify',
  prod: 'nuttify'
};

export const EDITORS = {
  dev: '62201cc943abdc650edce8bf',
  stage: '62426ac6c14d91fdf07640de',
  prod: '62426ac6c14d91fdf07640de'
};

export const getEnvironment = (window: Window): string => {
  for (let env in DOMAINS) {
    if (window.location.host === DOMAINS[env]) {
      return env;
    }
  }
  return 'dev';
};

export const getEditors = (window: Window): string => {
  const env = getEnvironment(window);
  return EDITORS[env];
};

export const getRealmDb = (window: Window): string => {
  const env = getEnvironment(window);
  return REALM_DBS[env];
};

export const getRealmAppId = (window: Window): string => {
  const env = getEnvironment(window);
  return REALM_APPS[env];
};

export const getRealmApiKey = (window: Window): string => {
  const env = getEnvironment(window);
  return REALM_API_KEYS[env];
};

export const getMediaApiUrl = (window: Window): string => {
  const env = getEnvironment(window) === 'dev' ? 'dev' : 'production';
  return `https://qnevo47uo8.execute-api.ap-southeast-2.amazonaws.com/${env}`;
};

export const getProfileImageUrl = (window: Window): string => {
  const env = getEnvironment(window);
  return env === 'dev' ? 'https://dq2dh0upvyzyf.cloudfront.net' : 'https://profile.nuttify.com';
};

export const getWebhooksUrl = (window: Window): string => {
  const app = getRealmAppId(window);
  // eg:
  // https://ap-southeast-2.aws.data.mongodb-api.com/app/${app}/endpoint
  return `https://ap-southeast-2.aws.data.mongodb-api.com/app/${app}/endpoint`;
};

export const BUGSNAG_TOKEN: string = 'fb6eb058b1ecf80cf0eccff3b25749a8';
export const GOOGLE_MAPS_API_KEY: string = 'AIzaSyBg_y8HfRITgOY1FUwhYpDvbSRFRt4Tbus';
export const SITE_TITLE: string = 'Nuttify';
export const STRAP_LINE: string = 'Create content. Get paid';
export const IMAGE_TYPE: string = 'image/jpeg';
export const VIDEO_TYPE: string = 'video/mp4';
export const IMAGE_MAX: number = 1280;

export const OPTIONS = {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'error', // manual, *follow, error
  referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const ACCEPTABLE_ID = [
  { title: 'Select ID type', value: 'none' },
  // eslint-disable-next-line quotes
  { title: "Driver's licence", value: 'drivers_licence' },
  { title: 'Passport', value: 'passport' },
  { title: 'Other photo ID', value: 'other' }
];

export const COUNTRY_CODES = [
  { country: 'AU', currency: 'AUD', locale: 'en-AU' },
  { country: 'CA', currency: 'CAD', locale: 'en-CA' },
  { country: 'MX', currency: 'MXN', locale: 'es-MX' },
  { country: 'NZ', currency: 'NZD', locale: 'en-NZ' },
  { country: 'US', currency: 'USD', locale: 'en-US' },
  { country: 'UK', currency: 'GBP', locale: 'en-GB' }
];
export const CREATOR_COUNTRIES = ['AU', 'CA', 'MX', 'NZ', 'GB', 'US'];
export const API_KEY = 'api-key';
export const PLATFORM_FEE = 0.2;
export const ADMIN = 'admin';
export const ANON = 'anonymous';
export const APPROVED = 'approved';
export const BLOCKED = 'blocked';
export const PROVISIONAL = 'provisional';
export const DECLINED = 'declined';
export const DEFAULT = 'default';
export const DENIED = 'denied';
export const DISABLED = 'disabled';
export const DISMISS_GET_VERIFIED = 'dismissGetVerified';
export const EDITOR = 'editor';
export const ENABLED = 'enabled';
export const FOLLOW = 'follow';
export const FOLLOWING = 'following';
export const UNFOLLOW = 'unfollow';
export const GRANTED = 'granted';
export const PENDING = 'pending';
export const UNKNOWN = 'unknown';
export const CREATOR = 'creator';
export const USER = 'user';
export const UNVERIFIED = 'unverified';
export const EMAIL_UNVERIFIED = 'emailUnverified';
export const READ = 'read';
export const SENDING = 'sending';
export const SENT = 'sent';
export const UNREAD = 'unread';
export const DELETED = 'deleted';
export const RECEIVED = 'received';
export const ADD = 'add';
export const NEW = 'new';
export const REMOVE = 'remove';
export const PROVIDER_TYPES = { apiKey: 'api-key', localUser: 'local-userpass' };
export const EMAIL_PROVIDERS = ['gmail.com', 'hotmail.co.uk', 'icloud.com', 'yahoo.com', 'live.com'];
export const MSG_LIMIT = 20;
export const DOCS = 'docs';
export const PHOTO = 'photo';
export const VIDEO = 'video';
export const VIDEOS = 'videos';
export const IMAGE = 'image';
export const IMAGES = 'images';
export const USERNAME_RE: RegExp = new RegExp(/@[a-zA-Z0-9]{1,15}/g);
export const SHOW_MIN_WIDTH = 1000;
export const SNACKBAR_STYLE = { bottom: { xs: 120, sm: 180 }, zIndex: 1001 };
export const MONGO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.000ZZ';
export const DEFAULT_LOAD_MSG_LIMIT = 12;
