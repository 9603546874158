/**
 * Footer
 * @flow
 */
import React, { type Node } from 'react';
import Copyright from '../copyright/Copyright';
import FooterNav from './footer-nav/FooterNav';
import styles from './Footer.module.scss';

const Footer = (): Node => {
  return (
    <footer className={styles.Footer}>
      <FooterNav />
      <Copyright style={{ textAlign: 'left', padding: 0 }} />
    </footer>
  );
};

export default Footer;
