import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import useUserMutations from './useUserMutations';

const useUsers = (query, limit = 10) => {
  const { users, loading } = getAllUsers(query, limit);

  const { addUser, updateUser } = useUserMutations();
  return {
    loading,
    users,
    updateUser,
    addUser
  };
};
export default useUsers;

function getAllUsers(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetUsers($query: UserQueryInput!, $limit: Int) {
        users(query: $query, sortBy: USERID_DESC, limit: $limit) {
          _id
          bio
          dateJoined
          email
          emailConfirmed
          free
          firstName
          headerImage
          lastName
          phone
          phoneConfirmed
          profileImage
          role
          stripe {
            _id
            accountId
            bankAccount
            customerId
            setupIntents
            paymentMethods
            stripeId
          }
          subscriptions {
            _id
            accountId
            creatorId
            currentPeriodEnd
            invoices {
              _id
              invoiceId
              stripeId
              userId
            }
            status
            stripeId
            subscriptionId
          }
          url
          userName
          verifications {
            _id
            address {
              country
              locality
              street
              state
              postcode
            }
            actions {
              _id
              action
              actionDate
              note
            }
            created
            dateOfBirth
            firstName
            idType
            idNumber
            idOther
            ipAddress
            images {
              _id
              filePath
              title
            }
            lastName
            userAgent
          }
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'cache-and-network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch users: ${error.message}`);
  }

  // If the query has finished, return the users from the result data
  // Otherwise, return an empty list
  const users = data?.users ?? [];
  return { users, loading };
}
