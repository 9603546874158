/**
 * Video
 * @flow
 */
import React, { useEffect, useRef, useState, type Node } from 'react';
import type { Video as VideoType } from '../../types/Types';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import VideoControls from './video-controls/VideoControls';
import { CircularProgress } from '@mui/material';
import useFullscreenStatus from '../../hooks/useFullscreenStatus';
import { getSignedUrlsDownload } from '../../lib/upload/upload';
import { IMAGE_TYPE, IMAGES, VIDEOS } from '../../data/Data';
import styles from './Video.module.scss';

type Props = {
  autoPlay: boolean,
  containerDim: { width: number, height: number } | null,
  embedded?: boolean,
  field?: number,
  keyDownListen: boolean,
  loop: boolean,
  rc: boolean,
  posters: any,
  setPoster: (index: number, poster: string) => *, // used when loading image into new Post
  video: VideoType
};

const Video = (props: Props): Node => {
  const isIos = /iPhone|iPad/.test(navigator.appVersion);
  const { autoPlay, containerDim, embedded, keyDownListen, loop, rc, setPoster, video } = props;
  const { _id, filePath, poster, src } = video;
  const [play, setPlay] = useState(autoPlay);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [frameId, setFrameId] = useState(null);
  const [orientation, setOrientation] = useState('');
  const [vidWidth, setVidWidth] = useState(0);
  const [vidHeight, setVidHeight] = useState(0);
  const [videoContainerMouseMove, setVideoContainerMouseMove] = useState(false);
  const [playsInline, setPlaysInline] = useState(isIos ? false : true);
  const [muted, setMuted] = useState(isIos && !embedded ? true : false); // ios will not autoplay unless muted
  const [isIosFullscreen, setIosFullscreen] = useState(false);
  const [posterSrc, setPosterSrc] = useState('');
  const [videoSrc, setVideoSrc] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loaded, setLoaded] = useState(false); // true for ios
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const bigButtonRef = useRef(null);
  const controlRef = useRef(null);
  let isFullscreen = false;
  let setFullscreen;

  try {
    [isFullscreen, setFullscreen] = useFullscreenStatus(videoContainerRef);
  } catch (e) {
    isFullscreen = false;
    setFullscreen = undefined;
  }

  const handleExitFullscreen = () => document.exitFullscreen();

  /**
   * Get poster image from Cloudfront
   */
  useEffect(() => {
    if (poster && posterSrc === '') {
      // $FlowFixMe
      getSignedUrlsDownload([{ path: poster.filePath, media: IMAGES }])
        .then((urls) => {
          setPosterSrc(urls[0]);
        })
        .catch((err) => console.error(err));
    }
  }, [poster, posterSrc]);

  /**
   * Get video from Cloudfront
   */
  useEffect(() => {
    if (filePath && videoSrc === '') {
      getSignedUrlsDownload([{ path: filePath, media: VIDEOS }])
        .then((urls) => {
          setVideoSrc(urls[0]);
        })
        .catch((err) => console.error(err));
    }
  }, [filePath, videoSrc]);

  /**
   * PauseVideo if offscreen using intersection observer
   */
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio === 0) {
        pauseVideo();
      }
    });

    if (!videoRef || !videoRef.current) {
      return;
    }

    observer.observe(videoRef.current);

    return () => {
      if (!videoRef || !videoRef.current) {
        return;
      }
      observer.unobserve(videoRef.current);
    };
  }, []);

  /**
   * unload videoUrl object
   */
  useEffect(() => {
    if (embedded || !src) {
      return;
    }

    return () => {
      if (/^blob/.test(src)) {
        URL.revokeObjectURL(src);
      }
    };
  }, [src, embedded]);

  /**
   * Add event listeners for context menu to disable downloading of video,
   * And to listen to keydown on page load
   */
  useEffect(() => {
    // $FlowFixMe
    document.addEventListener('contextmenu', contextMenuListener);
    window.addEventListener('keydown', keyDown);

    // Remove event listeners on cleanup
    return () => {
      // $FlowFixMe
      document.removeEventListener('contextmenu', contextMenuListener);
      window.removeEventListener('keydown', keyDown);
    };
  }, []);

  /**
   * When `play` or `time` state changes
   */
  useEffect(() => {
    if (!embedded) {
      return;
    }

    window.addEventListener('keydown', keyDown);

    // Remove event listeners on cleanup
    return () => {
      // $FlowFixMe
      window.removeEventListener('keydown', keyDown);
    };
  }, [play, time, embedded]);

  /**
   * Context menu listener
   */
  const contextMenuListener = (e: SyntheticEvent<>) => {
    if (rc) {
      e.preventDefault();
    }
  };

  /**
   * keyDown listener
   */
  const keyDown = (e: SyntheticKeyboardEvent<*>) => {
    if (!embedded || !keyDownListen) {
      return;
    }

    switch (true) {
      // $FlowFixMe
      case e.code === 'Space' && play:
        playVideo();
        break;

      // $FlowFixMe
      case e.code === 'Space' && !play:
        pauseVideo();
        break;

      case (e.key === 'f' || e.key === 'F') && !isIos:
        toggleFullscreen();
        break;

      case e.key === 'ArrowLeft':
        scrub(false);
        break;

      case e.key === 'ArrowRight':
        scrub(true);
        break;

      default:
      // no op
    }
  };

  /**
   * scrub forward / reverse
   */
  const scrub = (direction: boolean) => {
    let newTime = direction ? time + 0.5 : time - 0.5;
    // clamp time within bounds
    newTime = newTime <= 0 ? 0 : newTime >= duration ? duration : newTime;
    setJumpTime(newTime);
  };

  /**
   * Triggers on loading of video
   */
  const onLoad = () => {
    setSpinner(false);
    setLoaded(true);
    getOrientation();

    if (!embedded && !isIos) {
      makePoster();
    }
  };

  /**
   * get orientation
   */
  const getOrientation = () => {
    if (!videoRef || !videoRef.current || !videoContainerRef || !videoContainerRef.current) {
      return;
    }

    const { width } = videoContainerRef.current.getBoundingClientRect();
    let videoWidth = videoRef.current.videoWidth;
    let videoHeight = (width / videoWidth) * videoRef.current.videoHeight;
    const newOrientation =
      videoWidth > videoHeight
        ? 'landscape'
        : videoWidth < videoHeight
        ? 'portrait'
        : videoWidth === videoHeight
        ? 'square'
        : '';

    // If we have a containerRef, then set max height to that
    if (containerDim) {
      let controlDim = { width: videoWidth, height: 30 };

      if (controlRef && controlRef.current) {
        controlDim = controlRef.current.getBoundingClientRect();
      }

      videoHeight = containerDim.height - controlDim.height;
      videoWidth = videoWidth * (videoHeight / containerDim.height);
    }

    setOrientation(newOrientation);
    setVidWidth(videoWidth);
    setVidHeight(videoHeight);
    setDuration(videoRef.current.duration);
  };

  /**
   * getPoster
   * For creating poster image when adding video to new post
   */
  const makePoster = () => {
    if (!videoRef || !videoRef.current || embedded || isIos) {
      return;
    }

    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;
    const video = document.getElementById(`video-${_id}`);
    playVideo();
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    setTimeout(() => {
      ctx.drawImage(video, 0, 0, width, height);
      const newPoster = canvas.toDataURL(IMAGE_TYPE, 0.7);
      setPoster(parseInt(_id), newPoster);
      pauseVideo();
      setJumpTime(0);
      setMuted(false);
    }, 500);
  };

  /**
   * onPlay
   **/
  const onPlay = () => {
    setPlay(true);
  };

  /**
   * onPause
   **/
  const onPause = () => {
    setPlay(false);
  };

  /**
   * Toggle play state of video
   */
  const togglePlay = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    if (!videoRef || !videoRef.current) {
      return;
    }

    if (!play) {
      playVideo();
    } else {
      pauseVideo();
    }
  };

  /**
   * play video
   */
  const playVideo = () => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    videoRef.current.play();
    updateCurrentTime();
    // trigger fade effects on BigButton (and play controls in full screen)
    onMouseMove();
  };

  const pauseVideo = () => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    videoRef.current.pause();

    // clear Interval & animationFrame
    clearInterval();
    if (frameId) {
      cancelAnimationFrame(frameId);
    }

    // trigger fade effects on BigButton (and play controls in full screen)
    onMouseMove();
  };

  /**
   * Get the current time of the video playback
   */
  const updateCurrentTime = () => {
    updateProgress();

    setInterval(() => {
      updateProgress();
    }, 100);
  };

  /**
   * setCurrentTime based on mouse / touch events
   */
  const setJumpTime = (jumpTime: number) => {
    if (!videoRef || !videoRef.current) {
      return;
    }

    clearInterval();
    if (frameId) {
      cancelAnimationFrame(frameId);
    }
    const id = requestAnimationFrame(() => {
      // this sets the video playtime
      setTime(jumpTime);
      videoRef.current.currentTime = jumpTime;
    });
    setFrameId(id);
  };

  /**
   * updateProgress
   * Use requestAnimationFrame to get the video time
   */
  const updateProgress = () => {
    const id = requestAnimationFrame(() => getTime());
    setFrameId(id);
  };

  /**
   * Get the currentTime of the video
   * This is called within requestAnimationFrame by updateProgress()
   */
  const getTime = () => {
    if (!videoRef || !videoRef.current) {
      return;
    }
    const newTime = videoRef.current.currentTime;
    setTime(newTime);
    // do custom video analytics here. Maybe concat analytics to an array / object and send periodically to backend
  };

  /**
   * toggleFullscreen ios
   */
  const toggleFullscreenIos = () => {
    setPlaysInline(false);
    setMuted(false);
    playVideo();
    setIosFullscreen(isIosFullscreen);
  };

  /**
   * Toggle fullscreen video
   */
  const toggleFullscreen = (e?: SyntheticMouseEvent<>) => {
    if (e) {
      e.preventDefault();
    }

    console.warn('isFullscreen, setFullscreen', isFullscreen, setFullscreen);

    if (isIos) {
      toggleFullscreenIos();
    } else if (!isFullscreen && setFullscreen) {
      setFullscreen();
    } else if (document.fullscreenElement !== null && document.exitFullscreen()) {
      handleExitFullscreen();
    }
  };

  /**
   * toggle playsInline
   */
  const togglePlaysInline = (e: SyntheticTouchEvent<>) => {
    e.preventDefault();
    if (playsInline) {
      playVideo();
    }
    setPlaysInline(!playsInline);
  };

  /**
   * toggleMute
   */
  const toggleMute = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMuted(!muted);
  };

  /**
   * onMouseMove
   */
  const onMouseMove = () => {
    clearTimeout();
    setVideoContainerMouseMove(true);
    setTimeout(() => {
      setVideoContainerMouseMove(false);
    }, 2000);
    if (bigButtonRef && bigButtonRef.current && bigButtonRef.current.focus) {
      bigButtonRef.current.blur();
    }
  };

  // classes
  let classes = [isFullscreen ? styles.VideoContainerFullscreen : styles.VideoContainer, orientation];
  // if ios we set the video .5 seconds into the vid to show a frame.
  return (
    <div
      //$FlowFixMe
      ref={videoContainerRef}
      className={`${classes.join(' ').trim()}`}
    >
      <div className={isFullscreen ? styles.VideoFullscreen : styles.Video}>
        {spinner && !loaded ? (
          <div className={styles.Processing}>
            <CircularProgress />
            {!embedded ? 'Processing video' : null}
          </div>
        ) : null}
        <video
          //$FlowFixMe
          ref={videoRef}
          id={`video-${_id}`}
          className={styles[orientation]}
          autoPlay={isIos && !embedded ? true : false}
          controls={false}
          loop={loop}
          onLoadedData={onLoad}
          onPlay={onPlay}
          onPause={onPause}
          poster={posterSrc}
          src={videoSrc ? `${videoSrc}${isIos ? '#t=0.5' : ''}` : src ? src : ''}
          playsInline={playsInline}
          muted={muted}
          preload="metadata"
          height={vidHeight}
        />
        {loaded ? (
          <a
            // $FlowFixMe
            ref={bigButtonRef}
            href="/toggle-play"
            className={videoContainerMouseMove ? styles.BigButtonShow : styles.BigButton}
            onClick={togglePlay}
            onMouseMove={onMouseMove}
          >
            {play ? <PauseCircleFilledRoundedIcon /> : <PlayCircleFilledRoundedIcon />}
          </a>
        ) : null}
      </div>
      <VideoControls
        autoPlay={autoPlay}
        controlRef={controlRef}
        duration={duration}
        isIos={isIos}
        isFullscreen={isIos ? isIosFullscreen : isFullscreen}
        muted={muted}
        orientation={orientation}
        play={play}
        playsInline={playsInline}
        setJumpTime={setJumpTime}
        time={time}
        toggleFullscreen={toggleFullscreen}
        toggleMute={toggleMute}
        togglePlay={togglePlay}
        togglePlaysInline={togglePlaysInline}
        videoContainerMouseMove={videoContainerMouseMove}
        videoRef={videoRef}
        vidWidth={vidWidth}
      />
    </div>
  );
};

Video.defaultProps = {
  autoPlay: false,
  containerDim: null,
  containerRef: null,
  embedded: true,
  keyDownListen: true,
  loop: true,
  posters: ([]: Array<empty>),
  rc: false,
  setPoster: (): null => null,
  video: {
    _id: 0,
    poster: '',
    src: '',
    title: ''
  }
};

export default Video;
