/**
 * PremiumLabel
 * @flow
 */
import React, { type Node } from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Chip from '@mui/material/Chip';
import type { Post } from '../../types/Types';
import styles from './PremiumLabel.module.scss';

type Props = {
  post: Post
};

const PremiumLabel = (props: Props): Node => {
  const { post } = props;

  return post.premium ? (
    <div className={styles.PremiumLabel}>
      <Chip icon={<WorkspacePremiumIcon />} label="Premium" color="primary" />
    </div>
  ) : null;
};

export default PremiumLabel;
