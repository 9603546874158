/**
 * ProfileHeaderMinimal
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import UserAvatar from '../user-avatar/UserAvatar';
import UserField from '../user-field/UserField';
import UserVerified from '../user-verified/UserVerified';
import DateTime from '../../components/datetime/DateTime';
import { Link } from 'react-router-dom';
import type { User } from '../../types/Types';
import styles from './ProfileHeaderMinimal.module.scss';

type Props = {
  created?: string,
  isSingle: boolean, // used in PostSingle to indent left for top menu on mobile
  link?: boolean,
  parent: 'post' | 'comment' | 'subscribe',
  profileImageClassName: string,
  user: User | null, // the logged in user
  owner: User | null // the owner of this profile
};

const ProfileHeaderMinimal = (props: Props): Node => {
  const { created, isSingle, link, user, parent } = props;

  if (!user) {
    return null;
  }
  const { pathname } = window.location;
  let { userName } = user;

  const size = parent === 'comment' ? 40 : parent == 'subscribe' ? 36 : 56;

  const renderHeader = () => {
    return (
      <Fragment>
        <UserAvatar size={size} user={user} />
        <div className={styles.UserFields}>
          <div className={styles.Name}>
            <UserField field="firstName" user={user} />
            <UserField field="lastName" user={user} />
            <UserVerified {...props} user={user} />
          </div>
          <UserField field="userName" user={user} />
        </div>
        {created ? <DateTime datetime={created} /> : null}
      </Fragment>
    );
  };

  if (link && pathname.substr(1).toLowerCase() !== userName.toLowerCase()) {
    return (
      <Link
        className={
          parent === 'subscribe'
            ? styles.ProfileHeaderMinimalSubscribe
            : isSingle
            ? styles.ProfileHeaderMinimalSingle
            : styles.ProfileHeaderMinimal
        }
        to={`/${userName}`}
      >
        {renderHeader()}
      </Link>
    );
  }

  return (
    <div
      className={
        parent === 'subscribe'
          ? styles.ProfileHeaderMinimalSubscribe
          : parent === 'comment'
          ? styles.ProfileHeaderMinimalComment
          : styles.ProfileHeaderMinimal
      }
    >
      {renderHeader()}
    </div>
  );
};

ProfileHeaderMinimal.defaultProps = {
  edit: false,
  isSingle: false,
  link: true,
  owner: null,
  parent: 'post',
  profileImageClassName: 'ProfileImageMinimal'
};

export default ProfileHeaderMinimal;
