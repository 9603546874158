/**
 * ImageEditable
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import ImageEditor from '../image-editor/ImageEditor';
import styles from './ImageEditable.module.scss';
import type { MediaEdit } from '../../types/Types';

type Props = {
  allowCropChange: boolean,
  aspectRatio?: number,
  allowCropChange: boolean,
  carouselDim: { width: number, height: number },
  cropBoxMovable: boolean,
  cropBoxResizable: boolean,
  field: number,
  media: Array<MediaEdit>,
  updateImage: (field: number, uri: string) => *,
  setMedia: (media: Array<MediaEdit>) => *
};

const ImageEditable = (props: Props): Node => {
  const { carouselDim, field, media } = props;
  const [dim, setDim] = useState<{ width: number, height: number }>({ width: 0, height: 0 });

  useEffect(() => {
    const img = media[field];
    if (!img || !img?.width || !img?.height) {
      return;
    }
    let ratio = 1;
    let width = carouselDim.width;
    let height = carouselDim.height;

    const imagePortrait = img.height > img.width ? true : false;
    const screenPortrait = carouselDim.height > carouselDim.width ? true : false;

    switch (true) {
      case imagePortrait && screenPortrait:
      case !imagePortrait && !screenPortrait:
        ratio = carouselDim.width / img.width;
        height = Math.floor(img.height * ratio);
        width = Math.floor(carouselDim.width);
        break;

      case imagePortrait && !screenPortrait:
        ratio = carouselDim.height / img.height;
        height = Math.floor(carouselDim.height);
        width = Math.floor(img.width * ratio);
        break;

      case !imagePortrait && screenPortrait:
        ratio = carouselDim.width / img.width;
        width = Math.floor(carouselDim.width);
        height = Math.floor(img.height * ratio);
        break;

      default:
        width = Math.floor(carouselDim.width);
        height = Math.floor(carouselDim.height);
    }
    setDim({ width, height });
  }, [carouselDim, media]);

  return media[field] && media[field].src ? (
    <div className={styles.ImageEditable}>
      <div className={styles.background} style={{ backgroundImage: `url(${media[field].src})` }} />
      <img {...dim} src={media[field].src} />
      <ImageEditor {...props} id={`edit-post-image-editor${field}`} />
    </div>
  ) : null;
};

ImageEditable.defaultProps = {
  allowCropChange: true,
  carouselDim: { width: window.innerWidth * 0.95 - 32, height: window.innerHeight * 0.95 - 32 },
  cropBoxMovable: false,
  cropBoxResizable: false,
  field: 0,
  media: ([]: Array<empty>),
  index: 0,
  updateImage: (): null => null
};

export default ImageEditable;
