/**
 * Slate helper functions
 * @flow
 */
import type { TextElement, Slate } from '../../types/Types';

export const SLATE_INITIAL_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

/**
 * Text node has content
 */
export const nodeHasContent = (node: TextElement): boolean => {
  if (node.type === 'paragraph') {
    for (const child of node.children) {
      if (child.text !== '') {
        return true;
      }
    }
  }
  return false;
};

/**
 * slateHasValue
 * Check if Slate editor has content
 */
export const slateHasValue = (content: Slate): boolean => {
  for (const node of content) {
    if (nodeHasContent(node)) {
      return true;
    }
  }

  return false;
};

export const slateToMessage = (content: Slate): Slate => {
  let newContent = [];

  for (const node of content) {
    if (nodeHasContent(node)) {
      newContent.push(node);
    }
  }

  return newContent;
};
