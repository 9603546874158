/**
 * Profile Nav Button
 * @flow
 */
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import type { User } from '../../../types/Types';
import { useWindowSize } from '../../../hooks/windowResize';
import { getProfileImageUrl } from '../../../data/Data';
import styles from './ProfileNavButton.module.scss';

type Props = {
  drawer: boolean,
  user: User
};

const ProfileNavButton = (props: Props): Node => {
  const { drawer, user } = props;
  const { firstName, lastName, profileImage, userName } = user
    ? user
    : { firstName: '', lastName: '', profileImage: false, userName: '' };
  const initials = firstName && lastName ? `${firstName[0].toUpperCase()}` : userName ? userName[0].toUpperCase() : 'N';
  const [src, setSrc] = useState('');
  const { width } = useWindowSize();
  useEffect(() => {
    if (user && user.profileImage && src === '') {
      setSrc(`${getProfileImageUrl(window)}/${user._id}/profileImage.jpg`);
    }
  }, []);

  const style = profileImage ? { backgroundImage: `url('${src}')` } : {};

  return (
    <a href={`/${userName}`} className={styles.ProfileNavButton}>
      {profileImage ? (
        <div className={styles.Image} style={style} tabIndex="0" />
      ) : (
        <div className={styles.Badge}>{initials}</div>
      )}
      {width >= 768 || drawer ? <span className={styles.Text}>Profile</span> : null}
    </a>
  );
};

ProfileNavButton.defaultProps = {
  drawer: false,
  user: null
};

export default ProfileNavButton;
