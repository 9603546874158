/**
 * FollowButton
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import CreateAccountFormModal from '../create-account-form/CreateAccountFormModal';
import FollowButton from './FollowButton';
// import userSubscriptions from '../../hooks/userSubscriptions';
import useRelationMutations from '../../graphql/useRelationMutations';
import useRelation from '../../graphql/useSingleRelations';
import { ANON, EMAIL_UNVERIFIED, FOLLOW, UNFOLLOW } from '../../data/Data';
import { verificationStatus } from '../../lib/user/user';
import type { PopoverType, User, UserRelation } from '../../types/Types';

type Props = {
  app: *,
  user: User, // the logged in user
  owner: User // the owner of this profile
};

const FollowButtonWrapper = (props: Props): Node => {
  const { app, owner, user } = props;
  const [popover, setPopover] = useState<PopoverType>({ severity: 'info', show: false, text: '' });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('follow');
  const [currentRelation, setCurrentRelation] = useState<UserRelation | null>(null);
  // const subscriptions = userSubscriptions(user);
  const status = verificationStatus(user);
  const { relation } = useRelation({
    sourceUser: { _id: user._id },
    targetUser: { _id: owner._id }
  });

  const { useAddRelation, useDeleteRelation } = useRelationMutations();
  const { addRelation, data, error, loading } = useAddRelation;

  /**
   * If we already have a relationship
   */
  useEffect(() => {
    if (relation && relation.relationship === FOLLOW) {
      setButtonText(UNFOLLOW);
      setCurrentRelation(relation);
    }
  }, [relation]);

  /**
   * Upon clicking the button create relationship
   */
  useEffect(() => {
    if (data && data.addedRelation) {
      setButtonText(UNFOLLOW);
      setPopover({
        severity: 'success',
        show: true,
        text: `You are now following @${owner.userName}`
      });
      setCurrentRelation(data.addedRelation);
    }

    if (error) {
      setPopover({
        severity: 'error',
        show: true,
        text: `Error following @${owner.userName}`
      });
    }
  }, [data, error]);

  /**
   * Follow the user
   */
  const followAction = () => {
    switch (true) {
      case status === ANON: // anonymous user
        setShowModal(!showModal);
        break;

      case status === EMAIL_UNVERIFIED:
        setPopover({
          show: true,
          text: 'Please confirm your email before you follow'
        });
        break;

      // Follow
      case buttonText === FOLLOW:
        addRelation({
          relationship: FOLLOW,
          sourceUser: user._id,
          targetUser: owner._id,
          userId: user._id
        });
        break;

      // Unfollow
      case buttonText === UNFOLLOW && currentRelation !== null: {
        // $FlowFixMe
        useDeleteRelation(currentRelation)
          .then(async (result) => {
            console.warn('useDeleteRelation result', result);
            const removeRelation = await app.currentUser.callFunction(
              'deleteRelationForUser',
              user._id,
              currentRelation?._id
            );

            console.warn('removeRelation', removeRelation);

            setCurrentRelation(null);
            setButtonText(FOLLOW);
          })
          .catch(() => {
            setPopover({
              severity: 'error',
              show: true,
              text: 'Error unfollowing'
            });
          });
        break;
      }
      default:
      // no op
    }
  };

  /**
   * Close popover in button
   */
  const closePopover = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setPopover({
      ...popover,
      show: false
    });
  };

  switch (true) {
    case !owner.emailConfirmed:
      return null;

    default:
      return (
        <>
          <FollowButton
            {...props}
            buttonText={buttonText}
            followAction={followAction}
            closePopover={closePopover}
            loading={loading}
            popover={popover}
          />
          <CreateAccountFormModal
            {...props}
            open={showModal}
            setOpen={setShowModal}
            text={`Create an account to follow @${owner.userName}`}
          />
        </>
      );
  }
};

export default FollowButtonWrapper;
