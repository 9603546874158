/**
 * Get message threads
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
// import useThreadMutations from './useThreadMutations';

const useThreads = (query, limit = 10) => {
  const { threads, loading } = getAllThreads(query, limit);

  return {
    loading,
    threads
  };
};
export default useThreads;

function getAllThreads(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetThreads($query: ThreadQueryInput!, $limit: Int) {
        threads(query: $query, sortBy: THREADID_DESC, limit: $limit) {
          _id
          latest
          messages {
            _id
            content
            created
            status
            user {
              _id
              userName
              profileImage
              firstName
              lastName
            }
          }
          users {
            _id
            userName
            profileImage
            firstName
            lastName
          }
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'network-only'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch threads: ${error.message}`);
  }

  // If the query has finished, return the threads from the result data
  // Otherwise, return an empty list
  const threads = data?.threads ?? [];
  return { threads, loading };
}
