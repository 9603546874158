import { ObjectId } from 'bson';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function useLikeMutations() {
  return {
    useAddLike: useAddLike(),
    updateLike: useUpdateLike(),
    deleteLike: useDeleteLike()
  };
}

const AddLikeMutation = gql`
  mutation CustomAddLike($like: CustomLikeInput!) {
    addedLike: CustomLikeInput(input: $like) {
      _id
      created
      message {
        _id
      }
      post {
        _id
      }
      photo {
        _id
      }
      video {
        _id
      }
      user {
        _id
      }
      vote
    }
  }
`;

const UpdateLikeMutation = gql`
  mutation UpdateLike($likeId: ObjectId!, $updates: LikeUpdateInput!) {
    updatedLike: updateOneLike(query: { _id: $likeId }, set: $updates) {
      _id
      vote
    }
  }
`;

const DeleteLikeMutation = gql`
  mutation CustomDeleteLike($like: CustomDeleteLikeInput!) {
    deletedLike: CustomDeleteLikeInput(input: $like) {
      _id
    }
  }
`;

const LikeFieldsFragment = gql`
  fragment LikeFields on Like {
    _id
    created
    post {
      _id
    }
    photo {
      _id
    }
    video {
      _id
    }
    user {
      _id
    }
    vote
  }
`;

function useAddLike() {
  const [addLikeMutation, { data, loading, error }] = useMutation(AddLikeMutation, {
    // Manually like added Likes into the Apollo cache so that Like queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedLike } }) => {
      cache.modify({
        fields: {
          likes: (existingLikes = []) => [
            ...existingLikes,
            cache.writeFragment({
              data: addedLike,
              fragment: LikeFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addLike = async (like) => {
    const id = new ObjectId();
    const { addedLike } = await addLikeMutation({
      variables: {
        like: {
          _id: id,
          ...like
        }
      }
    });
    return addedLike;
  };

  return { addLike, data, loading, error };
}

function useUpdateLike() {
  const [updateLikeMutation] = useMutation(UpdateLikeMutation);
  const updateLike = async (like, updates) => {
    const { updatedLike } = await updateLikeMutation({
      variables: { likeId: new ObjectId(like._id), updates }
    });
    return updatedLike;
  };
  return updateLike;
}

function useDeleteLike() {
  const [deleteLikeMutation] = useMutation(DeleteLikeMutation, {
    update: (cache, { data: { deletedLike } }) => {
      cache.evict({ _id: deletedLike._id });
    }
  });

  const deleteLike = async (like) => {
    const { deletedLike } = await deleteLikeMutation({
      variables: { like }
    });
    return deletedLike;
  };
  return deleteLike;
}
