const registerServiceWorker = () => {
  console.warn('registerServiceWorker navigator.serviceWorker', navigator.serviceWorker);

  if (navigator.serviceWorker !== undefined) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch((err) => {
        console.log('Service worker registration failed, error:', err);
      });
  }
};

export { registerServiceWorker };
