/**
 * upload helper functions
 * @flow
 */
import { getMediaApiUrl, DOCS, OPTIONS, PHOTO, VIDEOS, IMAGE_TYPE, VIDEO_TYPE } from '../../data/Data';
import type { MediaEdit, Upload, User } from '../../types/Types';

/**
 * detect image or video from base64
 */
export const detectMediaType = (media: MediaEdit): string | null => {
  const { src } = media;
  const pic = new RegExp(/^data:image\/jpeg/);
  const vid = new RegExp(/^data:video\/mp4/);
  return src && pic.test(src) ? PHOTO : src && vid.test(src) ? VIDEOS : null;
};

/**
 * get signed Urls to upload to S3.
 */
export const getSignedUrlUpload = async (media: MediaEdit, user: User): Promise<Upload> => {
  let { _id, type, subType } = media;
  const ext = type === VIDEOS ? 'mp4' : 'jpg';
  const contentType = type === DOCS ? type : type === VIDEOS ? VIDEO_TYPE : IMAGE_TYPE;

  const fileName = subType ? `${subType}.${ext}` : `${_id}.${ext}`;
  const filePath = `${user._id}/${fileName}`;
  const options = {
    ...OPTIONS,
    body: JSON.stringify({ contentType, type, filePath })
  };
  const putUrl = `${getMediaApiUrl(window)}/images`;
  const { url } = await fetch(putUrl, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });

  const file = {
    filePath,
    id: _id,
    media,
    type,
    url
  };

  return file;
};

export const getSignedUrlsDownload = async (
  filePaths: Array<{ _id: string, path: string, media: string }>
): Promise<Array<string>> => {
  const options = {
    ...OPTIONS,
    body: JSON.stringify({ filePaths })
  };

  const url = `${getMediaApiUrl(window)}/get-images`;
  const { urls } = await fetch(url, options)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });

  return urls;
};

/**
 * get the signed urls
 */
export const getUrls = async (
  filePaths: Array<{ _id: string, path: string, media: string }>
): Promise<Array<string>> => {
  return await getSignedUrlsDownload(filePaths);
};
