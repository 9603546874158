import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export default function useUserMutations() {
  return {
    updateUser: useUpdateUser()
  };
}

export const UpdateUserMutation = gql`
  mutation UpdateUser($userId: String!, $updates: UserUpdateInput!) {
    updatedUser: updateOneUser(query: { userId: $userId }, set: $updates) {
      _id
      bio
      firstName
      lastName
      twitter
      url
      userName
    }
  }
`;

function useUpdateUser() {
  const [updateUserMutation] = useMutation(UpdateUserMutation);
  const updateUser = async (user, updates) => {
    const { updatedUser } = await updateUserMutation({
      variables: { userId: user._id, updates }
    });
    console.warn('useUserMutations useUpdateUser() updatedUser', updatedUser);
    return updatedUser;
  };
  return updateUser;
}
