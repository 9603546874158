/**
 * UserFields
 * @flow
 */
import React, { Fragment, useState, type Node } from 'react';
import { EditRounded, ExpandMoreRounded, ExpandLessRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Redirect } from 'react-router-dom';
import UserField from '../user-field/UserField';
import UserVerified from '../user-verified/UserVerified';
import ProfileEdit from '../profile-edit/ProfileEdit';
import type { User, UserFields as UserFieldsType } from '../../types/Types';

import styles from './UserFields.module.scss';

type Props = {
  edit: boolean,
  editValues: (field: string, value: string) => *,
  toggleEdit: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  updateUserData: () => *,
  owner: User, // profile owner
  user: User, // current User
  values: UserFieldsType
};

const UserFields = (props: Props): Node => {
  const { edit, editValues, owner, user, updateUserData, values } = props;
  const editable = owner && user && owner._id === user._id ? true : false;
  let { bio, firstName, lastName, twitter, url } = values;
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const hasMore =
    (bio.length > 74 && window.innerWidth >= 768) || (bio.length > 105 && window.innerWidth < 768) || twitter || url
      ? true
      : false;

  /**
   * Show more
   */
  const more = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  const createUsername = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    updateUserData();
    setTimeout(() => {
      setRedirect(true);
    }, 1000);
  };

  if (redirect) {
    return <Redirect to="/settings/create-username" />;
  }

  return (
    <Fragment>
      <div className={styles.Name}>
        {firstName} {lastName} <UserVerified owner={owner} />
      </div>
      <div className={styles.UserName}>
        <UserField field="userName" multiline={false} open={open} user={values} />
        {edit && editable && user._id === user.userName ? (
          <a href="#create-username" onClick={createUsername}>
            <Button variant="contained">
              <EditRounded />
              Create username
            </Button>
          </a>
        ) : null}
      </div>
      {edit && editable ? (
        <ProfileEdit {...props} editValues={editValues} user={user} values={values} />
      ) : (
        <div className={styles.ExtraUserFields}>
          <UserField field="bio" multiline={true} open={open} user={values} />
          {open ? (
            <Fragment>
              <UserField field="twitter" user={values} />
              <UserField field="url" user={values} />
            </Fragment>
          ) : null}
          {hasMore && !edit && open ? (
            <a href="/less" onClick={more} className={styles.More}>
              <ExpandLessRounded /> Less
            </a>
          ) : hasMore && !edit && !open ? (
            <a href="/more" onClick={more} className={styles.More}>
              <ExpandMoreRounded /> More
            </a>
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

UserFields.defaultProps = {
  currentUser: null,
  minimal: false,
  user: null
};

export default UserFields;
