/**
 * SnackBar
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import useThreads from '../../graphql/useThreads';
import { getRealmDb, SNACKBAR_STYLE } from '../../data/Data';
import type { Slate, User } from '../../types/Types';

type Props = {
  app: *,
  user: User
};

const MessagesSnackbar = (props: Props): Node => {
  const { app, user } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [breakAsyncIterator, setBreakAsyncIterator] = useState<boolean>(false);
  const [hasListener, setHasListener] = useState<boolean>(false);
  const [message, setMessage] = useState<{ text: string, link: string }>({ text: '', link: '/chat' });
  // const [threadIds, setThreadIds] = useState<Array<string>>([]);
  const { threads } = useThreads({ users_in: [{ userId: user._id }] });

  useEffect(() => {
    if (!threads || threads.length === 0 || hasListener) {
      return;
    }

    let newThreadIds = [];

    for (let watch of threads) {
      newThreadIds.push(watch._id);
    }

    listen(newThreadIds);

    return () => {
      setBreakAsyncIterator(true);
    };
  }, [threads, hasListener]);

  const listen = async (threadIds: Array<string>) => {
    const DB = getRealmDb(window);
    const mongo = app.currentUser.mongoClient('mongodb-atlas');
    const threads = mongo.db(DB).collection('threads');
    const messages = mongo.db(DB).collection('messages');
    const users = mongo.db(DB).collection('users');
    setHasListener(true);

    /**
     * @important
     * The docs on Mongo DB are wrong. We don't use `$match` in an aggregate pipeline.
     * Reading the source for node_modules/realm-web reveals that we need to
     * use the property `filter` to check watch specific records.
     *
     * node_modules/realm-web/types/realm/services.d.ts line 544
     */
    for await (let item of threads.watch({ ids: threadIds })) {
      const _id = item.fullDocument.messages[0];
      const newMessage = await messages.findOne({ _id });
      const link = `/chat/${newMessage.threadId}`;

      // Don't alert ourselves to our own sent messages, or if we are on the chat thread page
      if (newMessage.userId === user._id || window.location.pathname === link) {
        return;
      }

      // else fetch user
      const sender = await users.findOne({ userId: newMessage.userId });

      const content: Slate = JSON.parse(newMessage.content);
      let text: string = '';

      // @tdo add logic for reactions / likes

      switch (content[0].type) {
        case 'paragraph':
          text = `New message from @${sender.userName}`;
          break;

        case 'photo':
          text = `@${sender.userName} sent you a photo`;
          break;

        case 'video':
          text = `@${sender.userName} sent you a video`;
          break;

        default:
          return;
      }

      if (text) {
        setMessage({ text, link });
        setOpen(true);
      }

      if (breakAsyncIterator) {
        console.warn('Snackbar: breaking async iterator for Realm watch()');
        break;
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      sx={SNACKBAR_STYLE}
    >
      <Link to={message.link} onClick={handleClose}>
        <Alert severity="info">{message.text}</Alert>
      </Link>
    </Snackbar>
  );
};

export default MessagesSnackbar;
