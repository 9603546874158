/**
 * ImageEditor
 * @flow
 */
import React, { useState, useRef, useEffect, type Node } from 'react';
import { Button, Modal } from '@mui/material';
import { CancelRounded, CreateRounded } from '@mui/icons-material';
import Cropper from 'react-cropper';
import { useKeyPress } from '../../hooks/useKeyPress';
import { IMAGE_TYPE } from '../../data/Data';
import type { MediaEdit } from '../../types/Types';
import 'cropperjs/dist/cropper.css';
import styles from './ImageEditor.module.scss';

type Props = {
  allowCropChange: boolean,
  aspectRatio?: number,
  allowCropChange: boolean,
  cropBoxMovable: boolean,
  cropBoxResizable: boolean,
  field: number,
  icon: 'camera' | 'picture',
  id: string,
  media: Array<MediaEdit>,
  setMedia: (media: Array<MediaEdit>) => *
};

const ImageEditor = (props: Props): Node => {
  const { allowCropChange, aspectRatio, field, media, setMedia } = props;
  const [editing, setEditing] = useState(false);
  const [image, setImage] = useState(media[field]);
  const cropperRef = useRef(null);
  const isEsc = useKeyPress('Escape');

  useEffect(() => {
    if (isEsc) {
      setEditing(false);
      setImage(null);
    }
  }, [isEsc]);

  /**
   * edit existing image
   */
  const editImage = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    setEditing(!editing);
  };

  /**
   * remove existing image
   */
  const removeImage = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    let newMedia = [...media];
    newMedia.splice(parseInt(field), 1);
    setMedia(newMedia);
  };

  /**
   * crop
   */
  const cropImage = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (cropperRef && cropperRef.current) {
      const imageElement: any = cropperRef.current;
      const cropper: any = imageElement.cropper;
      const canvas = cropper.getCroppedCanvas();
      const { width, height } = canvas;
      const img = {
        ...media[field],
        src: canvas.toDataURL(IMAGE_TYPE),
        width,
        height
      };
      const newMedia = [...media];
      newMedia[field] = img;
      setMedia(newMedia);
      setEditing(false);
    }
  };

  /**
   * cancelCrop
   */
  const cancelCrop = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setEditing(false);
    setImage(null);
  };

  /**
   * Set crop ratio
   */
  const ratio = (e: SyntheticMouseEvent<HTMLAnchorElement>, ratio: number) => {
    e.preventDefault();
    // setCropAspect(ratio);
    if (cropperRef && cropperRef.current) {
      const imageElement: any = cropperRef.current;
      const cropper: any = imageElement.cropper;
      cropper.setAspectRatio(ratio);
    }
  };

  switch (true) {
    case editing:
      return (
        <Modal className={styles.Modal} open={editing} onBackdropClick={editImage}>
          <div className={styles.ModalInner}>
            <Cropper
              ref={cropperRef}
              src={image ? image.src : null}
              className={styles.Cropper}
              cropBoxMovable={false}
              cropBoxResizable={false}
              zoomOnWheel={true}
              zoomOnTouch={true}
              movable={true}
              resizable={true}
              dragMode={'move'}
              viewMode={1}
              aspectRatio={aspectRatio}
              autoCropArea={0.8}
            />
            <div className={styles.Buttons}>
              {allowCropChange ? (
                <div className={styles.CropRatios}>
                  <a href="#portrait" onClick={(e) => ratio(e, 9 / 16)}>
                    <div className={styles.Portrait} />
                  </a>
                  <a href="#square" onClick={(e) => ratio(e, 1)}>
                    <div className={styles.Square} />
                  </a>
                  <a href="#landscape" onClick={(e) => ratio(e, 16 / 9)}>
                    <div className={styles.Landscape} />
                  </a>
                </div>
              ) : null}
              <a href="#cancel-image-edit" onClick={cancelCrop}>
                <Button variant="outlined">Cancel</Button>
              </a>
              <a href="#crop-image" onClick={cropImage}>
                <Button variant="contained">Crop</Button>
              </a>
            </div>
          </div>
        </Modal>
      );

    default:
      return (
        <div className={styles.HasImage}>
          <a className={styles.RemoveImage} href="#edit-image/remove" onClick={removeImage}>
            <CancelRounded />
          </a>
          <a className={styles.EditImage} href="#edit-image" onClick={editImage}>
            <CreateRounded />
          </a>
        </div>
      );
  }
};

ImageEditor.defaultProps = {
  aspectRatio: 1,
  allowCropChange: true,
  icon: 'camera',
  id: 'edit-image',
  img: '',
  setMedia: (): null => null
};

export default ImageEditor;
