import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useRelations = (query, limit = 20) => {
  const { relations, loading } = getAllRelations(query, limit);

  return {
    loading,
    relations
  };
};
export default useRelations;

function getAllRelations(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetRelations($query: RelationQueryInput!, $limit: Int) {
        relations(query: $query, sortBy: _ID_DESC, limit: $limit) {
          _id
          created
          relationship
          sourceUser {
            _id
            emailConfirmed
            userName
            profileImage
            firstName
            lastName
          }
          targetUser {
            _id
            emailConfirmed
            userName
            profileImage
            firstName
            lastName
          }
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'network-only'
    }
  );
  if (error) {
    console.warn(error);
    return { error };
  }

  // If the query has finished, return the relations from the result data
  // Otherwise, return an empty list
  const relations = data?.relations ?? [];
  return { relations, loading };
}
