import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useNotifications = (query, limit = 20) => {
  const { notifications, loading } = getNotifications(query, limit);
  return {
    loading,
    notifications
  };
};
export default useNotifications;

function getNotifications(query, limit) {
  const { data, loading, error } = useQuery(
    gql`
      query GetNotificationsByQuery($query: NotificationQueryInput!, $limit: Int) {
        notifications(query: $query, sortBy: CREATED_DESC, limit: $limit) {
          _id
          content
          created
          link
          status
          title
          userId
        }
      }
    `,
    {
      variables: {
        query,
        limit
      },
      fetchPolicy: 'network-only'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch notifications: ${error.message}`);
  }

  // If the query has finished, return the notifications from the result data
  // Otherwise, return an empty list
  const notifications = data?.notifications ?? [];
  return { notifications, loading };
}
