/**
 * App
 * @flow
 */
import React, { useState, useEffect, type Node } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRealmApp, RealmAppProvider } from './RealmApp';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/Theme';
import RealmApolloProvider from './graphql/RealmApolloProvider';
import Routes from './routes/Routes';
import CreateAccountRoutes from './routes/CreateAccountRoutes';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Loading from './components/loading/Loading';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { loadStripe } from '@stripe/stripe-js';
import { PROVIDER_TYPES, RECAPTCHA_KEY, getEnvironment, getRealmAppId, getRealmApiKey } from './data/Data';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styles from './App.module.scss';
const env = getEnvironment(window);
const realmAppId = getRealmAppId(window);
const stripeKey = env === 'dev' ? process.env.REACT_APP_STRIPE_KEY_DEV : process.env.REACT_APP_STRIPE_KEY_PROD;
const stripePromise = loadStripe(stripeKey);

const AppWrapper = () => {
  const app = useRealmApp();
  const [canLoad, setCanLoad] = useState<boolean>(false);
  const [user, setUser] = useState(null);
  const [realmApp, setRealmApp] = useState(null);

  useEffect(() => {
    if (app?.currentUser?.providerType === PROVIDER_TYPES.localUser) {
      setCanLoad(true);
      return;
    }

    // API key login for anonymous users
    const apiKey = getRealmApiKey(window);

    app
      .loginApiKey(apiKey)
      .then(({ app, user }) => {
        setUser(user);
        setRealmApp(app);
        setCanLoad(true);
      })
      .catch((error) => {
        console.warn(error);
      });
  }, [app]);

  return (
    <div className={styles.App}>
      {canLoad && realmApp && user ? (
        <RealmApolloProvider app={realmApp}>
          <CreateAccountRoutes app={realmApp} stripePromise={stripePromise} user={user} />
        </RealmApolloProvider>
      ) : canLoad && app ? (
        <RealmApolloProvider app={app}>
          <Routes stripePromise={stripePromise} />
        </RealmApolloProvider>
      ) : (
        <Loading type="squirrel" />
      )}
    </div>
  );
};

const App = (): Node => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <RealmAppProvider appId={realmAppId}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <BrowserRouter>
              <AppWrapper />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </RealmAppProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
