/**
 * PaymentAddMethod
 * @flow
 */
import React, { useState, type Node } from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';
import type { Offer, User } from '../../types/Types';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styles from './PaymentAddMethod.module.scss';

type Props = {
  app: *,
  owner: null | User,
  subscription: null | Offer,
  user: User
};

const PaymentAddMethod = (props: Props): Node => {
  const { subscription, user } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState<{ error: boolean, message: string }>({ error: false, message: '' });
  const stripe = useStripe();
  const elements = useElements();

  // When the Stripe Payment Element loads, turn off loading
  const onReady = () => {
    setLoading(false);
  };

  // Handle submission of the Stripe Form
  const handleSubmit = async (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!stripe || !elements || !user || !subscription) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setSpinner(true);
    const { email, firstName, lastName } = user;
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : '';

    const data = {
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.href}?subscription=${subscription.id}`,
        payment_method_data: {
          billing_details: {
            email,
            name
          }
        }
      }
    };

    const result = await stripe.confirmSetup(data);

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      console.log(result.error.message);
      setError({
        error: true,
        message: result.error.message
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  if (!elements || !stripe) {
    return null;
  }

  return (
    <div className={styles.PaymentAddMethod}>
      <h2>Add card</h2>
      <p>Add a credit or debit card to pay for subscriptions</p>
      <div className={styles.StripeContainer}>
        {loading ? <CircularProgress /> : null}
        <PaymentElement onReady={onReady} />
      </div>
      {error.error ? <Alert severity="error">{error.message}</Alert> : null}
      {spinner ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" disabled={!stripe} onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </div>
  );
};

export default PaymentAddMethod;
