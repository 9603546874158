/**
 * Get keyboard events
 * @flow
 */
import { useEffect, useState } from 'react';

export function useKeyPress(targetKey: string): boolean {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  // If pressed key is our target key then set to true
  function downHandler(e: SyntheticKeyboardEvent<>) {
    const { key, keyCode } = e;
    // console.warn('key, keyCode', key, keyCode);

    switch (true) {
      case targetKey === 'Escape' && keyCode === 27:
        setKeyPressed(true);
        break;

      case key === targetKey:
        setKeyPressed(true);
        break;

      default:
        setKeyPressed(false);
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}
