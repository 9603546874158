/**
 * CarouselImage
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Skeleton } from '@mui/material';
import type { MediaCache, Photo } from '../../types/Types';
import styles from './CarouselImage.module.scss';

type Props = {
  embedded: boolean,
  carouselDim: { width: number, height: number },
  mediaCache: MediaCache,
  photo: Photo
};

const CarouselImage = (props: Props): Node => {
  const { carouselDim, mediaCache, photo } = props;
  const [dim, setDim] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
  const [image, setImage] = useState<Image | null>(null);
  const [showBackground, setShowBackground] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    // Cancel image.onload on unmount

    if (!image) {
      return;
    }

    return () => {
      image.onload = () => null;
    };
  }, [image]);

  useEffect(() => {
    if (!photo || !photo?.width || !photo?.height) {
      return;
    }
    let ratio = 1;
    let width = carouselDim.width;
    let height = carouselDim.height;

    const imagePortrait = photo.height > photo.width ? true : false;
    const screenPortrait = carouselDim.height > carouselDim.width ? true : false;

    switch (true) {
      case imagePortrait && screenPortrait:
      case !imagePortrait && !screenPortrait:
        ratio = carouselDim.width / photo.width;
        height = Math.floor(photo.height * ratio);
        width = Math.floor(carouselDim.width);
        break;

      case imagePortrait && !screenPortrait:
        ratio = carouselDim.height / photo.height;
        height = Math.floor(carouselDim.height);
        width = Math.floor(photo.width * ratio);
        break;

      case !imagePortrait && screenPortrait:
        ratio = carouselDim.width / photo.width;
        width = Math.floor(carouselDim.width);
        height = Math.floor(photo.height * ratio);
        break;

      default:
        width = Math.floor(carouselDim.width);
        height = Math.floor(carouselDim.height);
    }

    if (width !== carouselDim.width) {
      setShowBackground(true);
    }

    setDim({ width, height });

    const theImage = mediaCache
      ? mediaCache.find((image) => {
          return image._id === photo._id;
        })
      : null;

    // console.warn('CarouselImage theImage', theImage)

    if (theImage) {
      loadImage(theImage.url, width, height);
    }
  }, [carouselDim, photo, mediaCache]);

  /**
   * Load the image
   */
  const loadImage = async (url: string, width: number, height: number) => {
    let image = new Image();
    image.src = url;
    image.width = width;
    image.height = height;
    setImage(image);

    image.onload = async () => {
      setLoaded(true);
    };
  };

  return image ? (
    <div className={styles.CarouselImageContainer}>
      {showBackground ? (
        <div className={styles.BackgroundImage} style={{ backgroundImage: `url(${image.src})` }} />
      ) : null}
      <div className={styles.CarouselImage} style={{ width: carouselDim.width, height: dim.height }}>
        {loaded ? (
          <img src={image.src} width={image.width} height={image.height} loading="lazy" />
        ) : (
          <Skeleton width={carouselDim.width} height={image.height} animation="pulse" variant="rectangular" />
        )}
      </div>
    </div>
  ) : null;
};

CarouselImage.defaultProps = {
  embedded: false,
  carouselDim: { width: window.innerWidth * 0.95 - 32, height: window.innerHeight * 0.95 - 32 },
  image: '',
  photo: null
};

export default CarouselImage;
