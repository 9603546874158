/**
 * RecommendedFollowsWrapper
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { ObjectId } from 'bson';
import RecommendedFollows from './RecommendedFollows';
import useUsers from '../../graphql/useUsers';
import useRelations from '../../graphql/useRelations';
import { FOLLOW } from '../../data/Data';
import type { Stripe, User, UserRelation } from '../../types/Types';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  user: User
};

const RecommendedFollowsWrapper = (props: Props): Node => {
  const { user } = props;
  const [userQueryDone, setUserQueryDone] = useState<boolean>(false); // to avoid an infinite loop of queries
  const [query, setQuery] = useState({
    userId_lt: new ObjectId().toString(),
    emailConfirmed: true,
    AND: [{ userId_ne: user._id }]
  });
  const [relationQuery, setRelationsQuery] = useState(null);
  const { relations }: { relations: Array<UserRelation> } = useRelations(relationQuery, 1000);
  const { users, loading } = useUsers(query, 5);

  /**
   * Relations query
   */
  useEffect(() => {
    if (!user) {
      return;
    }

    const newQuery = {
      relationship: FOLLOW,
      AND: [{ sourceUser: { userId: user._id } }]
    };

    if (newQuery.AND[0].sourceUser.userId === relationQuery?.AND[0].sourceUser.userId) {
      return;
    }

    setRelationsQuery(newQuery);
  }, [user, relationQuery]);

  /**
   * User query
   */
  useEffect(() => {
    // userQueryDone avoids an infinite loop of queries
    if (userQueryDone || !relations || relations.length === 0) {
      return;
    }

    let excludeIds = [user._id];

    for (const rel of relations) {
      excludeIds.push(rel.targetUser._id);
    }

    setQuery({
      userId_lt: new ObjectId().toString(),
      emailConfirmed: true,
      AND: [{ userId_nin: excludeIds }]
    });
    setUserQueryDone(true);
  }, [relations, userQueryDone]);

  return <RecommendedFollows {...props} users={users} loading={loading} />;
};

export default RecommendedFollowsWrapper;
