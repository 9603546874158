/**
 * User Graphql queries
 */
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const useGetUserQuery = (query) => {
  const { user, loading } = getUserQuery(query);
  return {
    loading,
    user
  };
};
export default useGetUserQuery;

function getUserQuery(query) {
  const { data, loading, error } = useQuery(
    gql`
      query GetUserByQuery($query: UserQueryInput!) {
        user(query: $query) {
          _id
          bio
          emailConfirmed
          firstName
          free
          headerImage
          lastName
          phoneConfirmed
          profileImage
          role
          stripe {
            accountId
            customerId
          }
          url
          userName
          verifications {
            _id
            actions {
              _id
              action
              actionDate
              note
            }
            created
          }
        }
      }
    `,
    {
      variables: {
        query
      },
      fetchPolicy: 'network'
    }
  );
  if (error) {
    throw new Error(`Failed to fetch user: ${error.message}`);
  }

  // If the query has finished, return the posts from the result data
  // Otherwise, return an empty list
  const user = data?.user ?? null;
  return { user, loading };
}
