import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function useVideoMutations() {
  return {
    useAddVideo: useAddVideo(),
    updateVideo: useUpdateVideo(),
    deleteVideo: useDeleteVideo()
  };
}

// This uses a custom resolver to insert a new video.
// See app/functions/customVideoInputResolver.js
const AddVideoMutation = gql`
  mutation CustomAddVideo($video: CustomVideoInput!) {
    addedVideo: CustomVideoInput(input: $video) {
      _id
      comments
      created
      filePath
      user {
        _id
        userName
        firstName
        lastName
        profileImage
      }
    }
  }
`;

const UpdateVideoMutation = gql`
  mutation UpdateVideo($videoId: ObjectId!, $updates: VideoUpdateInput!) {
    updatedVideo: updateOneVideo(query: { _id: $videoId }, set: $updates) {
      _id
      comments
      created
      user {
        _id
        userName
        firstName
        lastName
        profileImage
      }
    }
  }
`;

const DeleteVideoMutation = gql`
  mutation DeleteVideo($videoId: ObjectId!) {
    deletedVideo: deleteOneVideo(query: { _id: videoId }) {
      _id
    }
  }
`;

const VideoFieldsFragment = gql`
  fragment VideoFields on Video {
    _id
    comments
    created
    user {
      _id
      userName
      firstName
      lastName
      profileImage
    }
  }
`;

function useAddVideo() {
  const [addVideoMutation, { data, loading, error }] = useMutation(AddVideoMutation, {
    // Manually save added Videos into the Apollo cache so that Video queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedVideo } }) => {
      cache.modify({
        fields: {
          videos: (existingVideos = []) => [
            ...existingVideos,
            cache.writeFragment({
              data: addedVideo,
              fragment: VideoFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addVideo = async (video) => {
    const { id, ...params } = video;
    const { addedVideo } = await addVideoMutation({
      variables: {
        video: {
          _id: id,
          videoId: id,
          ...params
        }
      }
    });
    return addedVideo;
  };

  return { addVideo, data, loading, error };
}

function useUpdateVideo() {
  const [updateVideoMutation] = useMutation(UpdateVideoMutation);
  const updateVideo = async (video, updates) => {
    const { updatedVideo } = await updateVideoMutation({
      variables: { videoId: video._id, updates }
    });
    return updatedVideo;
  };
  return updateVideo;
}

function useDeleteVideo() {
  const [deleteVideoMutation] = useMutation(DeleteVideoMutation);
  const deleteVideo = async (video) => {
    const { deletedVideo } = await deleteVideoMutation({
      variables: { videoId: video._id }
    });
    return deletedVideo;
  };
  return deleteVideo;
}
