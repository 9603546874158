/**
 * Search
 * @flow
 */
import React, { useEffect, useRef, useState, type Node } from 'react';
import { SearchRounded } from '@mui/icons-material';
import SearchResults from './search-results/SearchResults';
import { TextField } from '@mui/material';
import type { User } from '../../types/Types';
import styles from './Search.module.scss';

type Props = {
  autoFocus: boolean,
  app: *,
  user: User
};

const Search = (props: Props): Node => {
  const { app, autoFocus } = props;
  const [value, setValue] = useState<string>('');
  const [icon, setIcon] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Array<User>>([]);
  const searchRef = useRef();

  useEffect(() => {
    if (!value) {
      setResults([]);
      return;
    }

    doSearch(value);
  }, [value]);

  /**
   * Do Search via Realm app function
   */
  const doSearch = async (value: string) => {
    setLoading(true);
    const searchResults = await app.currentUser.callFunction('search', value);
    setResults(searchResults);
    setLoading(false);
  };

  /**
   * search
   */
  const searchIconClick = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (icon && searchRef && searchRef.current) {
      searchRef.current.focus();
      setIcon(false);
    }
  };

  /**
   * OnChange
   */
  const onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();
    console.warn('onChange');
    const { value } = e.target;
    setValue(value);
  };

  /**
   * onFocus
   */
  const onFocus = () => {
    setIcon(false);
  };

  /**
   * onBlur
   */
  const onBlur = () => {
    setIcon(true);
  };

  return (
    <div className={styles.Search}>
      <TextField
        autoFocus={autoFocus}
        autoComplete="false"
        inputRef={searchRef}
        id="search"
        type="search"
        label="Search"
        name="search"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder="Search"
        value={value}
        aria-controls="search-results"
        aria-expanded={results.length > 0}
      />
      {icon ? (
        <a href="#search" className={styles.Icon} onClick={searchIconClick}>
          <SearchRounded />
        </a>
      ) : null}
      <SearchResults results={results} loading={loading} value={value} />
    </div>
  );
};

Search.defaultProps = {
  app: null,
  autoFocus: false,
  user: null
};

export default Search;
