// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from 'firebase/messaging';
import { detect } from 'detect-browser';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let messaging, analytics;
const browser = detect();

if (navigator.serviceWorker !== undefined) {
  const firebaseConfig = {
    apiKey: 'AIzaSyC_IKdCM8RH3P4b6AVkOR_fF3wAz_w9SMk',
    authDomain: 'nuttify.firebaseapp.com',
    projectId: 'nuttify',
    storageBucket: 'nuttify.appspot.com',
    messagingSenderId: '1042659826406',
    appId: '1:1042659826406:web:406fa6ff3087bfdc8af927',
    measurementId: 'G-H49QZL8PEQ'
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.

  if (browser && browser.name !== 'safari' && window.Notification) {
    messaging = getMessaging(app);

    getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          // console.warn('currentToken', currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  }
}

export { analytics, messaging };
