/**
 * MessagesMenuItem
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { NavLink } from 'react-router-dom';
import ChatBubbleRounded from '@mui/icons-material/ChatBubbleRounded';
import useMessages from '../../../graphql/useMessages';
import { ObjectId } from 'bson';
import type { User } from '../../../types/Types';
import styles from './MessagesMenuItem.module.scss';

type Props = {
  app: *,
  user: User
};

const MessagesMenuItem = (props: Props): Node => {
  const { user } = props;
  const [unread, setUnread] = useState<number>(0);
  const { messages } = useMessages({
    user: {
      _id: new ObjectId(user._id)
    },
    status: 'unread'
  });

  useEffect(() => {
    if (!messages) {
      return;
    }

    // set initial number of unread messages
    setUnread(messages.length);
  }, [messages]);

  return (
    <NavLink to="/chat" className={styles.MessagesMenuItem}>
      <div className={styles.Icon}>
        <ChatBubbleRounded />
        {unread > 0 ? <div className={styles.Badge}>{unread}</div> : null}
      </div>
      <span className={styles.Text}>Chat</span>
    </NavLink>
  );
};

export default MessagesMenuItem;
