/**
 * Post
 * @flow
 */
import React, { useEffect, useRef, useState, type Node } from 'react';
import { Redirect } from 'react-router-dom';
import PremiumPostTeaser from '../premium-post-teaser/PremiumPostTeaser';
import ProfileHeaderMinimal from '../../components/profile-header-minimal/ProfileHeaderMinimal';
import PostActions from '../post-actions/PostActions';
import Text from '../text/Text';
import MediaModal from '../media-modal/MediaModal';
import Carousel from '../carousel/Carousel';
import ReplyingTo from '../replying-to/ReplyingTo';
import { getUrls } from '../../lib/upload/upload';
import { IMAGES } from '../../data/Data';
import type { Post as PostType, Stripe, User } from '../../types/Types';
import styles from './PostReply.module.scss';

type Props = {
  app: *,
  customerPlatform: Stripe | null,
  stripePromise: Promise<*>,
  deleted: boolean,
  isReply: boolean,
  post: PostType,
  parent: PostType,
  reallyDeletePost: () => *,
  isSubbed: boolean,
  user: User
};

const PostReply = (props: Props): null | Node => {
  const { deleted, isSubbed, parent, post, reallyDeletePost, user } = props;
  const [redirect, setRedirect] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [lineStyle, setLineStyle] = useState<{ height: number } | null>(null);
  const [mediaCache, setMediaCache] = useState<Array<{ _id: string, url: string }>>([]);
  const { created, likes, mediaOrder, premium, photos, videos } = post;
  const postRef = useRef(null);
  const mediaRef = useRef(null);

  useEffect(() => {
    let filePaths = [];
    if (photos && photos.length > 0) {
      post.photos.map((item) => {
        filePaths.push({ path: item.filePath, media: IMAGES });
      });
    }
    getUrls(filePaths)
      .then((urls) => {
        let media = [];
        urls.map((url, index) => {
          media.push({
            _id: photos[index]._id,
            url
          });
        });
        setMediaCache(media);
      })
      .catch((err) => console.error(err));
  }, []);

  // Set the height of the connecting line
  useEffect(() => {
    if (!postRef || !postRef.current) {
      return;
    }

    // After a short delay get the ReplyDiv dimensions
    setTimeout(delayedLineStyle, 500);

    return () => {
      clearTimeout();
    };
  }, [postRef]);

  /**
   * Get the ReplyDiv dimensions
   */
  const delayedLineStyle = () => {
    if (!postRef || !postRef.current || !parent.replies) {
      return;
    }

    const { replies } = parent;
    const last = replies.length - 1;
    const isLast = replies[last]._id === post._id ? true : false;
    const { height } = postRef.current.getBoundingClientRect();
    const lineHeight = isLast ? 60 : height;
    setLineStyle({ height: lineHeight });
  };

  /**
   * View Single Post
   */
  const viewSinglePost = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setRedirect(true);
  };

  const toggleModal = (e: SyntheticMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  switch (true) {
    case redirect:
      return <Redirect to={`/post/${post._id ? post._id : ''}`} />;

    case deleted:
      return null;

    default:
      return (
        <>
          <div ref={postRef} className={styles.PostReply}>
            <div className={styles.Line} style={lineStyle} />
            <ReplyingTo post={post} />
            <ProfileHeaderMinimal created={created} user={post.user} />
            <div className={styles.LinkPost} onClick={viewSinglePost} tabIndex={0}>
              <Text {...props} />
            </div>
            {premium && !isSubbed && user._id !== post.user._id ? (
              <PremiumPostTeaser {...props} />
            ) : photos.length > 0 || videos.length > 0 ? (
              <div ref={mediaRef} className={styles.Media}>
                <Carousel
                  embedded={true}
                  mediaCache={mediaCache}
                  mediaOrder={mediaOrder}
                  // $FlowFixMe
                  photos={photos}
                  // $FlowFixMe
                  videos={videos}
                  containerRef={mediaRef}
                  toggleModal={toggleModal}
                />
              </div>
            ) : null}
            <div className={styles.PostFooter}>
              <PostActions {...props} deleteItem={reallyDeletePost} likes={likes} />
            </div>
          </div>
          <MediaModal
            mediaCache={mediaCache}
            photos={photos}
            videos={videos}
            show={showModal}
            toggleModal={toggleModal}
          />
        </>
      );
  }
};

PostReply.defaultProps = {
  deleted: false,
  isReply: false,
  post: ({}: { ... }),
  reallyDeletePost: (): null => null
};

export default PostReply;
