/**
 * ProfileHeader
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { CheckRounded, CloseRounded, EditRounded } from '@mui/icons-material';
import FollowButtonWrapper from '../follow-button/FollowButtonWrapper';
import MessageButton from '../message-button/MessageButton';
import ProfileImage from '../profile-image/ProfileImage';
import GetVerifiedButton from '../get-verified-button/GetVerifiedButton';
import UserFields from '../user-fields/UserFields';
import Share from '../share/Share';
import useUserMutations from '../../graphql/useUserMutations';
import { IMAGE_TYPE, OPTIONS, UNVERIFIED } from '../../data/Data';
import { getSignedUrlUpload } from '../../lib/upload/upload';
import type { MediaEdit, MediaSubtypes, User, VerificationStatus } from '../../types/Types';
import styles from './ProfileHeader.module.scss';

type Props = {
  app: *,
  status: VerificationStatus,
  user: User, // the logged in user
  owner: User // the owner of this profile
};

const ProfileHeader = (props: Props): Node => {
  const { owner, status, user } = props;
  const { updateUser } = useUserMutations();
  const editable = user && owner && user._id === owner._id ? true : false;
  const { _id, bio, firstName, headerImage, lastName, profileImage, twitter, url, userName } = owner
    ? owner
    : {
        _id: '',
        bio: '',
        firstName: '',
        headerImage: '',
        profileImage: '',
        lastName: '',
        url: '',
        twitter: '',
        userName: ''
      };
  const [values, setValues] = useState({
    bio,
    firstName,
    headerImage,
    lastName,
    profileImage,
    twitter,
    url,
    userName
  });
  const [edit, setEdit] = useState(false);
  const [profileEdited, setProfileEdited] = useState(false);
  const [headerEdited, setHeaderEdited] = useState(false);

  /**
   * When the component loads, user === null.
   * When the user loads from Realm graphql update local values in state
   */
  useEffect(() => {
    if (!owner) {
      return;
    }
    const { _id, bio, firstName, headerImage, lastName, profileImage, twitter, url, userName } = owner;
    setValues({ _id, bio, firstName, headerImage, lastName, profileImage, twitter, url, userName });
  }, [owner]);

  /**
   * Set the edited values
   */
  const editValues = (field: string, value: string | Blob) => {
    if (!editable) {
      return;
    }
    const newValues = { ...values };
    newValues[field] = value;
    setValues(newValues);
  };

  /**
   * prep image for upload
   */
  const prepImageUpload = (field: MediaSubtypes, image: string) => {
    let img = new Image();
    img.src = image;
    img.onload = async () => {
      let canvas = document.createElement('canvas');
      let { width, height } = img;

      if (field === 'profileImage') {
        width = 400;
        height = 400;
      } else {
        width = 630;
        height = 126;
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const url = canvas.toDataURL(IMAGE_TYPE, 0.7);
      const file = await fetch(url);
      const blob = await file.blob();
      updateImage(field, blob);
      editValues(field, url);
      if (field === 'profileImage') {
        setProfileEdited(true);
      } else if (field === 'headerImage') {
        setHeaderEdited(true);
      }
    };
  };

  /**
   * Update avatar or header Image
   * Upload to S3, and update graphql user
   */
  const updateImage = async (field: MediaSubtypes, blob: Blob) => {
    if (!user) {
      return;
    }

    let width,
      height,
      subType = field;

    if (field === 'profileImage') {
      width = 400;
      height = 400;
    } else {
      width = 630;
      height = 126;
    }

    const image: MediaEdit = {
      type: 'profile',
      subType,
      width,
      height
    };
    const { url } = await getSignedUrlUpload(image, user);

    const options = {
      ...OPTIONS,
      method: 'PUT',
      headers: {
        'Content-Type': IMAGE_TYPE,
        Accept: 'application/json'
      },
      body: blob,
      isBase64Encoded: true
    };

    fetch(url, options)
      .then((response) => {
        console.warn(response);
        if (response.status === 200) {
          // Update user in graphql
          // eslint-disable-next-line no-unused-vars
          const { __typename, ...userToUpdate } = user;
          let values = {};
          values[field] = true;
          updateUser(userToUpdate, { ...values });
        } else {
          throw new Error('Error posting profile or header image');
        }
      })
      .catch((err) => console.warn(err));
  };

  /**
   * Edit Profile toggle
   */
  const toggleEdit = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setEdit(!edit);

    if (editable) {
      updateUserData();
    }
  };

  /**
   * Cancel
   */
  const cancelEdit = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    if (!user) {
      return;
    }

    e.preventDefault();
    setEdit(false);
    const { bio, firstName, headerImage, lastName, url, profileImage, twitter, userName } = user;
    setValues({
      bio,
      firstName,
      headerImage,
      lastName,
      url,
      profileImage,
      twitter,
      userName
    });
  };

  /**
   * Update the user data with GraphQl (apart from images)
   */
  const updateUserData = () => {
    if (!user) {
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const { __typename, ...userToUpdate } = user;
    let updatedFields = {};

    for (let field in userToUpdate) {
      if (field === 'profileImage' || field === 'headerImage') {
        continue;
      }

      if (values[field] && userToUpdate[field] !== values[field]) {
        updatedFields[field] = values[field];
      }
    }

    // Only update if fields have changed
    if (JSON.stringify(updatedFields) !== '{}') {
      updateUser(userToUpdate, updatedFields);
    }
  };

  return (
    <div className={styles.ProfileHeader}>
      <ProfileImage
        aspectRatio={5}
        className="HeaderImage"
        edit={edit}
        field="headerImage"
        image={headerEdited ? values.headerImage : headerImage ? `${_id}/headerImage.jpg` : ''}
        id="header-image"
        updateImage={prepImageUpload}
      />
      <div className={styles.UserDetails}>
        {editable ? (
          <div className={styles.edit}>
            {edit ? (
              <a href="#cancel-edit" onClick={cancelEdit}>
                <CloseRounded />
              </a>
            ) : null}
            <a href="#edit-profile" onClick={toggleEdit}>
              {edit ? <CheckRounded /> : <EditRounded />}
            </a>
          </div>
        ) : null}
        <ProfileImage
          aspectRatio={1}
          className="ProfileImage"
          edit={edit}
          field="profileImage"
          image={profileEdited ? values.profileImage : profileImage ? `${_id}/profileImage.jpg` : ''}
          id="profile-image"
          updateImage={prepImageUpload}
        />

        {editable && status === UNVERIFIED && !edit ? (
          <div className={styles.GetVerified}>
            <GetVerifiedButton text="Get Verified" />
          </div>
        ) : editable ? null : (
          <FollowButtonWrapper {...props} />
        )}
        {editable ? null : <MessageButton {...props} />}
        <Share {...props} target="profile" />
        <UserFields
          edit={edit}
          editValues={editValues}
          owner={owner}
          toggleEdit={toggleEdit}
          updateUserData={updateUserData}
          user={user}
          values={values}
        />
      </div>
    </div>
  );
};

export default ProfileHeader;
