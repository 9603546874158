/**
 * CarouselVideo
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import Video from '../video/Video';
import type { MediaCache, Video as VideoType } from '../../types/Types';
import styles from './CarouselVideo.module.scss';

type Props = {
  carouselDim: { width: number, height: number },
  mediaCache: MediaCache,
  video: VideoType
};

const CarouselVideo = (props: Props): Node => {
  const { carouselDim, mediaCache, video } = props;
  const [media, setMedia] = useState<VideoType | null>(null);

  useEffect(() => {
    if (!video) {
      return;
    }

    const theVideo = mediaCache
      ? mediaCache.find((item) => {
          return item._id === video._id;
        })
      : null;

    if (theVideo) {
      setMedia({
        ...video,
        src: theVideo.url
      });
    }
  }, [carouselDim, video, mediaCache]);

  return media ? (
    <div className={styles.CarouselVideoContainer}>
      <div className={styles.CarouselVideo} style={{ width: carouselDim.width, height: '100%' }}>
        {media ? <Video video={media} containerDim={carouselDim} /> : null}
      </div>
    </div>
  ) : null;
};

CarouselVideo.defaultProps = {
  carouselDim: { width: window.innerWidth * 0.95 - 32, height: window.innerHeight * 0.95 - 32 },
  image: '',
  photo: null
};

export default CarouselVideo;
