/**
 * Paragraph
 * @flow
 */
import React, { Fragment, type Node } from 'react';
import { Link } from 'react-router-dom';
import { USERNAME_RE } from '../../data/Data';
import type { TextElement } from '../../types/Types';

type Props = {
  element: TextElement
};

const Paragraph = (props: Props): Node => {
  return props.element.children.map((child, index) => {
    const { text } = child;
    let display = [];

    const parts = text.split(' ');
    for (let i = 0; i < parts.length; i++) {
      let part = parts[i];
      const username = part.match(USERNAME_RE);

      if (username) {
        display.push(
          <>
            {' '}
            <Link to={`/${username[0].substr(1)}`}>{part}</Link>{' '}
          </>
        );
      } else {
        let last = display.length - 1;
        last < 0 ? 0 : last;
        if (typeof display[last] === 'object' || display.length === 0) {
          display.push(part);
        } else {
          display[last] += ` ${part}`;
        }
      }
    }

    return (
      <p key={index}>
        {display.map((item, i) => {
          return <Fragment key={i}>{item}</Fragment>;
        })}
      </p>
    );
  });
};

export default Paragraph;
