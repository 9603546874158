import { ObjectId } from 'bson';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function usePostMutations() {
  return {
    useAddPost: useAddPost(),
    updatePost: useUpdatePost(),
    deletePost: useDeletePost()
  };
}

// This uses a custom resolver to insert a new post.
// See app/functions/customPostInputResolver.js
const AddPostMutation = gql`
  mutation CustomAddPost($post: CustomPostInput!) {
    addedPost: CustomPostInput(input: $post) {
      _id
      content
      photos {
        _id
        filePath
        width
        height
      }
      videos {
        _id
        filePath
      }
      user {
        _id
        userName
        firstName
        lastName
      }
    }
  }
`;

const UpdatePostMutation = gql`
  mutation UpdatePost($postId: ObjectId!, $updates: PostUpdateInput!) {
    updatedPost: updateOnePost(query: { _id: $postId }, set: $updates) {
      _id
      content
      photos {
        _id
        filePath
        width
        height
      }
      videos {
        _id
        filePath
        poster {
          _id
          filePath
        }
      }
      user {
        _id
        userName
        firstName
        lastName
      }
    }
  }
`;

const DeletePostMutation = gql`
  mutation DeletePost($postId: ObjectId!) {
    deletedPost: deleteOnePost(query: { _id: $postId }) {
      _id
      content
    }
  }
`;

export const PostFieldsFragment = gql`
  fragment PostFields on Post {
    _id
    content
    photos {
      _id
      filePath
      width
      height
    }
    videos {
      _id
      filePath
    }
    user {
      _id
      userName
      firstName
      lastName
    }
  }
`;

function useAddPost() {
  const [addPostMutation, { data, loading, error }] = useMutation(AddPostMutation, {
    // Manually save added Posts into the Apollo cache so that Post queries automatically update
    // For details, refer to https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    update: (cache, { data: { addedPost } }) => {
      cache.modify({
        fields: {
          posts: (existingPosts = []) => [
            ...existingPosts,
            cache.writeFragment({
              data: addedPost,
              fragment: PostFieldsFragment
            })
          ]
        }
      });
    }
  });

  const addPost = async (post) => {
    const id = new ObjectId();
    post = {
      ...post,
      _id: id.toString()
    };
    console.warn('usePostMutation post', post);

    const { addedPost } = await addPostMutation({
      variables: { post }
    });
    return addedPost;
  };

  return { addPost, data, loading, error };
}

function useUpdatePost() {
  const [updatePostMutation] = useMutation(UpdatePostMutation);
  const updatePost = async (post, updates) => {
    const { updatedPost } = await updatePostMutation({
      variables: { postId: post._id, updates }
    });
    return updatedPost;
  };
  return updatePost;
}

function useDeletePost() {
  const [deletePostMutation] = useMutation(DeletePostMutation);
  const deletePost = async (post) => {
    const { deletedPost } = await deletePostMutation({
      variables: { postId: post._id }
    });
    return deletedPost;
  };
  return deletePost;
}
