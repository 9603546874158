/**
 * Hook to get a user's offers / products/ prices
 *
 * User's stripe accountId is stored in the stripe collection in MongoDB
 * the account that owns the products has an accountID + NO customerId (in that stripe collection document)
 */
import { useEffect, useState } from 'react';
import { userHasStripeAcc } from '../lib/stripe/stripeHelpers';

export default function userOffers(app, user) {
  // State and setter for storing whether element is visible
  const [offers, setOffers] = useState([]);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);

  /**
   * Get Products
   */
  useEffect(() => {
    const hasStripeAcc = userHasStripeAcc(user);
    console.warn('userOffers hasStripeAcc', hasStripeAcc);

    if (!app || !user || !hasStripeAcc) {
      return;
    }

    const args = {
      accountId: hasStripeAcc.accountId,
      endpoint: 'products',
      method: 'get',
      data: { active: true }
    };

    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        setProducts(result.data);
      })
      .catch((err) => {
        console.warn('Stripe userOffers getProducts error');
        console.error(err);
      });
  }, [app, user]);

  /**
   * get prices
   */
  useEffect(() => {
    if (!app || !user.stripe) {
      return;
    }

    const owner = user.stripe.find((stripe) => {
      return stripe.accountId && !stripe.customerId;
    });

    if (!owner) {
      return;
    }

    const args = {
      accountId: owner.accountId,
      endpoint: 'prices',
      method: 'get'
    };

    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        console.warn('StripeAPI userOffers getPrices result');
        console.warn(result);
        setPrices(result.data);
      })
      .catch((err) => {
        console.warn('Stripe userOffers getPrices error');
        console.error(err);
      });
  }, [app, user]);

  /**
   * Match prices to products
   */
  useEffect(() => {
    if (!products || !prices) {
      return;
    }

    let offersList = [];

    products.map((product) => {
      prices.map((price) => {
        if (price.product === product.id) {
          offersList.push({
            ...product,
            price
          });
        }
      });
    });

    setOffers(offersList);
  }, [products, prices]);

  return offers;
}
