/**
 * Logo
 * @flow
 */
import React from 'react';
import type { Node } from 'react';
import { SITE_TITLE, STRAP_LINE } from '../../data/Data';
import styles from './Logo.module.scss';

type Props = {
  showStrap: boolean,
  size: string
};

const Logo = (props: Props): Node => {
  const { showStrap, size } = props;

  return (
    <div className={size === 'small' ? styles.LogoSmall : styles.Logo}>
      <span className={styles.brand}>{SITE_TITLE.toLocaleLowerCase()}</span>
      <div className={styles.NoDot} />
      <div className={styles.Beta}>Beta</div>
      {showStrap ? <span className={styles.strapline}>{STRAP_LINE}</span> : null}
    </div>
  );
};

Logo.defaultProps = {
  showStrap: false,
  size: 'large'
};

export default Logo;
