/**
 * MediaModal
 * @flow
 */
import React, { useRef, type Node } from 'react';
import { Modal, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';
import type { MediaCache, Photo, Video } from '../../types/Types';
import Carousel from '../carousel/Carousel';
import styles from './MediaModal.module.scss';

type Props = {
  toggleModal: (e: SyntheticMouseEvent<HTMLDivElement>) => *,
  show: boolean,
  photos: Array<Photo>,
  videos: Array<Video>,
  mediaCache: MediaCache
};

const MediaModal = (props: Props): Node => {
  const { show, toggleModal } = props;
  const containerRef = useRef(null);

  return (
    <Modal className={styles.MediaModal} open={show} onBackdropClick={toggleModal}>
      <>
        <a className={styles.Close} onClick={toggleModal}>
          <Close />
        </a>
        <Paper className={styles.Inner} ref={containerRef}>
          <Carousel {...props} isModal={true} containerRef={containerRef} />
        </Paper>
      </>
    </Modal>
  );
};

MediaModal.defaultProps = {
  toggleModal: (): null => null,
  show: false,
  slide: 0,
  images: ([]: Array<empty>),
  isModal: true
};

export default MediaModal;
