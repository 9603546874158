/**
 * IosMediaAlert
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Alert } from '@mui/material';
import { ArrowForwardRounded, CloseRounded } from '@mui/icons-material';
import { detect } from 'detect-browser';
import styles from './IosMediaAlert.module.scss';

const ALERT_DISMISSED_KEY = 'nuttify:iOsAlertCreatePost';

const IosMediaAlert = (): Node | null => {
  const [dismissed, setDismissed] = useState<boolean>(true);
  const { os } = detect();

  useEffect(() => {
    const isDismissed = localStorage.getItem(ALERT_DISMISSED_KEY);
    if (isDismissed && isDismissed === 'true') {
      // no op - already set to true to avoid a flash
    } else {
      setDismissed(false);
    }
  }, []);

  const close = (e: SyntheticKeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setDismissed(true);
    localStorage.setItem(ALERT_DISMISSED_KEY, 'true');
  };

  return os === 'iOS' && !dismissed ? (
    <div className={styles.Alert}>
      <Alert severity="info">
        <div className={styles.inner}>
          <div className={styles.text}>
            Please check your Camera Settings are set to "Most Compatible".{' '}
            <a className={styles.HelpLink} href="/help/iphone-video-settings" target="_blank">
              Read the help article <ArrowForwardRounded />
            </a>
          </div>
          <a className={styles.Close} href="#" onClick={close}>
            <CloseRounded />
          </a>
        </div>
      </Alert>
    </div>
  ) : null;
};

export default IosMediaAlert;
