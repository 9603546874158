/**
 * Stripe helper functions
 * @flow
 */
import type { BankAccountState, Stripe, User } from '../../types/Types';

/**
 * User has stripe account
 */
export const userHasStripeAcc = (user: User): Stripe | null => {
  if (!user) {
    return null;
  }

  const hasStripe =
    user && user.stripe
      ? user.stripe.find((item) => {
          return item.accountId !== null && !item.customerId ? true : false;
        })
      : null;

  return hasStripe ? hasStripe : null;
};

/**
 * Stripe Bank details on this page
 * https://stripe.com/docs/connect/payouts-bank-accounts
 */
export const bankStateValues = (
  country: null | string
): null | {
  bsb: BankAccountState,
  account: BankAccountState
} => {
  const defaultField = {
    display: '',
    error: false,
    helperText: '',
    label: '',
    placeholder: '',
    reg: null,
    required: true,
    value: ''
  };

  switch (country) {
    case 'AU':
      return {
        bsb: {
          ...defaultField,
          label: 'BSB',
          placeholder: '123-456',
          reg: /^\d{6}$/,
          required: true
        },
        account: {
          ...defaultField,
          label: 'Account number',
          placeholder: '123456789',
          reg: /^\d{5,9}$/
        }
      };

    case 'CA':
      return {
        bsb: {
          ...defaultField,
          label: 'Transit number & institution number',
          placeholder: '12345-678',
          reg: /^\d{8}$/,
          required: true
        },
        account: {
          ...defaultField,
          label: 'Account number',
          placeholder: '123456789',
          reg: /.+/
        }
      };

    case 'MX':
      return {
        bsb: {
          ...defaultField,
          required: false
        },
        account: {
          ...defaultField,
          helperText: 'Your 18 digit account number',
          label: 'CLABE',
          placeholder: '123456789012345678',
          reg: /^[0-9]{18}$/
        }
      };

    case 'NZ':
      return {
        bsb: {
          ...defaultField,
          required: false
        },
        account: {
          ...defaultField,
          helperText: 'Your 15 or 16 digit account number',
          label: 'Account number',
          placeholder: '12-3456-78901234-023',
          reg: /^[0-9]{15,16}$/
        }
      };

    case 'UK':
      return {
        bsb: {
          ...defaultField,
          label: 'BSB',
          placeholder: '12-34-56',
          reg: /^\d{6}$/
        },
        account: {
          ...defaultField,
          label: 'Account number',
          placeholder: '12345678',
          reg: /^\d{8}$/
        }
      };

    case 'US':
      return {
        bsb: {
          ...defaultField,
          label: 'Routing number',
          placeholder: '123456789',
          reg: /^\d{9}$/
        },
        account: {
          ...defaultField,
          label: 'Account number',
          placeholder: '12345678',
          reg: /.+/
        }
      };

    default:
      return null;
  }
};

export const bankNumberDisplay = (
  value: string,
  field: string,
  country: null | string
): { display: string, value: string } => {
  value = value.replace(/-|\s/g, '');
  let parts = [];
  let display = value;

  switch (true) {
    case country === 'AU' && field === 'bsb':
      if (value.length > 3) {
        parts.push(value.slice(0, 3));
        parts.push(value.slice(3));
      }
      break;

    case country === 'CA' && field === 'bsb':
      if (value.length > 5) {
        parts.push(value.slice(0, 5));
        parts.push(value.slice(5));
      }
      break;

    case country === 'NZ' && field === 'account':
      if (value.length > 2) {
        parts.push(value.slice(0, 2));
        parts.push(value.slice(2, 6));
      }
      if (value.length > 6) {
        parts.push(value.slice(6, 13));
      }
      if (value.length > 13) {
        parts.push(value.slice(13));
      }
      break;

    case country === 'UK' && field === 'bsb':
      if (value.length > 2) {
        parts.push(value.slice(0, 2));
        parts.push(value.slice(2, 4));
      }
      if (value.length > 4) {
        parts.push(value.slice(4));
      }
      break;

    default:
    // no op;
  }

  display = parts.length > 0 ? parts.join('-') : value;
  return { display, value };
};

export const currencySymbol = (currency: string): string => {
  switch (currency) {
    case 'aud':
    case 'cad':
    case 'nzd':
    case 'usd':
      return '$';

    case 'gbp':
      return '£';

    default:
      return '';
  }
};
