/**
 * PaymentMethodModal
 * @flow
 */
import React, { type Node } from 'react';
import Close from '../close/Close';
import { Card, Modal } from '@mui/material';
import SetupIntent from '../setup-intent/SetupIntent';
import PaymentAddMethod from '../payment-add-method/PaymentAddMethod';
import type { Offer, Stripe, User } from '../../types/Types';
import styles from './PaymentMethodModal.module.scss';

type Props = {
  accountId: string | null,
  customerPlatform: Stripe | null,
  app: *,
  color: string, // Mui theme color: primary, secondary, etc
  owner: User | null,
  open: boolean,
  setError: ({ error: boolean, message: string }) => *,
  setOpen: (open: boolean) => *,
  stripePromise: Promise<*>,
  subscription: null | Offer,
  user: User
};

const PaymentMethodModal = (props: Props): Node => {
  const { customerPlatform, open, owner, setOpen, subscription } = props;

  /**
   * Close the Modal
   */
  const closeModal = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    setOpen(!open);
  };

  if (!owner || !subscription) return null;

  return (
    <Modal open={true} className={styles.PaymentModal}>
      <Card className={styles.Card}>
        <Close onClick={closeModal} />
        <SetupIntent {...props} customer={customerPlatform}>
          <PaymentAddMethod {...props} />
        </SetupIntent>
      </Card>
    </Modal>
  );
};

PaymentMethodModal.defaultProps = {
  color: 'secondary',
  offer: null,
  user: null
};

export default PaymentMethodModal;
