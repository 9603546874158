/**
 * MoreButtons
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { useRealmApp } from '../../RealmApp';
import type { Post, User } from '../../types/Types';
import Buttons from './Buttons';

type Props = {
  alignment: 'horizontal' | 'vertical',
  deleteItem: () => *,
  post: Post | null,
  showMore: boolean,
  user: User,
  toggleEdit: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *
};

const MoreButton = (props: Props): Node => {
  const app = useRealmApp();
  const { post, user } = props;
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(() => {
    if (!app || !user) {
      return;
    }

    setIsCurrentUser(user && post && app?.currentUser?.id === post?.user?._id ? true : false);
  }, [app, user]);

  return <Buttons {...props} isCurrentUser={isCurrentUser} />;
};

MoreButton.defaultProps = {
  alignment: 'horizontal',
  deleteItem: (): null => null,
  post: null,
  showMore: true,
  toggleEdit: (): null => null
};

export default MoreButton;
