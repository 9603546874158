/**
 * Sidebar
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import Search from '../search/Search';
import HelpWidget from '../help-widget/HelpWidget';
import Footer from '../footer/Footer';
import { SHOW_MIN_WIDTH } from '../../data/Data';
import { useWindowSize } from '../../hooks/windowResize';
import type { User } from '../../types/Types';
import styles from './Sidebar.module.scss';

type Props = {
  app: *,
  user: User,
  mainRef: *,
  headerRef: *
};

const Sidebar = (props: Props): Node => {
  const { headerRef, mainRef, ...componentProps } = props;
  const [style, setStyle] = useState(null);
  const { width } = useWindowSize();

  /**
   * Set Sidebar as position fixed using the refs to <header> and <main>
   */
  useEffect(() => {
    setDims;
  }, [width]);

  const setDims = () => {
    if (!width || width < SHOW_MIN_WIDTH || !headerRef || !headerRef.current || !mainRef || !mainRef.current) {
      setStyle(null);
      return;
    }

    const header = headerRef.current.getBoundingClientRect();
    const main = mainRef.current.getBoundingClientRect();
    const buffer = 32;
    const left = header.width + main.width + buffer;
    const top = props.user.providerType === 'api-key' || props.user.emailConfirmed ? 0 : 32;

    let newStyle = {
      position: 'fixed',
      top,
      left
    };

    setStyle(newStyle);
  };

  if (!width || width < SHOW_MIN_WIDTH) {
    return null;
  }

  return (
    <div className={styles.Sidebar}>
      <aside style={style}>
        <Search {...componentProps} />
        <HelpWidget />
      </aside>
      <Footer />
    </div>
  );
};

export default Sidebar;
