/**
 * PostActions
 * @flow
 */
import React, { useState, type Node } from 'react';
import EditPost from '../edit-post/EditPost';
import LikeWrapper from '../like/LikeWrapper';
import FavouriteWrapper from '../favourite/FavouriteWrapper';
import ReplyIcon from '../reply-icon/ReplyIcon';
import Share from '../share/Share';
import MoreButtons from '../more-buttons/MoreButtons';
import ReplyingTo from '../replying-to/ReplyingTo';
import type { Post, User } from '../../types/Types';
import styles from './PostActions.module.scss';

type Props = {
  app: *,
  alignment: 'horizontal' | 'vertical',
  deleteItem: () => *,
  likes?: number,
  post: Post,
  toggleEdit: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  user: User
};

const PostActions = (props: Props): Node => {
  const { app, alignment, post, user } = props;
  const [reply, setReply] = useState<boolean>(false);

  const toggleReply = (e: SyntheticMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setReply(!reply);
  };

  return (
    <div className={styles.PostActionsWrapper}>
      <div className={alignment === 'vertical' ? styles.PostActionsVertical : styles.PostActions}>
        <LikeWrapper {...props} />
        <FavouriteWrapper {...props} />
        <ReplyIcon {...props} toggleReply={toggleReply} />
        <Share {...props} target="post" />
        <MoreButtons {...props} />
      </div>
      {reply ? (
        <>
          <ReplyingTo post={post} />
          <EditPost app={app} parent={post._id} user={user} />
        </>
      ) : null}
    </div>
  );
};

PostActions.defaultProps = {
  deleteItem: (): null => null,
  alignment: 'horizontal',
  saveItem: (): null => null,
  toggleEdit: (): null => null,
  user: null
};

export default PostActions;
