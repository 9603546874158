/**
 * Button
 * @flow
 */
import React from 'react';
import type { Node } from 'react';
import styles from './Button.module.scss';

type Props = {
  action: (e: SyntheticMouseEvent<HTMLAnchorElement>) => *,
  className: 'Primary' | 'Secondary' | 'Cancel' | string,
  children: null | Node,
  href: string
};

const Button = (props: Props): Node => {
  const { action, children, className, href } = props;
  return (
    <a className={`${styles.Button} ${styles[className]}`} href={href} onClick={action}>
      {children}
    </a>
  );
};

Button.defaultProps = {
  action: (): null => null,
  className: 'Primary',
  children: null,
  href: '/'
};

export default Button;
