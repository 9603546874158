/**
 * CarouselNumbers
 * @flow
 */
import React, { useEffect, useContext, useState, type Node } from 'react';
import { CarouselContext } from 'pure-react-carousel';
import styles from './CarouselNumbers.module.scss';

type Props = {
  isModal: boolean
};

const CarouselNumbers = (props: Props): Node => {
  const { isModal } = props;
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide + 1);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide + 1);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <div className={isModal ? styles.NumbersModal : styles.Numbers}>
      {currentSlide}/{carouselContext.state.totalSlides}
    </div>
  );
};

CarouselNumbers.defaultProps = {
  isModal: false
};

export default CarouselNumbers;
