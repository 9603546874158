/**
 * Help Widget
 * @flow
 */
import React, { type Node } from 'react';
import { HELP_ARTICLES } from '../../pages/help/Help.data';
import { SupportRounded } from '@mui/icons-material';
import styles from './HelpWidget.module.scss';

const HelpWidget = (): Node => {
  const { pathname } = window.location;
  const parts = pathname.split('/');
  const current = parts[parts.length - 1];

  return (
    <div className={styles.HelpWidget}>
      <h2>Help Articles</h2>
      <ul>
        {HELP_ARTICLES.map((article, index) => {
          const { slug, title } = article;

          return (
            <li key={index}>
              {slug === current ? (
                <span className={styles.Current}>{title}</span>
              ) : (
                <a href={`/help/${slug}`}>{title}</a>
              )}
            </li>
          );
        })}
        <li>
          <a className={styles.All} href="/help">
            <SupportRounded /> All help articles
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HelpWidget;
