/**
 * userStripeCustomer hook
 * Gets the user's stripe customer account for the platform or connected account
 * @flow
 */
import { useEffect, useState } from 'react';
import useStripeMutations from '../graphql/useStripeMutations';
import { BSON } from 'realm-web';
import type { User, Stripe } from '../types/Types';

export default function userStripePlatformCustomer(app: *, user: User): Stripe | null {
  const [customer, setCustomer] = useState<Stripe | null>(null);
  const { useAddStripe } = useStripeMutations();
  const { addStripe } = useAddStripe;

  useEffect(() => {
    if (!app || !user) {
      return;
    }

    // platform customer
    if (user && user.stripe) {
      const customer = user.stripe.find((id) => {
        return !id.accountId && id.customerId ? true : false;
      });

      if (customer) {
        setCustomer(customer);
        return;
      }
    }

    const { _id, email, firstName, lastName } = user;
    const name = firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : '';

    const args = {
      endpoint: 'customers',
      method: 'post',
      data: {
        email,
        name,
        'metadata[userId]': _id
      }
    };

    app.currentUser
      .callFunction('stripeApi', args)
      .then((result) => {
        // Attach customer Id to user
        if (result.id) {
          const id = new BSON.ObjectID().toString();
          const customerId = result.id;
          const stripe = {
            id,
            customerId,
            userId: _id
          };
          addStripe(stripe);
          // $FlowFixMe
          setCustomer({
            customerId,
            userId: _id,
            stripeId: id
          });
        } else {
          throw new Error(result.message);
        }
      })
      .catch((err) => console.error('userStripeCustomer stripe err', err));
  }, [app, user]);

  return customer;
}
