/**
 * UserVerified
 * @flow
 */
import React, { type Node } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import { verificationStatus } from '../../lib/user/user';
import { ADMIN, APPROVED } from '../../data/Data';
import type { User } from '../../types/Types';
import styles from './UserVerified.module.scss';

type Props = {
  owner: User | null
};

const UserVerified = (props: Props): Node => {
  const { owner } = props;

  if (!owner) {
    return null;
  }

  const status = verificationStatus(owner);

  return status === APPROVED || status === ADMIN ? (
    <div className={styles.UserVerified}>
      <VerifiedIcon color="primary" />
    </div>
  ) : null;
};

export default UserVerified;
