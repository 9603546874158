/**
 * NotificationsMenuItem
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { NavLink } from 'react-router-dom';
import { NotificationsRounded } from '@mui/icons-material';
import useNotifications from '../../../graphql/useNotifications';
import type { User } from '../../../types/Types';
import styles from './NotificationsMenuItem.module.scss';

type Props = {
  app: *,
  user: User
};

// const DB = getRealmDb(window);

const NotificationsMenuItem = (props: Props): Node => {
  const { user } = props;
  const [unread, setUnread] = useState<number>(0);
  const { notifications } = useNotifications(
    {
      userId: user._id,
      status: 'unread'
    },
    1000
  );

  useEffect(() => {
    if (!notifications) {
      return;
    }

    // set initial number of unread notifications
    setUnread(notifications.length);
  }, [notifications]);

  return (
    <NavLink to="/notifications" className={styles.NotificationsMenuItem}>
      <div className={styles.Icon}>
        <NotificationsRounded />
        {unread > 0 ? <div className={styles.Badge}>{unread}</div> : null}
      </div>
      <span className={styles.Text}>Nuttifications</span>
    </NavLink>
  );
};

export default NotificationsMenuItem;
